<template>
  <base-nav
    container-classes="container-fluid"
    class="navbar-top navbar-expand"
    v-bind:class="$route.name == 'Dashboard' ? 'bg-gradient-warning' : ''"
  >
    <!-- Navbar links -->
    <ul class="navbar-nav align-items-center">
      <li class="nav-item d-xl-none">
        <!-- Sidenav toggler -->
        <div
          class="pr-3 sidenav-toggler"
          :class="{
            active: $sidebar.showSidebar,
            'sidenav-toggler-dark': type === 'default',
            'sidenav-toggler-dark': type === 'light',
          }"
          @click="toggleSidebar"
        >
          <div class="sidenav-toggler-inner">
            <i class="sidenav-toggler-line bg-primary"></i>
            <i class="sidenav-toggler-line bg-primary"></i>
            <i class="sidenav-toggler-line bg-primary"></i>
          </div>
        </div>
      </li>
    </ul>

    <ul class="navbar-nav align-items-center ml-auto ml-md-auto">
      <li>
        <el-dropdown
          trigger="click"
          :class="activePath === 'Dashboard' ? 'el-dropdown-link' : 'el-dropdown-link_2'"
          @command="switchLg"
        >
          <span type="primary" class="el-dropdown-link--inner">
            {{ $t("dashboard_navbar.language")
            }}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu
            slot="dropdown"
            :class="
              activePath === 'Dashboard' ? 'myDropdown__menu' : 'myDropdown__menu__2'
            "
          >
            <el-dropdown-item
              command="en"
              :class="
                activePath === 'Dashboard' ? 'myDropdown__item' : 'myDropdown__item__2'
              "
              >{{ $t("dashboard_navbar.english") }}</el-dropdown-item
            >
            <el-dropdown-item
              command="es"
              :class="
                activePath === 'Dashboard' ? 'myDropdown__item' : 'myDropdown__item__2'
              "
              >{{ $t("dashboard_navbar.spanish") }}</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </li>
      <base-dropdown
        menu-on-right
        class="nav-item no--hover"
        tag="li"
        title-tag="a"
        title-classes="nav-link pr-0"
      >
        <a href="#" class="nav-link pr-0" @click.prevent slot="title-container">
          <div class="media align-items-center">
            <span class="avatar-image">
              <div class="if-img" v-if="user.profile_pic">
                <img :src="user.profile_pic" />
              </div>
              <div class="if-img-noavatar" v-else>
                {{ getAvatarInitials() }}
              </div>
            </span>
          </div>
        </a>
        <template>
          <div class="dropdown-header noti-title">
            <h6 class="text-overflow m-0">
              {{ $t("dashboard_navbar.welcome") }} {{ avatarText }} !
            </h6>
          </div>
          <a class="elt--dropdown" @click.prevent="goToProfile()">
            <i class="ni ni-single-02"></i>
            <span>{{ $t("dashboard_navbar.my_profile") }}</span>
          </a>
          <div class="dropdown-divider"></div>
          <a class="elt--dropdown red" @click.prevent="logout()" to="">
            <i class="ni ni-user-run"></i>
            <span>{{ $t("dashboard_navbar.logout") }}</span>
          </a>
        </template>
      </base-dropdown>
    </ul>
  </base-nav>
</template>
<script>
import BaseNav from "@/components/Navbar/BaseNav";
import axios from "axios";

export default {
  components: {
    BaseNav,
  },
  props: {
    type: {
      type: String,
      default: "default", // default|light
      description: "Look of the dashboard navbar. Default (Green) or light (gray)",
    },
  },
  computed: {
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
    profileImage() {
      return this.avatar ? this.avatar : "/img/placeholder.jpg";
    },
  },
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      searchQuery: "",
      title: "User",
      avatarText: null,
      user: {},
      activePath: undefined,
    };
  },
  methods: {
    switchLg(lg) {
      this.$i18n.locale = lg;
      localStorage.setItem("aichemy--current_language", lg);
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    getAvatarInitials() {
      if (this.avatarText) {
        const avatarArray = this.avatarText.split(" ");

        if (avatarArray.length > 1) return avatarArray.map((word) => word[0]).join("");
        return this.avatarText.substring(0, 2);
      }
    },
    goToProfile() {
      this.$router.push({
        name: "User Profile",
      });
    },
    async getData() {
      try {
        if(!localStorage.getItem("testgold.userInfo")){
  
          await store.dispatch("userInfo")
        }
        const userInfo = JSON.parse(localStorage.getItem("testgold.userInfo"))
        if(userInfo){
          this.user = Object.assign({}, userInfo);
          this.avatarText = this.user.full_name ? this.user.full_name : "N/A";
        }else{
          console.log("navbar 402");
          localStorage.removeItem("vue-authenticate.access_token");
          localStorage.removeItem("testgold.aioToken");
          localStorage.setItem(
            "login--message",
            "User trial period has ended. Please contact support@testgold.dev."
          );
          window.location.reload();
        }
      } catch (e) {
        this.$notify({
          type: "danger",
          message: `An error has occured ${e}`,
        });
      }
    },
    async logout() {
      try {
        await this.$store.dispatch("logout");
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, Cannot logout ! If problem persist, please contact support",
        });
      }
    },
  },
  async created() {
    await this.getData();
    this.activePath = this.$route.name;
  },
  watch: {
    $route(from, to) {
      this.activePath = from.name;
    },
  },
};
</script>

<style lang="scss" scoped>
.el-dropdown-link {
  cursor: pointer;
  color: white;
  justify-content: center;
  transform: translateY(-1rem);
  padding: 0.25rem 0.5rem 0.25rem 0.5rem;
  font-weight: bold;
  top: 16px;
  font-size: 13px;

  &:hover {
    border: 1px solid white;
    border-radius: 0.5rem;
  }
}

.el-dropdown-link_2 {
  cursor: pointer;
  color: #6e6e6d;
  justify-content: center;
  transform: translateY(-1rem);
  padding: 0.25rem 0.5rem 0.25rem 0.5rem;
  font-weight: bold;
  top: 16px;
  font-size: 13px;

  &:hover {
    border: 1px solid #6e6e6d;
    border-radius: 0.5rem;
  }
}

.el-icon-arrow-down {
  font-size: 12px;
  top: 2px;
}

.el-popper .popper__arrow,
.el-popper .popper__arrow::after {
  display: none !important;
}

.myDropdown__menu {
  border: 2px solid white;
  background-color: orange;
  width: 98px;
  transform: translateY(-5px) translateX(0px);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: 1rem;
  padding: 0rem;
}

.myDropdown__item {
  color: white;
  background-color: #fcb04c;
  width: 100%;
  font-size: 12px;

  &:not(:last-child) {
    border-bottom: 1px solid white;
  }
}

.myDropdown__menu__2 {
  border: 3px solid #cfcfcf;
  background-color: #f5f5f5;
  width: 98px;
  transform: translateY(-5px) translateX(0px);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: 1rem;
  padding: 0rem;
}

.myDropdown__item__2 {
  color: #616161;
  background-color: #ebeced;
  width: 100%;
  font-size: 12px;

  &:not(:last-child) {
    border-bottom: 1px solid #cfcfcf;
  }
}

.avatar-image i {
  color: #25a18e;
}

.if-img {
  width: 50px;
  height: 50px;
  border-radius: 0.3rem;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #ffffff;
  font-size: 22px;
  font-weight: bold;
  text-transform: uppercase;
  overflow: hidden;

  img {
    width: 100%;
  }
}

.if-img-noavatar {
  width: 50px;
  height: 50px;
  border-radius: 0.3rem;
  background-color: #25a18e;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #fff;
  font-size: 22px;
  font-weight: bold;
  text-transform: uppercase;
  overflow: hidden;

  img {
    width: 100%;
  }
}

.no--hover {
  .nav-link {
    transform: translate3d(0, 0, 0);
    &:hover {
      transform: translate3d(0, 0, 0);
    }
  }
}

.dropdown-menu.show {
  padding-bottom: 0 !important;
  overflow: hidden;
}

.dropdown-divider {
  margin: 0;
}

.elt--dropdown {
  display: inline-flex;
  gap: 0.5rem;
  align-items: center;
  font-size: 0.75rem;
  width: 100%;
  padding: 0.75rem;
  color: initial;

  &:hover {
    background-color: #25a18e;
    color: #ffffff;
  }

  &.red {
    color: #f5365c;

    &:hover {
      background-color: #f5365c;
      color: #ffffff;
    }
  }
}
</style>
