import store from "../store";
import axios from "axios";
import router from "../router";

const Url = process.env.VUE_APP_API_URL_PREFIX + "/auth/v1/users/me";

// const options = {
//   headers: {
//     Accept: "application/json",
//     "Content-Type": "application/json",
//   },
// };

// // We dont need to intercept axios directly, we can do it at the routes.js level
// axios.interceptors.response.use(
//   (response) => {
//     if (response.status === 401) {
//       store.dispatch("logout");
//       router.push("/login");
//     }
//     return response;
//   },
//   (error) => {
//     if (error.response.status === 401) {
//       store.dispatch("logout");
//     }
//     return error;
//   }
// );

export default async function accessLiveEvent({ next, router }) {
  if(!localStorage.getItem("testgold.userInfo")){
    await store.dispatch("userInfo")
  }
  const userInfo = JSON.parse(localStorage.getItem("testgold.userInfo"))
  if(userInfo && userInfo.tier_limits.userActionsAllowedServices.includes("pageview-events")){
    return next();
  }else{
    return router.push({ name: "User Profile" });
  }
}
