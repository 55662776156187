<template>
  <div class="wrapper">
    <notifications></notifications>
    <side-bar>
      <template slot="links">
<!--        <sidebar-item-->
<!--          :link="{-->
<!--            name: $t('nav.dashboard'),-->
<!--            icon: 'fas fa-suitcase text-primary',-->
<!--            path: '/dashboard',-->
<!--          }"-->
<!--        />-->
        <sidebar-item
          :link="{
            name: $t('nav.liveEvents'),
            icon: 'ni ni-air-baloon text-primary',
            path: '/details/project-explorer/list-projects',
          }"
        />
        <sidebar-item
          :link="{
            name: 'Funnels',
            icon: 'fas fa-baby text-primary',
            path: '/details/project-explorer/funnels',
          }"
        >
        </sidebar-item>

        <sidebar-item
          :link="{
            name: 'Flows',
            icon: 'fas fa-users text-primary',
            path: '/details/project-explorer/flows',
          }"
        >
        </sidebar-item>

        <sidebar-item
          :link="{
            name: 'Snapshots',
            icon: 'fas fa-camera text-primary',
            path: '/details/project-explorer/snapshots',
          }"
        >
        </sidebar-item>

        <!-- <sidebar-item
          :link="{
            name: 'Explorer',
            icon: 'ni ni-bullet-list-67 text-primary',
          }"
        >
          <sidebar-item
            class="sub--item"
            :link="{
              name: 'Test Runs',
              icon: 'ni ni-bullet-list-67 text-primary',
              path: '/details/test-explorer/list-tests',
            }"
          />
          <sidebar-item
            class="sub--item"
            :link="{
              name: 'Projects',
              icon: 'ni ni-bullet-list-67 text-primary',
              path: '/details/test-explorer/list-projects',
            }"
          />
        </sidebar-item> -->

        <!--        <sidebar-item-->
        <!--          :link="{-->
        <!--            name: $t('nav.integrations'),-->
        <!--            icon: 'ni ni-app text-primary',-->
        <!--            path: '/details/integrations',-->
        <!--          }"-->
        <!--        />-->
        <!--        <sidebar-item-->
        <!--          :link="{-->
        <!--            name: $t('nav.installation'),-->
        <!--            icon: 'ni ni-settings-gear-65 text-primary',-->
        <!--            path: '/details/installation',-->
        <!--          }"-->
        <!--        >-->
        <!--        </sidebar-item>-->

        <sidebar-item
          :link="{
            name: $t('nav.profile'),
            icon: 'ni ni-single-02 text-primary',
            path: '/details/profile',
          }"
        >
        </sidebar-item>

        <!--        <sidebar-item-->
        <!--            :link="{-->
        <!--            name: $t('nav.subscription'),-->
        <!--            icon: 'ni ni-briefcase-24 text-primary',-->
        <!--            path: '/details/subscription',-->
        <!--          }"-->
        <!--        >-->
        <!--        </sidebar-item>-->

        <div class="sidebar--botom">
          <hr
            class="my-3"
            style="
              border-color: rgba(0, 0, 0, 0.1) currentcolor currentcolor;
              border-style: solid none none;
              border-width: 1px 0px 0px;
              border-image: none 100% / 1 / 0 stretch;
              min-width: 80%;
              overflow: visible;
              box-sizing: content-box;
              height: 0px;
            "
          />
          <!--          <sidebar-item-->
          <!--            :link="{-->
          <!--              name: $t('nav.github'),-->
          <!--              icon: 'fab fa-github mr-1',-->
          <!--              target: '_blank',-->
          <!--              isRoute: true,-->
          <!--              path: 'https://github.com/aichemydev/wring-support/issues',-->
          <!--            }"-->
          <!--            class="git&#45;&#45;btn mb-3"-->
          <!--            id="star-button"-->
          <!--          ></sidebar-item>-->
          <!--          <sidebar-item-->
          <!--            :link="{-->
          <!--              name: $t('nav.docs'),-->
          <!--              icon: 'fas fa-question-circle mr-1',-->
          <!--              target: '_blank',-->
          <!--              isRoute: true,-->
          <!--              path: 'https://aichemydev.github.io/Wring_docs/docs/intro',-->
          <!--            }"-->
          <!--            class="doc&#45;&#45;btn mb-2"-->
          <!--            id="docs-button"-->
          <!--          ></sidebar-item>-->
        </div>
      </template>
    </side-bar>
    <div class="main-content">
      <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>

      <div @click="$sidebar.displaySidebar(false)">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </fade-transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import DashboardNavbar from "./DashboardNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import { FadeTransition } from "vue2-transitions";
import axios from "axios";
import Vuex from "vuex";

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
    FadeTransition,
  },
  data() {
    return {
      roles: [],
      user: [],
      primaryColor: process.env.VUE_PRIMARY_COLOR,
      showPageview: null,
    };
  },
  async created() {
    await this.getData();
    // this.vallidePageview();
  },
  methods: {
    initScrollbar() {
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        initScrollbar("sidenav");
      }
    },
    // async vallidePageview() {
    //   if (this.user.tier_limits.userActionsAllowedServices.includes("pageview-events"))
    //     this.showPageview = true;
    // },
    async getData() {
      const res = await axios.get(
        process.env.VUE_APP_API_URL_PREFIX + `/auth/v1/users/me`
      );
      if (res.data.status === "success") {
        // this.user = res.data.response;
        // this.vallidePageview();
      } else {
        window.location.reload();
      }
    },
  },
  mounted() {
    this.initScrollbar();
  },
};
</script>
<style lang="scss">
body.g-sidenav-hidden {
  #purchase-button,
  #demo-button,
  #star-button,
  #docs-button {
    margin-left: 8px;
    margin-right: 8px;
    padding: 11px 0px;
  }
}
body.g-sidenav-show {
  #purchase-button,
  #demo-button,
  #star-button,
  #docs-button {
    margin-left: 20px;
    margin-right: 20px;
    padding: 11px 0px;
  }
}

html,
body {
  &::-webkit-scrollbar {
    width: 8px !important;
  }

  &::-webkit-scrollbar-track {
    background: #25a18e99 !important;
    border-radius: 0;
  }

  &::-webkit-scrollbar-thumb {
    background: #25a18e !important;
  }
}

.sidebar--botom {
  width: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
}

.git--btn {
  padding: 0 !important;

  a {
    margin: 0 !important;
    padding: 1rem !important;
    border-radius: 0.4rem;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    background-color: #212529;
    color: #fff !important;

    i {
      font-size: 1.1rem !important;
    }
    &:hover {
      i,
      .nav-link-text {
        color: #fff !important;
      }
    }
  }
}

.doc--btn {
  padding: 0 !important;

  a {
    margin: 0 !important;
    padding: 1rem !important;
    border-radius: 0.4rem;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);

    i {
      font-size: 1.1rem !important;
    }

    &:hover {
      i,
      .nav-link-text {
        color: initial !important;
      }
    }
  }
}
.disabled-link {
  pointer-events: none;
  a {
    color: #a6a6a6 !important;
  }
  i {
    color: #96a1e1 !important;
  }
}
.conming--soon--menu {
  // display: flex;
  align-items: center;

  a {
    padding-right: 0.4em !important;
    margin-bottom: -15px !important;
  }
}
.test-alert-cont {
  margin-left: 55px;
  border: 1px solid red !important;
  padding: 0.2em;
  background: #f9ebeb !important;
  color: red !important;
  border-radius: 2px;
  font-size: 0.5rem;
  font-weight: 700;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
