<template>
  <div class="container-fluid list-item-page mt--4">
    <div class="c--breadcrumb" :class="{ to_move: $sidebar.isMinimized }">
      <router-link to="/details/project-explorer/list-projects">
        <i class="el-icon-house"></i>
      </router-link>
      <span><i class="el-icon-d-arrow-right mx-1 cb--divider"></i></span>
      <span> Funnels </span>
    </div>
    <div class="mid--page_container">
      <div class="mid--page pb-4 mb-3">
        <div class="mp--l">
          <el-button
            @click="showListProject = !showListProject"
            icon="el-icon-thumb"
            class="ml-3 small_btn"
            plain
            size="small"
          >
            Choose project
          </el-button>
          <el-button
            @click="toggleShowFilters = !toggleShowFilters"
            icon="el-icon-guide"
            class="ml-3 small_btn dashed--btn"
            plain
            size="small"
          >
            {{ $t("list_item_page.filter") }}
          </el-button>
        </div>
      </div>
      <div
        v-loading="loadingFilter"
        class="mod--content lite mb-3"
        v-if="toggleShowFilters"
      >
        <el-form class="small-spaces" @submit.native.prevent="runFilter()">
          <el-row :gutter="24" class="ct--flex">
            <el-col :span="6">
              <el-form-item label="From">
                <el-date-picker
                  style="width: 100%"
                  type="date"
                  placeholder="from"
                  v-model="filterDate[0]"
                  :picker-options="pickerBeginDateBefore"
                  :disabledDate="pickerBeginDateBefore"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="to">
                <el-date-picker
                  style="width: 100%"
                  type="date"
                  placeholder="to"
                  v-model="filterDate[1]"
                  :picker-options="pickerBeginDateBefore"
                  :disabledDate="pickerBeginDateBefore"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label=" Type events">
                <el-select
                  style="width: 100%"
                  v-model="filterTypeEvent"
                  multiple
                  placeholder="Select type events"
                >
                  <el-option
                    v-for="item in listEvent"
                    :key="item.value"
                    :label="item.type"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label=" Session duration (in seconds)">
                <el-input-number style="width: 100%" v-model="duration" controls-position="right">
                </el-input-number>
              </el-form-item>
            </el-col>
          </el-row>
          <div>
            <base-button type="primary" native-type="submit">
              Process filter
            </base-button>
            <base-button
              type="info"
              @click="toggleShowFilters = !toggleShowFilters"
            >
              Close
            </base-button>
            <base-button type="danger" @click="resetAllFilter()">
              Reset All
            </base-button>
          </div>
        </el-form>
      </div>
      <div class="eff--page" v-if="showListProject">
        <div class="project--content--list">
          <data-list
            :tHeader="Header"
            :loading="loadingProject"
            :items="toShowProject"
            :customSizes="true"
            ref="dataList"
          >
            <template #content>
              <div
                v-for="(elt, id) in toShowProject"
                :key="id"
                class="tblb--row mb-2"
                :class="SelectProject.projectId === elt.projectId ? 'active' : ''"
                @click="showComponent(elt)"
              >
                <div class="tblb--inner">
                  <div class="elt-row" style="width: 35%">
                    <span class="test-run-name">
                      {{ elt.name }}
                    </span>
                  </div>
                  <div class="elt-row" style="width: 30%">
                    <div v-if="elt.appUrls">
                      <span v-for="(url, id) in elt.appUrls" :key="id" class="run-tags">
                        {{ url }}
                      </span>
                    </div>
                  </div>
                  <div class="elt-row" style="width: 35%">
                    <el-tooltip
                      class="item"
                      effect="light"
                      :content="elt.apiKey"
                      placement="top"
                    >
                      <span
                        class="run-tags"
                        style="
                          max-width: 100%;
                          overflow: hidden;
                          text-overflow: ellipsis;
                          white-space: nowrap;
                        "
                      >
                        {{ elt.apiKey }}
                      </span>
                    </el-tooltip>
                  </div>
                </div>
              </div>
            </template>
          </data-list>
        </div>
        <div v-if="showListFunnel" class="funnel--content--list">
          <data-list
            :tHeader="Header3"
            :loading="loadingFunnel"
            :items="toShowFunnel"
            :customSizes="true"
            ref="dataList"
          >
            <template #content>
              <div
                v-for="(elt, id) in toShowFunnel"
                :key="id"
                class="tblb--row mb-2"
                :class="SelectFunnel === elt ? 'active' : ''"
                @click="showComponentFunnel(elt)"
              >
                <div class="tblb--inner">
                  <div class="elt-row" style="width: 70%">
                    <span class="test-run-name">
                      {{ elt }}
                    </span>
                  </div>
                  <div class="elt-row" style="width: 30%">
                    <el-tooltip
                      class="item"
                      effect="light"
                      content="delete funnnel"
                      placement="top"
                    >
                      <el-button
                        type="danger"
                        icon="el-icon-delete"
                        @click.stop="deleteFunnel(elt)"
                        circle
                        plain
                        size="small"
                      ></el-button>
                    </el-tooltip>
                  </div>
                </div>
              </div>
            </template>
          </data-list>
        </div>
      </div>
      <div v-if="processFIlter">
        <div class="my-2 py-2 border-t border-solid border-gray">
          <h3 class="text-xs">Current Filter</h3>
          <div style="display: inline-flex; flex-wrap: wrap; gap: 0.5rem">
            <p
              v-if="filterDate[0]"
              class="text-sm bg-gray text-white px-2 py-1 rounded-sm m-0"
            >
              <b class="text-xs"><u>Form</u>: </b
              ><em>{{ formatDate(filterDate[0]) }}</em>
            </p>
            <p
              v-if="filterDate[1]"
              class="text-sm bg-gray text-white px-2 py-1 rounded-sm m-0"
            >
              <b class="text-xs"><u>To</u>: </b
              ><em>{{ formatDate(filterDate[1]) }}</em>
            </p>
            <p
              v-if="filterTypeEvent.length > 0"
              class="text-sm bg-gray text-white px-2 py-1 rounded-sm m-0"
            >
              <b class="text-xs"><u>Type events</u>: </b
              ><em v-for="(elt, id) in filterTypeEvent" :key="id">
                {{ elt }}
              </em>
            </p>
            <p
              v-if="duration > 0"
              class="text-sm bg-gray text-white px-2 py-1 rounded-sm m-0"
            >
              <b class="text-xs"><u>Session duration</u>: </b
              ><em> {{ duration }} s </em>
            </p>
          </div>
        </div>
      </div>
      <div class="wt--container">
        <div class="list--num_sessions_percent" v-if="pages_info.diagram">
          <div
            class="tag--percent"
            v-for="(elt, index) in pages_info.diagram.percent_sessions"
            :key="index"
            :id="`sessions_percent${index}`"
          >
            <p> {{ pages_info.diagram.num_sessions[index] }} </p>
            <p> {{ elt }} %</p>
            <i class="fas fa-compass icon--percent" @click="showSessionsPercent = true , indexSessionsPercent = index " ></i>
          </div>
        </div>
        <div class="tblb--row mb-2" v-if="loading">
          <div
            class="tblb--inner"
            style="
              display: flex;
              align-items: center;
              justify-content: center;
              background: #fff;
            "
          >
            <img
              src="/img/loading.gif"
              alt="loading..."
              style="height: 100px; width: 100px"
            />
          </div>
        </div>
        <div v-else>
          <div v-if="!pages_info.diagram">
            <p style="text-align: center">No items found</p>
          </div>
          <div class="card--body" v-else>
            <div
              class="card--content"
              v-for="(elt, index) in pages_info.diagram.screenshots"
              :key="index"
            >
              <div class="el--caed" :id="`LeaderLine${index}`">
                <div :id="`qly-card-Player${index}`" class="qly-card-Player">
                  <div
                    class="tblb--inner"
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      background: #fff;
                    "
                  >
                    <img
                      src="/img/loading.gif"
                      alt="loading..."
                      style="height: 100px; width: 100px"
                    />
                  </div>
                </div>
                <div class="botton--card" :id="`LeaderLineEnd${index}`">
                  <div>
                    <div class="tag--step bg-primary mb-2" v-if="elt.url">
                      <i class="fa fa-link mr-2"></i>
                      <span>{{ elt.url }}</span>
                    </div>
                  </div>
                  <div>
                    <div class="tag--step bg-info" v-if="elt.xpath">
                      <i class="fa fa-magnet mr-2" aria-hidden="true"></i>
                      <span>{{ elt.xpath }}</span>
                    </div>
                  </div>
                </div>
                <div class="button--step tag--step ">
                  <i class="el-icon-s-tools mr-2"></i>
                  <span>Step {{ index + 1 }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal aSessions Percent-->
    <div class="Sessions--Percent">
      <modal :show.sync="showSessionsPercent">
        <template slot="header">
          <div class="modal-title" id="showSessionsPercentModal">
            
          </div>
        </template>
        <el-tabs type="card" @tab-click="handleClick">
          
          <el-tab-pane label="Drop off Stats" v-if="pages_info.diagram">
            <el-timeline>
              <el-timeline-item
                icon="el-icon-more"
                type="primary"
                color="#0bbd87"
                size="large"
              >
                Average mouse weight time : {{ pages_info.diagram.drop_off.avg_mouse_time[indexSessionsPercent] }} sec
              </el-timeline-item>
              <el-timeline-item
                icon="el-icon-close"
                type="primary"
                color=""
                size="large"
              >
                Sessions ended : {{ pages_info.diagram.drop_off.percent_ended_sessions[indexSessionsPercent] }} %
              </el-timeline-item>
              <el-timeline-item
                icon="el-icon-thumb"
                type="primary"
                color="#909399"
                size="large"
              >
                Clicked back botton : {{ pages_info.diagram.drop_off.avg_mouse_time[indexSessionsPercent] }}
              </el-timeline-item>
              
              <el-timeline-item
                icon="el-icon-attract"
                type="primary"
                color="#e6a23c"
                size="large"
              >
                Alternate paths explored : {{ pages_info.diagram.drop_off.avg_mouse_time[indexSessionsPercent] }}
              </el-timeline-item>
            </el-timeline>
          </el-tab-pane>
          <div v-else >
            <p style="text-align: center">No items found</p>
          </div>
          <el-tab-pane label="Paths Taken">
            <div class="list--paths--taken">
              <div v-if="pages_info.diagram">
                <div v-for=" (elt, id) in pages_info.diagram.percent_sessions" :key="id" class="el--paths--taken">
                  <div>{{elt}} %</div>
                  <div>
                    <img v-if="id != 0" src="/img/theme/profile-cover.jpg" alt="" height="100px">
                    <div v-else> Session ended</div>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="Session Data">
            <data-list
              :tHeader="Header2"
              :loading="loading"
              :items="pages_info.sessions_list"
              ref="dataList"
            >
              <template #content>
                <div
                  v-for="(elt, id) in pages_info.sessions_list"
                  :key="id"
                  class="tblb--row mb-2"
                >
                  <div class="tblb--inner">
                    <div class="elt-row" style="width: 16%">
                      <span class="elt--txt">
                        {{ formatDate2(elt.start_time) }}
                      </span>
                    </div>
                    <div class="elt-row" style="width: 14%">
                      <span class="elt--txt">
                        {{ elt.recording_duration }} s
                      </span>
                    </div>
                    <div class="elt-row" style="width: 14%">
                      <span class="elt--txt">
                        {{ elt.country_name }} / {{ !elt.city_name ? elt.city_name : '' }}
                      </span>
                    </div>
                    <div class="elt-row" style="width: 14%">
                      <span class="elt--txt">
                        {{ elt.browser }}
                      </span>
                    </div>
                    <div class="elt-row" style="width: 14%">
                      <span class="elt--txt">
                        {{ elt.total_clicks }}
                      </span>
                    </div>
                    <div class="elt-row" style="width: 14%">
                      <span class="elt--txt">
                        {{ formatDate2(elt.end_time) }}
                      </span>
                    </div>
                    <div class="elt-row" style="width: 14%">
                      <i class="fas fa-play" style="font-size:20px ; color: #25a18e;" @click="detailsSession(elt)"/>
                    </div>
                  </div>
                </div>
              </template>
            </data-list>
          </el-tab-pane>
        </el-tabs>
      </modal>
    </div>
     <!-- Modal for video session start-->
     <div class="pageview-modal--funnels">
      <modal :show.sync="showVideo">
        <template slot="header">
          <h5 class="modal-title" id="sessionEventRunModal">
            video
          </h5>
        </template>
        <div class="">
          <template >
            <div class="d-flex pt-4">
              <div class="mr-4" style="width: 100%;">
                <div id="sessionVideo" style="width: 100%;">
                  <div class="tblb--row mb-2" v-if="loadingVideo">
                    <div class="tblb--inner">
                      <img
                        src="/img/loading.gif"
                        alt="loading..."
                        style="height: 100px; width: 100px"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="" style="min-width: 200px">
                <div class="mid--page_container">
                  <div class="mid--page pb-4 mb-6ty3">
                    <el-input
                      :placeholder="$t('list_item_page.search')"
                      v-model="querySessionEvent"
                    >
                      <i slot="suffix" class="el-input__icon el-icon-search"></i>
                    </el-input>
                  </div>
                </div>
                <div class="tblb--row mb-2" v-if="loadingVideo">
                  <div class="tblb--inner">
                    <img
                      src="/img/loading.gif"
                      alt="loading..."
                      style="height: 100px; width: 100px"
                    />
                  </div>
                </div>
                <div v-else class="event--liste">
                  <div
                    v-for="(ls, id) in tagsEvent"
                    :key="id"
                    class="content"
                    @click="startEvent(ls)"
                  >
                    <div class="icon--event">
                      <i :class="getIcon(ls.event)"></i>
                    </div>
                    <div class="right--content">
                      <div class="time--tag ml-3 mr-3">
                        <h4
                          style="
                            max-width: 60;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                          "
                        >
                          {{ ls.event.replace("$", " ") }}
                        </h4>
                        <span
                          style="
                            max-width: 40;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                          "
                        >
                          {{ secondsToRealTime(ls.start_time) }}
                        </span>
                      </div>
                      <div
                        style="
                          max-width: 60;
                          overflow: hidden;
                          text-overflow: ellipsis;
                          white-space: nowrap;
                          font-size: 14px;
                        "
                        class="ml-3 action"
                      >
                        {{ ls.properties.$pathname }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
        <template slot="footer">
          <div class="add-test-footer-btn">
            <base-button type="secondary" @click="showVideo = false">
              {{ $t("list_item_page.close") }}
            </base-button>
          </div>
        </template>
      </modal>
    </div>
    <!-- Modal for video session end-->

  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
  Collapse,
  CollapseItem,
} from "element-ui";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import moment from "moment";
import "moment-timezone";
import DataList from "@/components/Custom/DataList.vue";
import axios from "axios";
import rrwebPlayer from "rrweb-player";
import "rrweb-player/dist/style.css";
import LeaderLine from "leader-line-vue";
export default {
  name: "Funnels",
  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    DataList,
  },
  data() {
    return {
      pickerBeginDateBefore: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      toggleShowFilters: false,
      showListProject: true,
      Header: [
        {
          label: "Funnel name".toUpperCase(),
          key: "name",
          width: "35%",
          sortable: true,
          sortDetails: { asc: true, desc: false, type: "string" },
        },
        {
          label: "App urls".toUpperCase(),
          key: "",
          width: "30%",
          sortable: false,
        },
        {
          label: "Api key".toUpperCase(),
          key: "",
          width: "35%",
          sortable: false,
        },
      ],
      Header3: [
        {
          label: "funnel name".toUpperCase(),
          key: "name",
          width: "70%",
          sortable: true,
          sortDetails: { asc: true, desc: false, type: "string" },
        },
        {
          label: "".toUpperCase(),
          key: "",
          width: "30%",
          sortable: false,
        },
      ],
      Header2: [
        {
          label: "Start time".toUpperCase(),
          key: "name",
          width: "16%",
          sortable: true,
          sortDetails: { asc: true, desc: false, type: "string" },
        },
        {
          label: "Duration".toUpperCase(),
          key: "",
          width: "14%",
          sortable: false,
        },
        {
          label: "Location".toUpperCase(),
          key: "",
          width: "14%",
          sortable: false,
        },
        {
          label: "Browser".toUpperCase(),
          key: "",
          width: "14%",
          sortable: false,
        },
        {
          label: "Total cliks".toUpperCase(),
          key: "",
          width: "14%",
          sortable: false,
        },
        {
          label: "Idle time".toUpperCase(),
          key: "",
          width: "14%",
          sortable: false,
        },
        {
          label: "Replay".toUpperCase(),
          key: "",
          width: "14%",
          sortable: false,
        },
      ],
      toShowProject: [],
      loadingProject: false,
      toShowFunnel: [],
      loadingFunnel: false,
      loading: false,
      SelectProject: {},
      filterDate: [],
      pages_info: [],
      event: [],
      tags: {
        "start-recording": "green",
        "stop-recording": "red",
      },
      duration: 0,
      listEvent: [
        { value: "$pageview", type: "pageview" },
        { value: "$pageleave", type: "pageleave" },
      ],
      filterTypeEvent: [],
      processFIlter: false,
      loadingFilter: false,
      showSessionsPercent: false,
      indexSessionsPercent: null,
      showVideo: false,
      loadingVideo: false,
      autoplay: true,
      events: [],
      querySessionEvent: "",
      tagsEvent: {},
      resultEvent: {},
      webPlayer: null,
      showListFunnel: false,
      session_id: "" ,
      SelectFunnel: "",
    };
  },

  async created() {
    await Promise.all( [this.getProject()])
  },
  methods: {
    startEvent(elt) {
      this.webPlayer.goto(
        elt.start_time,
          false
        );
    },
    getIcon(action) {
      if (action == "$pageview") {
        return "el-icon-view";
      } else if (action == "$autocapture") {
        return "el-icon-position";
      } else if (action == "$pageleave") {
        return "el-icon-news";
      } else {
        return "el-icon-help";
      }
    },
    secondsToRealTime(b) {
      return this.hhmmss(b) == "" ? "0s" : this.hhmmss(b);
    },
    pad(num) {
      if (num) return ("" + num).slice(-2);
      return "";
    },
    hhmmss(secs) {
      let minutes = Math.floor(secs / 60);
      let dis_sec = Math.ceil(secs % 60);
      let hours = Math.floor(minutes / 60);
      minutes = minutes % 60;
      let result = "";
      if (hours > 0) {
        result = result + `${this.pad(hours)}h `;
      }
      if (minutes > 0) {
        result = result + `${this.pad(minutes)}m `;
      }
      if (dis_sec > 0) {
        result = result + `${this.pad(dis_sec)}s `;
      }
      return result;
    },
    async getProject() {
      this.loadingProject = true;
      const url = `${process.env.VUE_APP_API_URL_PREFIX}/pageviews/user-projects`;
      try {
        axios.defaults.validateStatus = (status) => {
          // return status >= 200 && status < 401; // to catch 400 error
          return status === 400 || (status >= 200 && status < 500);
        };
        const res = await axios.get(url);
        if ((res.data && res.data.status == "success") || res.data.status == 200) {
          this.toShowProject = res.data.response;
          this.SelectProject = Object.assign(this.SelectProject, res.data.response[0]);
        } else {
          this.toShowProject = [];
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          duration: 5000,
          message: "no project found ",
        });
      } finally {
        this.loadingProject = false;
      }
    },
    async getFunnel(){
      this.showListFunnel = true
      this.loadingFunnel = true;
      const url = `${process.env.VUE_APP_API_URL_PREFIX}/pageviews/funnelstorage?cmd=list&project_id=${this.SelectProject.projectId}`;
      try {
        axios.defaults.validateStatus = (status) => {
          // return status >= 200 && status < 401; // to catch 400 error
          return status === 400 || (status >= 200 && status < 500);
        };
        const res = await axios.get(url);
        if ((res.data && res.data.status == "success") || res.data.status == 200) {
          this.toShowFunnel = res.data.response.names;
        } else {
          this.toShowFunnel = [];
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          duration: 5000,
          message: "no project found ",
        });
      } finally {
        this.loadingFunnel = false;
      }
    },
    async showComponent(elt) {
      this.SelectProject = elt;
      this.getFunnel()
    },
    async showComponentFunnel(elt) {
      this.showListProject = !this.showListProject;
      this.SelectFunnel = elt
      let spans = document.querySelectorAll("svg");
      for (var s of spans) {
        s.remove();
      }
      this.loading = true;
      const url = `${process.env.VUE_APP_API_URL_PREFIX}/pageviews/funnelstorage?cmd=get&name=${elt}&limit=50&project_id=${this.SelectProject.projectId}`;
      try {
        axios.defaults.validateStatus = (status) => {
          // return status >= 200 && status < 401; // to catch 400 error
          return status === 400 || (status >= 200 && status < 500);
        };
        const res = await axios.get(url);
        if ((res.data && res.data.status == "success") || res.data.status == 200) {
          this.session_id = res.data.response.diagram.session_id;
          this.pages_info = res.data.response;
          this.loading = false;
        } else {
          this.pages_info = [];
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          duration: 5000,
          message: "an error occurred ",
        });
      } finally {
        this.loading = false;
        this.visualizationData();
      }
    },
    async visualizationData() {
      // let spans = document.querySelectorAll("svg");
      // for (var s of spans) {
      //   s.remove();
      // }
      // this.loading = true;
      // let path = this.SelectFunnel.xpath
      // const urlVisualizationdata = `${process.env.VUE_APP_API_URL_PREFIX}/pageviews/visualizationdata?limit=100&project_id=${this.SelectProject.projectId}&datatype=elements&contains_elements=[${path}]`;
      // let session_id = "";
      try {
      //   axios.defaults.validateStatus = (status) => {
      //     // return status >= 200 && status < 401; // to catch 400 error
      //     return status === 400 || (status >= 200 && status < 500);
      //   };
      //   const res = await axios.get(urlVisualizationdata);
      //   if ((res.data && res.data.status == "success") || res.data.status == 200) {
      //     session_id = res.data.response.diagram.session_id;
      //     this.pages_info = res.data.response;
      //     this.loading = false;
      //     // this.lineCard();
      //   } else {
      //     this.pages_info = [];
      //   }
        const urlSnapshots = `${process.env.VUE_APP_API_URL_PREFIX}/pageviews/snapshots?project_id=${this.SelectProject.projectId}&session_id=${this.session_id}&format=raw`;
        const res2 = await axios.get(urlSnapshots);
        if ((res2.data && res2.status == "success") || res2.status == 200) {
          this.event = res2.data;
          this.vidoeImage();
        } else {
          this.event = [];
        }
      } catch (error) {
        // this.$notify({
        //   type: "danger",
        //   duration: 5000,
        //   message: "an error occurred!",
        // });
        console.log(error);
        this.event = [];
      } finally {
        this.loading = false;
      }
    },
    lineCard() {
      setTimeout(() => {
        const totalSpet = this.pages_info.diagram.screenshots.length;
        for (
          let i = 0;
          i + 1 < this.pages_info.diagram.screenshots.length;
          i++
        ) {
          const startElement = document.getElementById(`LeaderLine${i}`);
          const endElement = document.getElementById(`LeaderLine${i + 1}`);
          const line = LeaderLine.setLine(
            LeaderLine.obj.pointAnchor(startElement, {
              x: startElement.getBoundingClientRect().width - 30,
              y: 45,
            }),
            LeaderLine.obj.pointAnchor(endElement, {
              x: endElement.getBoundingClientRect().width - 40,
              y: 45,
            }),
            {
              color: "#5e72e4",
              size: 2,
              dash: { animation: true },
            }
          );
        }
      }, 200);
    },
    vidoeImage() {
      if (
        this.pages_info.diagram.screenshots &&
        this.pages_info.diagram.screenshots.length > 0 &&
        this.event.length > 0
      ) {
        for (let i = 0; i < this.pages_info.diagram.screenshots.length; i++) {
          const events = this.event;
          document.getElementById(`qly-card-Player${i}`).innerHTML = "";
          const containerRef = document.getElementById(`qly-card-Player${i}`);
          const width = containerRef.getBoundingClientRect().width;
          try {
            const replayer = new rrwebPlayer({
              target: containerRef,
              props: {
                events,
                showController: false,
                width: width, //1000,
                height: 400, //400,
                autoPlay: false,
                mouseTail: false,
                tags: {
                  "start-recording": "green",
                  "stop-recording": "red",
                },
              },
            });
            if (this.pages_info.diagram.screenshots[i].offset_ms) {
              replayer.goto(
                this.pages_info.diagram.screenshots[i].offset_ms,
                false
              );
            }
            replayer.pause();
          } catch (error) {
            console.log(error);
          }
          this.lineCard()
        }
      }
    },
    async runFilter(){
      this.loadingFilter = true
      this.loading = true;
      let path = ""
      if(this.SelectProject.appUrls > 0 ){
        let allUrl = ""
        this.filterTypeEvent.map( item =>{
          if(allUrl.length > 10){
            allUrl = allUrl + `,{"url":"${item}", "xpath":"/html/body[1]"}`
          }else{
            allUrl = allUrl + `{"url":"${item}", "xpath":"/html/body[1]"}`
          }
        } )
        path = allUrl
      }else{
        path = `{"url": "https://demo2.testgold.dev/", "xpath": "/html/body[1]/div[1]/main[1]/div[1]/div[1]/section[1]/section[1]/section[1]/div[1]/a[1]"},{"url": "https://demo2.testgold.dev/login", "xpath": "/html/body[1]/div[1]/main[1]/div[1]/div[1]/section[1]/form[1]"}`
      }
      let urlVisualizationdata = `${process.env.VUE_APP_API_URL_PREFIX}/pageviews/visualizationdata?limit=100&project_id=${this.SelectProject.projectId}&datatype=elements&contains_elements=[${path}]`;
      if(this.filterDate[0]){
        urlVisualizationdata = urlVisualizationdata + `&date_from=${moment(this.filterDate[0]).format("YYYY-MM-DD")}`
      }
      if(this.filterDate[1]){
        urlVisualizationdata = urlVisualizationdata + `&date_to=${moment(this.filterDate[1]).format("YYYY-MM-DD")}`
      }
      if(this.duration > 0){
        urlVisualizationdata = urlVisualizationdata + `&session_recording_duration={"operator":"gt","value":${this.filterTypeEvent},"type":"recording","key":"duration"}`
      }
      if(this.filterTypeEvent.length > 0 ){
        let allEvent = ""
        this.filterTypeEvent.map( item =>{
          if(allEvent.length > 10){
            allEvent = allEvent + `,{"id":${item},"type":"events","order":0,"name":${item}}`
          }else{
            allEvent = allEvent + `{"id":${item},"type":"events","order":0,"name":${item}}`
          }
        } )
        urlVisualizationdata = urlVisualizationdata + `&events=[${allEvent}]`
      }
      let session_id = "";
      try {
        axios.defaults.validateStatus = (status) => {
          // return status >= 200 && status < 401; // to catch 400 error
          return status === 400 || (status >= 200 && status < 500);
        };
        const res = await axios.get(urlVisualizationdata);
        if (
          (res.data && res.data.status == "success") ||
          res.data.status == 200
        ) {
          session_id = res.data.response.diagram.session_id;
          this.pages_info = res.data.response;
          this.loading = false;
          this.loadingFilter = false
          this.processFIlter = true
          this.toggleShowFilters = false
          this.lineSessions_percent();
          this.lineCard();
        } else {
          this.pages_info = [];
        }
        const urlSnapshots = `${process.env.VUE_APP_API_URL_PREFIX}/pageviews/snapshots?project_id=${this.SelectProject.projectId}&session_id=${session_id}&format=raw`;
        const res2 = await axios.get(urlSnapshots);
        if ((res2.data && res2.status == "success") || res2.status == 200) {
          this.event = res2.data;
          this.vidoeImage();
        } else {
          this.event = [];
        }
      } catch (error) {
        // this.$notify({
        //   type: "danger",
        //   duration: 5000,
        //   message: "an error occurred!",
        // });
        console.log(error);
        this.pages_info = [];
        this.event = [];
      } finally {
        this.loading = false
        this.loadingFilter = false
        this.processFIlter = true
        this.toggleShowFilters = false
      }
    },
    formatDate(val) {
      return moment(val).format("MMM Do YYYY");
    },
    async resetAllFilter(){
      this.processFIlter = false
      this.toggleShowFilters = !this.toggleShowFilters
      this.filterDate = []
      this.filterTypeEvent = []
      this.duration = 0
      await this.visualizationData();
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    formatDate2(a) {
      return moment(a).format("h:mm MMM DD YYYY");
    },
    initializeSessionEvent() {
      this.tagsEvent = this.filteredResultSessionEventSet;
    },
    async detailsSession(elt) {
      document.getElementById('sessionVideo').innerHTML = "";
      await this.getEventSession(elt.id);
      let projectId = this.SelectProject.projectId;
      this.loadingVideo = true;
      this.showVideo = true;
      this.id = elt.id;
      const params = new URLSearchParams();
      params.append("project_id", projectId);
      params.append("session_id", this.id);
      axios.defaults.validateStatus = (status) => {
        return status === 400 || (status >= 200 && status < 300);
      };
      await axios
        .get(process.env.VUE_APP_API_URL_PREFIX + "/pageviews/snapshots?" + params)
        .then((response) => {
          if (response) {
            if (response.message === "Request failed with status code 500") {
              this.$notify({
                type: "danger",
                message: "the recovery of the video failed ",
              });
              this.events = [];
            } else if (response.data.status === "success") {
              this.events = [];
              // let index =  Object.entries(response.data.response.result.snapshot_data_by_window_id)[0]
              // this.events = response.data.response.result.snapshot_data_by_window_id[index[0]]
              let allVideo = [];
              response.data.response.snapshots.map((item) => {
                return Object.entries(item.result.snapshot_data_by_window_id).map(
                  (key) => {
                    this.events = this.events.concat(key[1]);
                  }
                );
              });
              this.loadingVideo = false;
            } else if (response.data.status === "failure") {
              this.$notify({
                type: "danger",
                message: "the recovery of the video failed ",
              });
              this.events = [];
            }
            this.sessionVideo()
            this.addPik();
          }
        })
        .catch((error) => {
          console.log(error);
          this.$notify({
            type: "danger",
            message: "an error occurred while retrieving data ",
          });
        });
    },
    async getEventSession(elt) {
      this.autoplay = false;
      let projectId = this.SelectProject.projectId;
      this.loadingVideo = true;
      this.showVideo = true;
      this.id = elt.id;
      const params = new URLSearchParams();
      params.append("project_id", projectId);
      params.append("session_id", this.id);
      axios.defaults.validateStatus = (status) => {
        return status === 400 || (status >= 200 && status < 300);
      };
      await axios
        .get(process.env.VUE_APP_API_URL_PREFIX + "/pageviews/events?" + params)
        .then((response) => {
          if (response) {
            if (response.message === "Request failed with status code 500") {
              this.$notify({
                type: "danger",
                message: "the recovery of the video failed ",
              });
              this.tagsEvent = {};
            } else if (response.data.status === "success") {
              this.tagsEvent = {};
              this.tagsEvent = response.data.response;
              this.tagsEvent.map((item) => {
                item["start_time"] = this.secondsToRealTimeEvent(
                  elt.start_time,
                  item.timestamp
                );
                return item;
              });
              this.resultEvent = this.tagsEvent;
              this.activeSessionDuration = elt.recording_duration;
            } else if (response.data.status === "failure") {
              this.$notify({
                type: "danger",
                message: "the recovery of the video failed ",
              });
              this.tagsEvent = {};
            }
          }
        })
        .catch((error) => {
          console.log(error);
          this.$notify({
            type: "danger",
            message: "an error occurred while retrieving data ",
          });
        });
    },
    async addPik() {
      await this.$nextTick();
      const lectBar = document.getElementsByClassName("rr-progress")[0];
      const timeLine = document.getElementsByClassName("rr-timeline")[0];
      const progress__handler = document.getElementsByClassName("rr-progress__handler")[0];
      const progress__step = document.getElementsByClassName("rr-progress__step")[0];
      const ticks = document.createElement("div");
      ticks.classList.add("ticks");

      for (let index = 0; index < this.tagsEvent.length; index++) {
        const ticks_box = document.createElement("div");
        ticks_box.classList.add("tick-hover-box");
        let left =
          (this.tagsEvent[index].start_time * 100) / this.activeSessionDuration - 0.5;
        if (left > 100) left = 100 - 0.5;
        if (left < 0) left = 0;
        ticks_box.style.left = left + "%";

        const tick_info = document.createElement("div");
        tick_info.classList.add("tick-info");
        tick_info.innerHTML = this.tagsEvent[index].event;

        const tick_marker = document.createElement("div");
        tick_marker.classList.add("tick-marker");

        const tick_thumb = document.createElement("div");
        tick_thumb.classList.add("tick-thumb");
        ticks_box.setAttribute("id", "tick_thumb_" + index);

        ticks_box.appendChild(tick_info);
        ticks_box.appendChild(tick_marker);
        ticks_box.appendChild(tick_thumb);
        ticks.appendChild(ticks_box);
      }
      console.log("hello")

      timeLine && (timeLine.style.width = "100%");
      progress__step.style.zIndex = "2";
      progress__handler.style.zIndex = "2";
      lectBar.appendChild(ticks);
      this.autoplay = true;
    },
    sessionVideo(){
      const events = this.events;
      console.log(document.getElementById('sessionVideo'))
      document.getElementById('sessionVideo').innerHTML = "";
      const containerRef = document.getElementById('sessionVideo');
      const width = containerRef.getBoundingClientRect().width;
      try {
        const replayer = new rrwebPlayer({
          target: containerRef,
          props: {
            events,
            showController: true,
            width: width, //1000,
            height: 400, //400,
            autoPlay: false,
            mouseTail: false,
            tags: {
              "start-recording": "green",
              "stop-recording": "red",
            },
          },
        });
        this.webPlayer = replayer
        // replayer.goto(
        //  0,
        //   false
        // );
        replayer.pause();
      } catch (error) {
        console.log(error);
      }
    },
    async deleteFunnel(elt){
      this.showListFunnel = true
      this.loadingFunnel = true;
      const url = `${process.env.VUE_APP_API_URL_PREFIX}/pageviews/funnelstorage?cmd=delete&name=${elt}&project_id=${this.SelectProject.projectId}`;
      try {
        axios.defaults.validateStatus = (status) => {
          // return status >= 200 && status < 401; // to catch 400 error
          return status === 400 || (status >= 200 && status < 500);
        };
        const res = await axios.delete(url);
        if ((res.data && res.data.status == "success") || res.data.status == 200) {
          this.$notify({
              type: "success",
              message: res.data.message,
            });
        } else {
          this.$notify({
              type: "danger",
              duration: 5000,
              message: res.data.message,
            });
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          duration: 5000,
          message: "an error occurred while delete the funnel !",
        });
      } finally {
        this.getFunnel()
      }
    }
  },
  computed:{
    filteredResultSessionEventSet() {
      const seq = this.resultEvent;
      return seq && Array.isArray(seq)
        ? seq.filter((item) => {
            if (this.querySessionEvent) {
              if (
                item.event.toLowerCase().includes(this.querySessionEvent.toLowerCase())
              ) {
                return item.event
                  .toLowerCase()
                  .includes(this.querySessionEvent.toLowerCase());
              } else if (
                item.properties.$pathname
                  .toLowerCase()
                  .includes(this.querySessionEvent.toLowerCase())
              ) {
                return item.properties.$pathname
                  .toLowerCase()
                  .includes(this.querySessionEvent.toLowerCase());
              }
            } else {
              return item;
            }
          })
        : [];
    },
  },
  watch: {
    querySessionEvent: {
      handler: "initializeSessionEvent",
      immediate: true,
    },
  }
};
</script>

<style lang="scss" scoped>
@import "../../../assets/sass/custom/custom-variables.scss";

//  $color: #5e72e4;
$colorPrimary: #25a18e;
.wt--container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  .card--body {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .card--content {
      position: relative;
      margin: 15px 30px;
      width: calc(100% / 2 - 60px);
      .el--caed {
        position: relative;
        // display: inline-block;
        width: 100%;
        border-radius: 5px;
        padding: 1px;
        background-color: #ffff;
        border: 1px solid #ffff;
        box-shadow: 0 2px 12px 0 #ebeef5;
        .botton--card {
          // display: inline-block;
          // position: relative;
          width: 100%;
          padding: 15px;
        }
        .bg-primary {
          border: 1px solid $colorPrimary !important;
          background-color: #25a18e23 !important;
          color: $colorPrimary !important;
        }
        .bg-info {
          border: 1px solid #d3d4d6 !important;
          color: #909399 !important;
          background: #f4f4f5 !important;
        }
      }
    }
  }
  .run-tags {
    font-size: 0.8em;
    padding: 5px;
    max-width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-top: 20px;
  }
  .qly-card-Player {
    width: 100%;
    height: 400px;
    border-radius: 5px;
    margin-bottom: 5px;
  }
}
.ct--flex {
  display: flex;
  align-items: center;
}
.time {
  font-size: 13px;
  color: #999;
}

.bottom {
  margin-top: 13px;
  line-height: 12px;
}

.button--step {
  position: absolute;
  top: 10px;
  right: 10px;
}

.image {
  width: 100%;
  display: block;
}
.run-tags {
  max-width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 20px;
}
.rr-player {
  margin-bottom: 20px !important;
}
.active {
  background-color: #f7f9fc !important;
  border: solid 1px $color;
}
.list--num_sessions_percent {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: 15px;
  margin-bottom: 15px;
  overflow: hidden;
  padding: 20px 0;
  .tag--percent {
    width: 80px;
    height: 5vh;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 30px 60px -12px rgba(50, 50, 93, 0.25), 0 18px 36px -18px rgba(0, 0, 0, 0.3), 0 -12px 36px -8px rgba(0, 0, 0, 0.025);
    position: relative;
    border: solid 1px $color;
    border-radius: 5px;
    text-align: center;
    padding: 5px;
    margin-right: 45px;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    p{
      margin-bottom: 0;
      font-size: 0.8em;
      font-weight: 500;
    }
    &::after{
      content: '';
      z-index: -1;
      position: absolute;
      height: 1px;
      width: 330px;
      // left: -2px;
      background-color: $color;
    }
    .icon--percent{
      cursor: pointer;
      color: $colorPrimary  ;
      position: absolute;
      top: -10px;
      right: -30px;
    }
    .icon--percent:hover{
      color: #25a18eb1;
    }
  }
  .tag--percent:last-child {
    &::after {
      display: none;
      height: 0; width: 0;
    }
    .icon--percent{
      display: none;
      height: 0; width: 0;
    }
  }
}
.tag--step {
  border: 1px solid $color;
  background-color: #5e72e41a;
  display: flex;
  align-items: center;
  padding: 5px;
  border-radius: 5px;
  color: $color;
  font-size: 0.8em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 600;
  max-width: 100%;
  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .bg-primary {
    border: 1px solid $color !important;
    background-color: #25a18e23 !important;
    color: $color !important;
  }
  .bg-info {
    border: 1px solid #d3d4d6 !important;
    color: #909399 !important;
    background: #f4f4f5 !important;

    i {
      margin-right: 10px;
      font-size: 1.1em !important;
    }
  }
}
.list--paths--taken{
  width: 100%;
  .el--paths--taken{
    display: flex;
    width: 100%;
    padding: 10px;
    align-items: center;
    // p{
    //   width: 30px;
    //   margin-right: 10px;
    // }
    div{
      width: auto;
      margin-right: 10px;

    }
  }
}
.container--event--liste {
    margin: 0 0 0 30px;
    padding: 8px;
    width: 100%;
    border: solid 1px #c6c6c6;
    border-radius: 5px;
    max-height: 656px;
    overflow: hidden;
  }
  .event--liste {
    max-height: 580px;
    overflow-x: auto;
    overflow-y: none;
  }
  event--liste::-webkit-scrollbar {
    display: none;
  }
  .content {
    cursor: pointer;
    overflow: hidden;
    display: flex;
    align-items: center;
    margin-bottom: 3px;
    width: 100%;
    border-radius: 5px;
    padding: 10px;
    .time--tag {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      flex-wrap: nowrap;
      margin-right: 1rem !important;
      width: 96%;
    }
    .right--content {
      width: 80%;
      flex-direction: column;
    }
    .icon--event {
      width: 20%;
      display: flex;
      background-color: #e5e5e5;
      width: 40px;
      height: 40px;
      border-radius: 5px;
      text-align: center;
      align-items: center;
      justify-content: center;
    }
  }
  .content:hover {
    background-color: #e8eeff;
    .icon--event {
      background-color: $color;
      i {
        color: #ffff;
      }
    }
  }
  .active--event {
    background-color: #e8eeff;
    .icon--event {
      background-color: $color;
      i {
        color: #ffff;
      }
    }
  }
  .snippet--inp {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  width: 100%;
}

.list--downloadTestFile {
  border-bottom: solid 1px #f8f9fe;
  cursor: pointer;
}
.list--downloadTestFile:hover {
  color: #5f72e4;
  border-bottom: solid 1px #d5d5d5;
}
.ticks {
  z-index: 1;
  position: relative;
  width: 100%;
  bottom: 100%;
}
.ticks .tick-hover-box {
  cursor: pointer;
  position: absolute;
  height: 100%;
  top: -15px;
  width: 5px;
  // right: -3px;
}
.ticks .tick-hover-box .tick-info {
  border-radius: var(--radius);
  padding: 0.25rem 0.5rem;
  position: absolute;
  top: -36px;
  background-color: var(--bg-charcoal);
  display: none;
  color: var(--light);
  white-space: nowrap;
  left: 50%;
  transform: translate(-50%);
}
.ticks .tick-hover-box .tick-marker {
  position: absolute;
  width: 1px;
  height: 21px;
  background-color: #7e7e7e;
  right: 2px;
}
.ticks .tick-hover-box .tick-thumb {
  position: absolute;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  right: -2px;
  top: -4px;
  border: 0.5px solid #7e7e7e;
  background-color: #ffff;
  transform-origin: center;
  transition: transform 0.2s;
}
.eff--page{
  display: flex;
  .funnel--content--list{
    width: 40vw;
    margin-left: 10px;
  }
  .project--content--list{
    width: 100%;
  }
}
</style>
