<template>
  <div class="container-fluid list-item-page mt--4">
    <div class="c--breadcrumb" :class="{ to_move: $sidebar.isMinimized }">
      <router-link to="/details/project-explorer/list-projects">
        <i class="el-icon-house"></i>
      </router-link>
      <span><i class="el-icon-d-arrow-right mx-1 cb--divider"></i></span>
      <span> Flows</span>
    </div>
    <div class="mid--page_container">
      <div class="mid--page pb-4 mb-3">
        <div class="mp--l">
          <el-button
            @click="showListProject = !showListProject"
            icon="el-icon-thumb"
            class="ml-3 small_btn"
            plain
            size="small"
          >
            Choose project
          </el-button>
          <el-button
            @click="toggleShowFilters = !toggleShowFilters"
            icon="el-icon-guide"
            class="ml-3 small_btn dashed--btn"
            plain
            size="small"
          >
            {{ $t("list_item_page.filter") }}
          </el-button>
        </div>
      </div>
      <div v-if="processFIlter">
        <div class="my-2 py-2 border-t border-solid border-gray">
          <h3 class="text-xs">Current Filter</h3>
          <div style="display: inline-flex; flex-wrap: wrap; gap: 0.5rem;">
            <p v-if="filterDate[0]"  class="text-sm bg-gray text-white px-2 py-1 rounded-sm m-0">
              <b class="text-xs"><u>Torm</u>: </b><em>{{formatDate(filterDate[0])}}</em>
            </p>
            <p v-if="filterDate[1]" class="text-sm bg-gray text-white px-2 py-1 rounded-sm m-0">
              <b class="text-xs"><u>To</u>: </b><em>{{formatDate(filterDate[1])}}</em>
            </p>
            <p v-if="filterTypeEvent.length > 0" class="text-sm bg-gray text-white px-2 py-1 rounded-sm m-0">
              <b class="text-xs"><u>Type events</u>: </b ><em v-for="(elt, id) in filterTypeEvent" :key="id"> {{elt}} </em>
            </p>
            <p v-if="duration > 0" class="text-sm bg-gray text-white px-2 py-1 rounded-sm m-0">
              <b class="text-xs"><u>Session duration</u>: </b><em> {{duration}} s </em>
            </p>
          </div>
        </div>
      </div>
      <div v-loading="loadingFilter" class="mod--content lite mb-3" v-if="toggleShowFilters">
        <el-form
          class="small-spaces"
          @submit.native.prevent="runFilter()"
        >
          <el-row :gutter="24" class="ct--flex">
            <el-col :span="6">
              <el-form-item label="From">
                <el-date-picker
                  style="width: 100%"
                  type="date"
                  placeholder="from"
                  v-model="filterDate[0]"
                  :picker-options="pickerBeginDateBefore"
                  :disabledDate="pickerBeginDateBefore"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="to">
                <el-date-picker
                  style="width: 100%"
                  type="date"
                  placeholder="to"
                  v-model="filterDate[1]"
                  :picker-options="pickerBeginDateBefore"
                  :disabledDate="pickerBeginDateBefore"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label=" Type events">
                <el-select style="width: 100%" v-model="filterTypeEvent" multiple placeholder="Select type events">
                  <el-option
                    v-for="item in listEvent"
                    :key="item.value"
                    :label="item.type"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label=" Session duration (in seconds)">
                <el-input-number
                  style="width: 100%"
                  v-model="duration"
                  controls-position="right"
                >
                </el-input-number>
              </el-form-item>
            </el-col>
          </el-row>
          <div>
            <base-button type="primary" native-type="submit">
              Process filter
            </base-button>
            <base-button
              type="info"
              @click="toggleShowFilters = !toggleShowFilters"
            >
              Close
            </base-button>
            <base-button
              type="danger"
              @click="resetAllFilter()"
            >
              Reset All
            </base-button>
          </div>
        </el-form>
      </div>
      <div class="eff--page" v-if="showListProject">
        <data-list
          :tHeader="Header"
          :loading="loadingProject"
          :items="toShowProject"
          :customSizes="true"
          ref="dataList"
        >
          <template #content>
            <div
              v-for="(elt, id) in toShowProject"
              :key="id"
              class="tblb--row mb-2"
              :class="SelectProject.projectId === elt.projectId ? 'active' : ''"
              @click="showComponent(elt)"
            >
              <div class="tblb--inner">
                <div class="elt-row" style="width: 35%">
                  <span class="test-run-name">
                    {{ elt.name }}
                  </span>
                </div>
                <div class="elt-row" style="width: 30%">
                  <div v-if="elt.appUrls">
                    <span
                      v-for="(url, id) in elt.appUrls"
                      :key="id"
                      class="run-tags"
                    >
                      {{ url }}
                    </span>
                  </div>
                </div>
                <div class="elt-row" style="width: 35%">
                  <el-tooltip
                    class="item"
                    effect="light"
                    :content="elt.apiKey"
                    placement="top"
                  >
                    <span
                      class="run-tags"
                      style=" max-width: 100%; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;"
                    >
                      {{ elt.apiKey }}
                    </span>
                  </el-tooltip>
                </div>
              </div>
            </div>
          </template>
        </data-list>
      </div>
      <div class="wt--container">
        <div class="tblb--row mb-2" v-if="loading">
          <div
            class="tblb--inner"
            style="display: flex; align-items: center; justify-content: center; background: #fff;  "
          >
            <img
              src="/img/loading.gif"
              alt="loading..."
              style="height: 100px; width: 100px"
            />
          </div>
        </div>
        <div v-else>
          <div v-if="!pages_info.diagram">
            <p style="text-align: center">No items found</p>
          </div>
          <div class="card--body" v-else>
            <div
              class="card--content"
              v-for="(elt, index) in pages_info.diagram.pages"
              :key="index"
            >
              <el-card :body-style="{ padding: '0px' }">
                <div
                  :ref="`qly-card-Player${index}`"
                  class="qly-card-Player"
                ></div>
                <div style="padding: 14px">
                  <div class="bottom clearfix">
                    <span class="run-tags">{{ elt.url }}</span>
                    <el-tag class="button">Step {{ index + 1 }}</el-tag>
                  </div>
                </div>
              </el-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
  Collapse,
  CollapseItem,
} from "element-ui";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import moment from "moment";
import "moment-timezone";
import DataList from "@/components/Custom/DataList.vue";
import axios from "axios";
import rrwebPlayer from "rrweb-player";
import "rrweb-player/dist/style.css";
export default {
  name: "Flows",
  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    DataList,
  },
  data() {
    return {
      pickerBeginDateBefore: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      toggleShowFilters: false,
      showListProject: false,
      Header: [
        {
          label: "Project name".toUpperCase(),
          key: "name",
          width: "35%",
          sortable: true,
          sortDetails: { asc: true, desc: false, type: "string" },
        },
        {
          label: "App urls".toUpperCase(),
          key: "",
          width: "30%",
          sortable: false,
        },
        {
          label: "Api key".toUpperCase(),
          key: "",
          width: "35%",
          sortable: false,
        },
      ],
      toShowProject: [],
      loadingProject: false,
      loading: true,
      SelectProject: {},
      filterDate: [],
      pages_info: [],
      event: [],
      tags: {
        "start-recording": "green",
        "stop-recording": "red",
      },
      duration: 0,
      listEvent: [
        { value: "$pageview", type: "pageview" },
        { value: "$pageleave", type: "pageleave" },
      ],
      filterTypeEvent: [],
      processFIlter: false,
      loadingFilter: false,
    };
  },

  async created() {
    await this.getProject();
    await this.visualizationData();
    await this.vidoeCapture();
  },

  methods: {
    async resetAllFilter(){
      this.toggleShowFilters = !this.toggleShowFilters
      this.filterDate = []
      this.filterTypeEvent = []
      this.processFIlter = false
      this.duration = 0
      await this.visualizationData();
      await this.vidoeCapture();
    },
    async runFilter(){
      this.loadingFilter = true
      this.loading = true;
      let urlVisualizationdata = `${process.env.VUE_APP_API_URL_PREFIX}/pageviews/visualizationdata?limit=100&project_id=${this.SelectProject.projectId}`;
      if(this.filterDate[0]){
        urlVisualizationdata = urlVisualizationdata + `&date_from=${moment(this.filterDate[0]).format("YYYY-MM-DD")}`
      }
      if(this.filterDate[1]){
        urlVisualizationdata = urlVisualizationdata + `&date_to=${moment(this.filterDate[1]).format("YYYY-MM-DD")}`
      }
      if(this.duration > 0){
        urlVisualizationdata = urlVisualizationdata + `&session_recording_duration={"operator":"gt","value":${this.filterTypeEvent},"type":"recording","key":"duration"}`
      }
      if(this.filterTypeEvent.length > 0 ){
        let allEvent = ""
        this.filterTypeEvent.map( item =>{
          if(allEvent.length > 10){
            allEvent = allEvent + `,{"id":${item},"type":"events","order":0,"name":${item}}`
          }else{
            allEvent = allEvent + `{"id":${item},"type":"events","order":0,"name":${item}}`
          }
        } )
        urlVisualizationdata = urlVisualizationdata + `&events=[${allEvent}]`
      }
      let session_id = "";
      try {
        axios.defaults.validateStatus = (status) => {
          // return status >= 200 && status < 401; // to catch 400 error
          return status === 400 || (status >= 200 && status < 500);
        };
        const res = await axios.get(urlVisualizationdata);
        if (
          (res.data && res.data.status == "success") ||
          res.data.status == 200
        ) {
          session_id = res.data.response.diagram.session_id;
          this.pages_info = res.data.response;
          this.loading = false;
          this.loadingFilter = false
          this.processFIlter = true
          this.toggleShowFilters = false
        } else {
          this.pages_info = [];
        }
        const urlSnapshots = `${process.env.VUE_APP_API_URL_PREFIX}/pageviews/snapshots?project_id=${this.SelectProject.projectId}&session_id=${session_id}&format=raw`;
        const res2 = await axios.get(urlSnapshots);
        if ((res2.data && res2.status == "success") || res2.status == 200) {
          this.event = res2.data;
          this.vidoeCapture();
        } else {
          this.event = [];
        }
      } catch (error) {
        console.log(error)
        this.pages_info = [];
        this.event = [];
      } finally {
        this.loading = false;
        this.loadingFilter = false
        this.processFIlter = true
        this.toggleShowFilters = false
      }

    },
    async getProject() {
      this.loadingProject = true;
      const url = `${process.env.VUE_APP_API_URL_PREFIX}/pageviews/user-projects`;
      try {
        axios.defaults.validateStatus = (status) => {
          // return status >= 200 && status < 401; // to catch 400 error
          return status === 400 || (status >= 200 && status < 500);
        };
        const res = await axios.get(url);
        if (
          (res.data && res.data.status == "success") ||
          res.data.status == 200
        ) {
          this.toShowProject = res.data.response;
          this.SelectProject = Object.assign(
            this.SelectProject,
            res.data.response[0]
          );
        } else {
          this.toShowProject = [];
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          duration: 5000,
          message: "no project found ",
        });
      } finally {
        this.loadingProject = false;
      }
    },
    async showComponent(elt) {
      this.SelectProject = elt;
      this.showListProject = !this.showListProject;
      if(this.processFIlter){
        this.runFilter()
      }else{
        await this.visualizationData();
        await this.vidoeCapture();
      }
    },
    async visualizationData() {
      this.loading = true;
      const urlVisualizationdata = `${process.env.VUE_APP_API_URL_PREFIX}/pageviews/visualizationdata?limit=100&project_id=${this.SelectProject.projectId}`;
      let session_id = "";
      try {
        axios.defaults.validateStatus = (status) => {
          // return status >= 200 && status < 401; // to catch 400 error
          return status === 400 || (status >= 200 && status < 500);
        };
        const res = await axios.get(urlVisualizationdata);
        if (
          (res.data && res.data.status == "success") ||
          res.data.status == 200
        ) {
          session_id = res.data.response.diagram.session_id;
          this.pages_info = res.data.response;
        } else {
          this.pages_info = [];
        }
        const urlSnapshots = `${process.env.VUE_APP_API_URL_PREFIX}/pageviews/snapshots?project_id=${this.SelectProject.projectId}&session_id=${session_id}&format=raw`;
        const res2 = await axios.get(urlSnapshots);
        if ((res2.data && res2.status == "success") || res2.status == 200) {
          this.event = res2.data;
        } else {
          this.event = [];
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          duration: 5000,
          message: "no visualization data found ",
        });
        this.pages_info = [];
        this.event = [];
      } finally {
        this.loading = false;
      }
    },
    vidoeCapture() {
      if (
        this.pages_info.diagram.pages &&
        this.pages_info.diagram.pages.length > 0 &&
        this.event.length > 0
      ) {
        for (let i = 0; i < this.pages_info.diagram.pages.length; i++) {
          try {
            const events = this.event;
            const containerRef = this.$refs[`qly-card-Player${i}`][0];
            const width = containerRef.getBoundingClientRect().width;
            const replayer = new rrwebPlayer({
              target: containerRef,
              props: {
                events,
                showController: false,
                width: width, //1000,
                height: 400, //400,
                autoPlay: false,
                mouseTail: false,
                tags: {
                  "start-recording": "green",
                  "stop-recording": "red",
                },
              },
            });
            if (this.pages_info.diagram.pages[i].offset_ms) {
              replayer.goto(this.pages_info.diagram.pages[i].offset_ms, false);
            }
            replayer.pause();
          } catch (error) {
            console.log(error)
          }
        }
      }
    },
    formatDate(val) {
      return moment(val).format("MMM Do YYYY");
    },
  },
};
</script>

<style lang="scss" scoped>
$color: #25a18e;
.wt--container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  .card--body {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .card--content {
      margin: 15px 30px;
      width: calc(100% / 2 - 60px);
    }
  }
  .run-tags {
    font-size: 0.8em;
    padding: 5px;
    max-width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-top: 20px;
  }
  .qly-card-Player {
    width: 100%;
  }
}
.ct--flex {
  display: flex;
  align-items: center;
}
.time {
  font-size: 13px;
  color: #999;
}

.bottom {
  margin-top: 13px;
  line-height: 12px;
}

.button {
  margin-top: 20px;
  float: right;
}

.image {
  width: 100%;
  display: block;
}
.run-tags {
  max-width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 20px;
}
.rr-player {
  margin-bottom: 20px !important;
}
.active {
  background-color: #f7f9fc !important;
  border: solid 1px $color;
}
</style>
