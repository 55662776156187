<template>
  <div class="container-fluid list-item-page mt--4">
    <div class="c--breadcrumb" :class="{ to_move: $sidebar.isMinimized }">
      <router-link to="/">
        <i class="el-icon-house"></i>
      </router-link>
      <span><i class="el-icon-d-arrow-right mx-1 cb--divider"></i></span>
      <span> List projects</span>
    </div>
    <template >
      <div class="mid--page_container">
        <div class="mid--page my-3 pb-3">
          <div class="mp--l">
            <el-input :placeholder="$t('list_item_page.search')" v-model="queryProject">
              <i slot="suffix" class="el-input__icon el-icon-search"></i>
            </el-input>
            <el-button
              @click="addTestModalProject"
              icon="el-icon-plus"
              class="ml-3 small_btn"
              plain
              size="small"
            >
              Add new project
            </el-button>
          </div>
        </div>
      </div>
      <div class="eff--page">
        <data-list
          :tHeader="Header"
          :loading="loadingProject"
          :items="toShowProject"
          :customSizes="true"
          ref="dataList"
        >
          <template #content>
            <div
              v-for="(elt, id) in toShowProject"
              :key="id"
              class="tblb--row mb-2"
              @click="showComponent(elt.projectId)"
            >
              <div class="tblb--inner">
                <div class="elt-row" style="width: 50%">
                  <span class="test-run-name">
                    {{ elt.name }}
                  </span>
                </div>
                <div class="elt-row" style="width: 40%">
                  <el-tooltip
                    class="item"
                    effect="light"
                    :content="elt.apiKey"
                    placement="top"
                  >
                    <span
                      class="run-tags"
                      style="
                        max-width: 100%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                      "
                    >
                      {{ elt.apiKey }}
                    </span>
                  </el-tooltip>
                </div>
                <div class="elt-row" style="width: 15%">
                  <el-tooltip
                    class="item"
                    effect="light"
                    content="Edit this project"
                    placement="top"
                  >
                    <el-button
                      type="primary"
                      icon="el-icon-edit"
                      @click.stop="editProjectModal(elt)"
                      circle
                      plain
                      size="small"
                    ></el-button>
                  </el-tooltip>

                  <el-tooltip
                    class="item"
                    effect="light"
                    content="Project details"
                    placement="top"
                  >
                    <el-button
                      type="success"
                      icon="el-icon-folder-opened"
                      @click.stop="
                        (selectProjectId = elt.projectId), detailProject(elt.projectId)
                      "
                      circle
                      plain
                      size="small"
                    ></el-button>
                  </el-tooltip>

                  <el-tooltip
                    class="item"
                    effect="light"
                    content="Delete this project"
                    placement="top"
                  >
                    <el-button
                      type="danger"
                      icon="el-icon-delete"
                      @click.stop="deleteProject(elt)"
                      circle
                      plain
                      size="small"
                    ></el-button>
                  </el-tooltip>
                </div>
              </div>
            </div>
          </template>
        </data-list>
      </div>
    </template>


    <!-- Modal add project start-->
    <div class="add-test-project-modal">
      <modal :show.sync="addTestProject">
        <template slot="header">
          <h5 class="modal-title">
            {{
              editProject == true
                ? " edit project " + projectName
                : " Add new pageview project"
            }}
          </h5>
        </template>
        <div class="body--modale" v-loading="loadingAddProject">
          <div class="modal-add-containt">
            <div class="add-test-project-modal">
              <div class="run-name">
                <label class="model-label" for="project_name"> PROJECT NAME </label>
                <input
                  type="text"
                  id="project_name"
                  name="project_name"
                  placeholder="Project name"
                  v-model="projectName"
                />
                <label class="model-label" for="app_urls"> APP URLs </label>
                <div class="ultra--ipt">
                  <div class="ultra--ipt_content pl-2" ref="data-rendered"></div>
                  <input
                    type="text"
                    class="ultra--ipt_ipt px-2"
                    @input="handleUltraIpt"
                    @change="handleUltraIpt"
                    @keyup.backspace="handleDeleteLastDataValue"
                    v-model="ultraIpt"
                  />
                </div>
                <label v-if="editProject == true" class="model-label" for="api_Key">
                  API KEY
                </label>
                <input
                  type="text"
                  id="api_Key"
                  disabled="true"
                  name="api_Key"
                  placeholder="api Key"
                  v-if="editProject == true"
                  v-model="projectDetail.apiKey"
                />
                <label v-if="editProject == true" class="model-label" for="server_Url">
                  SERVER URL
                </label>
                <input
                  type="text"
                  id="server_Url"
                  disabled="true"
                  name="server_Url"
                  placeholder="server Url"
                  v-if="editProject == true"
                  v-model="projectDetail.serverUrl"
                />
                <div v-if="editProject == true" class="snippet--inp">
                  <label class="model-label" for="js_Snppet"> JS SNIPPET </label>
                  <i
                    class="ni ni-single-copy-04"
                    @click="copy(projectDetail.jsSnppet, 'js snippet')"
                  ></i>
                </div>
                <div v-if="editProject == true" class="script p-4">
                  <template>
                    <p
                      class="text-white text-xs"
                      style="overflow: hidden; text-overflow: ellipsis"
                    >
                      {{ projectDetail.jsSnppet }}
                    </p>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
        <template slot="footer">
          <div class="add-test-footer-btn">
            <base-button
              type="secondary"
              @click="
                addTestProject = false;
                cleanUpProject();
              "
              >{{ $t("list_item_page.close") }}</base-button
            >
            <div v-if="editProject">
              <el-tooltip
                :disabled="!(projectName.length === 0)"
                :content="'project name cannot be empty'"
                placement="top"
              >
                <span>
                  <base-button
                    :disabled="projectName.length === 0 || loadingAddProject"
                    type="primary"
                    @click="addProject('edit')"
                  >
                    Edit
                  </base-button>
                </span>
              </el-tooltip>
            </div>
            <div v-else>
              <el-tooltip
                :disabled="!(projectName.length === 0)"
                :content="`${
                  projectName.length === 0 ? 'project name cannot be empty' : ''
                } `"
                placement="top"
              >
                <span>
                  <base-button
                    :disabled="projectName.length === 0 || loadingAddProject"
                    type="primary"
                    @click="addProject('add')"
                  >
                    add new project
                  </base-button>
                </span>
              </el-tooltip>
            </div>
          </div>
        </template>
      </modal>
    </div>
    <!-- Modal add project end-->

    <!-- Modal detail project start-->
    <div class="show-test-project-modal">
      <modal :show.sync="showDetailProject">
        <template slot="header">
          <h5 class="modal-title" id="showprojectDetailModal">
            {{ projectDetail.name }} project details
          </h5>
        </template>
        <div class="body--modale" v-loading="loadingDetailProject">
          <div class="modal-add-containt">
            <div class="add-test-project-modal">
              <div class="run-name">
                <label class="model-label" for="project_name"> Project name </label>
                <input
                  type="text"
                  id="project_name"
                  disabled="true"
                  name="project_name"
                  placeholder="Project name"
                  v-model="projectDetail.name"
                />
                <label class="model-label" for="app_urls"> App URLs </label>
                <div class="ultra--ipt">
                  <div class="ultra--ipt_content pl-2" ref="data-rendered-detail"></div>
                  <input
                    type="text"
                    disabled="true"
                    class="ultra--ipt_ipt px-2"
                    @input="handleUltraIpt"
                    @change="handleUltraIpt"
                    @keyup.backspace="handleDeleteLastDataValue"
                    v-model="ultraIpt"
                  />
                </div>
                <label class="model-label" for="api_Key"> API KEY </label>
                <input
                  type="text"
                  id="api_Key"
                  disabled="true"
                  name="api_Key"
                  placeholder="api Key"
                  v-model="projectDetail.apiKey"
                />
                <label class="model-label" for="server_Url"> SERVER URL </label>
                <input
                  type="text"
                  id="server_Url"
                  disabled="true"
                  name="server_Url"
                  placeholder="server Url"
                  v-model="projectDetail.serverUrl"
                />
                <div class="snippet--inp">
                  <label class="model-label" for="js_Snppet"> JS Snippet </label>
                  <i
                    class="ni ni-single-copy-04"
                    @click="copy(projectDetail.jsSnppet, 'js snippet')"
                  ></i>
                </div>
                <div class="script p-4">
                  <template>
                    <p
                      class="text-white text-xs"
                      style="overflow: hidden; text-overflow: ellipsis"
                    >
                      {{ projectDetail.jsSnppet }}
                    </p>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
        <template slot="footer">
          <div class="add-test-footer-btn">
            <base-button type="secondary" @click="showDetailProject = false">{{
              $t("list_item_page.close")
            }}</base-button>
          </div>
        </template>
      </modal>
    </div>
    <!-- Modal detail project end-->
  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
  Collapse,
  CollapseItem,
} from "element-ui";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import moment from "moment";
import "moment-timezone";
import DataList from "@/components/Custom/DataList.vue";
import axios from "axios";

export default {
  layout: "DashboardLayout",
  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    DataList,
  },

  data() {
    return {
      pickerBeginDateBefore: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      Header: [
        {
          label: "Project name".toUpperCase(),
          key: "name",
          width: "50%",
        },
        {
          label: "Api key".toUpperCase(),
          key: "",
          width: "40%",
          sortable: false,
        },
        { label: "".toUpperCase(), key: "", width: "15%", sortable: false },
      ],
      paginationProject: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
      },
      result: [],
      filteredResults: [],
      resultProject: [],
      filteredResultsProject: [],
      toProject: 0,
      fromProject: 0,
      totalProject: 0,
      loadingProject: true,
      toShowProject: [],
      queryProject: "",
      id: "",
      lastUrlTest: "",
      addTestProject: false,
      appUrls: "",
      appUrlsLs: [],
      loadingAddProject: false,
      editProject: false,
      showDetailProject: false,
      projectDetail: {
        name: "",
        appUrls: "",
        apiKey: "",
        serverUrl: "",
        jsSnppet: "",
      },
      newProjectDetail: {
        name: "",
        appUrls: "",
        apiKey: "",
        serverUrl: "",
        jsSnppet: "",
      },
      loadingDetailProject: true,
      ultraIpt: "",
      projectName: "",
    };
  },
  async created() {
    sessionStorage.getItem("projectPageNbr")
      ? (this.paginationProject.perPage = parseInt(
          sessionStorage.getItem("projectPageNbr")
        ))
      : (this.paginationProject.perPage = 10);
    this.total = this.result.length;
    this.totalProject = this.resultProject.length;
    await this.getProject();
  },
  methods: {
    showComponent(id){
      if(id){
        this.$router.push({
          name: "List Sessions",
          params: {
            id: id,
          },
        });
      }
    },
    remove(i) {
      this.filesList.splice(i, 1);
    },
    restoreAllFiles() {
      this.filesList = this.fileBackup;
    },
    copy(text, type) {
      if (text.length > 0) {
        navigator.clipboard.writeText(text).then(() => {
          this.$notify({
            type: "success",
            message: type + " Copied Successfully.",
          });
        });
      }
    },
    handleUltraIpt() {
      if (this.ultraIpt.length > 0 && this.ultraIpt[0] === " ") {
        this.ultraIpt = "";
      } else if (this.ultraIpt.slice(-1) === " ") {
        const elt = document.createElement("span");
        elt.classList.add("ml-1");
        elt.classList.add("u--tag");
        elt.innerHTML = " " + this.ultraIpt.trim() + " ";
        if (this.editProject === true) {
          this.addUrls(this.ultraIpt);
        }
        this.ultraIpt = "";
        if (this.$refs["data-rendered"].innerHTML.length > 1) {
          const space = document.createElement("span");
          space.classList.add("ml-1");
          space.innerHTML = " ";
          this.$refs["data-rendered"].appendChild(space);
          this.appUrls = space;
        }
        this.$refs["data-rendered"].appendChild(elt);
        this.appUrls = elt;
      } else if (this.ultraIpt.length > 0 && this.ultraIpt[0] != " ") {
        this.appUrls = this.ultraIpt;
      } else if (this.ultraIpt.length === 0) {
        this.appUrls = "";
      }
    },
    handleDeleteLastDataValue() {
      let getStr = this.$refs["data-rendered"].innerHTML
        .replace(/<span class="ml-1 u--tag">/g, "")
        .replace(/<span class="ml-1">/g, "")
        .replace(/<\/span>/g, ",")
        .split(",")
        .filter((s) => s.trim().length > 0)
        .map((s) => s.trim());
      if (this.ultraIpt.length <= 0) {
        if (this.editProject === true) {
          this.deleteUrls(getStr.pop());
        } else {
          let _rem = getStr.splice(-1);
        }
        this.$refs["data-rendered"].innerHTML = "";
        getStr.map((item) => {
          const elt = document.createElement("span");
          elt.classList.add("ml-1");
          elt.classList.add("u--tag");
          elt.innerHTML = " " + item + " ";
          this.$refs["data-rendered"].appendChild(elt);
          this.appUrlsLs.push(elt);
        });
      } else if (this.ultraIpt.length === 0) {
        this.appUrls = "";
      }
    },
    cleanUpProject() {
      this.projectName = "";
      this.appUrls = "";
    },
    addTestModalProject() {
      this.addTestProject = true;
      this.appUrls = "";
      this.projectName = "";
      this.projectId = null;
      this.editProject = false;
      this.$refs["data-rendered"].innerHTML = "";
    },
    pad(num) {
      if (num) return ("" + num).slice(-2);
      return "";
    },
    downloadTestFile(index) {
      // console.log("Before downloading file: ", files);
      if (index) {
        const files = this.fileBackup;
        let fileName = files[index].name;
        var fileURL = window.URL.createObjectURL(new Blob([files[index]]));
        var fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", fileName);
        document.body.appendChild(fileLink);
        fileLink.click();
      } else {
        const files = this.filesList;
        let fileName = files[0].name;
        var fileURL = window.URL.createObjectURL(new Blob([files[0]]));
        var fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", fileName);
        document.body.appendChild(fileLink);
        fileLink.click();
      }
    },
    savePaginationProject() {
      sessionStorage.setItem("projectPageNbr", this.paginationProject.perPage);
      this.getProject();
    },
    handleSizeChangeProject(val) {
      this.fromProject = (val - 1) * this.paginationProject.perPage;
      this.toProject =
        parseInt(this.fromProject) + parseInt(this.paginationProject.perPage);
      const sortByMapped = (map, compareFn) => (a, b) => compareFn(map(a), map(b));
      const byValue = (a, b) => a - b;
      const toPrice = (e) => e.price;
      const byPrice = sortByMapped(toPrice, byValue);
      this.filteredResultsProject = [...this.filteredResultsProject].sort(byPrice);
      this.toShowProject = this.filteredResultsProject;
    },
    initializeProjectState() {
      this.filteredResultsProject = this.filteredResultProjectSet;
      this.handleSizeChangeProject(this.paginationProject.currentPage);
    },
    parseRunTags(runTags) {
      if (runTags.length === 0) {
        return [];
      }

      const splitTags = runTags.split(",");
      return splitTags.map((item) => {
        return item.trim().toLowerCase();
      });
    },
    async getProject() {
      this.loadingProject = true;
      const url = `${process.env.VUE_APP_API_URL_PREFIX}/pageviews/user-projects`;
      try {
        axios.defaults.validateStatus = (status) => {
          // return status >= 200 && status < 401; // to catch 400 error
          return status === 400 || (status >= 200 && status < 500);
        };
        const res = await axios.get(url);
        if ((res.data && res.data.status == "success") || res.data.status == 200) {
          this.resultProject = res.data.response;
          this.totalProject = res.data.response.length;
        } else {
          this.resultProject = [];
          this.totalProject = 0;
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          duration: 5000,
          message: "no project found ",
        });
      } finally {
        this.loadingProject = false;
        this.filteredResultsProject = this.filteredResultProjectSet;
        this.initializeProjectState();
      }
    },
    async addProject(val) {
      this.loadingAddProject = true;
      if (val === "add") {
        let appUrls = this.$refs["data-rendered"].innerHTML
          .replace(/<span class="ml-1 u--tag">/g, "")
          .replace(/<span class="ml-1">/g, "")
          .replace(/<\/span>/g, ",")
          .split(",")
          .filter((s) => s.trim().length > 0)
          .map((s) => s.trim());
        if (this.ultraIpt) appUrls.push(this.ultraIpt);
        this.ultraIpt = "";
        let data = {
          // appUrls: appUrls,
          name: this.projectName,
        };
        if (appUrls) {
          data["appUrls"] = appUrls;
        }
        const url = `${process.env.VUE_APP_API_URL_PREFIX}/pageviews/user-projects`;
        try {
          axios.defaults.validateStatus = (status) => {
            // return status >= 200 && status < 401; // to catch 400 error
            return status === 400 || (status >= 200 && status < 500);
          };
          const res = await axios.post(url, data);
          if ((res.data && res.data.status == "success") || res.data.status == 200) {
            this.detailProject(res.data.response.projectId);
            this.$notify({
              type: "success",
              message: res.data.message,
            });
            this.addTestProject = false;
            this.cleanUpProject();
          } else {
            this.$notify({
              type: "danger",
              duration: 5000,
              message: res.data.message,
            });
          }
        } catch (error) {
          this.$notify({
            type: "danger",
            duration: 5000,
            message: "an error occurred while add the project.",
          });
        } finally {
          this.loadingAddProject = false;
          this.getProject();
        }
      }
      if (val === "edit") {
        const url = `${process.env.VUE_APP_API_URL_PREFIX}/pageviews/user-projects/${this.projectId}`;
        let data = {
          name: this.projectName,
        };
        let appUrls = "";
        if (this.ultraIpt.length > 1) appUrls = this.ultraIpt;
        if (appUrls.length > 1) {
          this.addUrls(appUrls);
        }
        try {
          axios.defaults.validateStatus = (status) => {
            // return status >= 200 && status < 401; // to catch 400 error
            return status === 400 || (status >= 200 && status < 500);
          };
          const res = await axios.put(url, data);
          if ((res.data && res.data.status == "success") || res.data.status == 200) {
            this.$notify({
              type: "success",
              message: res.data.message,
            });
          } else {
            this.$notify({
              type: "danger",
              duration: 5000,
              message: res.data.message,
            });
          }
        } catch (error) {
          this.$notify({
            type: "danger",
            duration: 5000,
            message: "an error occurred while edit the project .",
          });
        } finally {
          this.projectDetail = Object.assign({}, this.newProjectDetail);
          this.loadingAddProject = false;
          this.addTestProject = false;
          this.cleanUpProject();
          this.getProject();
          this.ultraIpt = "";
        }
      }
    },
    async editProjectModal(row) {
      this.ultraIpt = "";
      this.loadingAddProject = true;
      this.addTestProject = true;
      this.projectName = row.name;
      this.editProject = true;
      this.projectId = row.projectId;

      this.$refs["data-rendered"].innerHTML = "";
      const id = row.projectId;
      const url = `${process.env.VUE_APP_API_URL_PREFIX}/pageviews/user-projects/${id}`;
      try {
        axios.defaults.validateStatus = (status) => {
          // return status >= 200 && status < 401; // to catch 400 error
          return status === 400 || (status >= 200 && status < 500);
        };
        const res = await axios.get(url);
        if ((res.data && res.data.status === "success") || res.data.status === 200) {
          res.data.response.appUrls.map((item) => {
            const elt = document.createElement("span");
            elt.classList.add("ml-1");
            elt.classList.add("u--tag");
            elt.innerHTML = " " + item + " ";
            this.$refs["data-rendered"].appendChild(elt);
          });
          this.projectDetail = Object.assign({}, res.data.response);
          this.projectDetail.jsSnppet = `<script> !function(t,e){var o,n,p,r;e.__SV||(window.wring=e,e._i=[],e.init=function(i,s,a){function g(t,e){var o=e.split(".");2==o.length&&(t=t[o[0]],e=o[1]),t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}}(p=t.createElement("script")).type="text/javascript",p.async=!0,p.src="https://wring.dev/assets/js/array.js",(r=t.getElementsByTagName("script")[0]).parentNode.insertBefore(p,r);var u=e;for(void 0!==a?u=e[a]=[]:a="wring",u.people=u.people||[],u.toString=function(t){var e="wring";return"wring"!==a&&(e+="."+a),t||(e+=" (stub)"),e},u.people.toString=function(){return u.toString(1)+".people (stub)"},o="capture identify alias people.set people.set_once set_config register register_once unregister opt_out_capturing has_opted_out_capturing opt_in_capturing reset isFeatureEnabled onFeatureFlags".split(" "),n=0;n<o.length;n++)g(u,o[n]);e._i.push([i,s,a])},e.__SV=1)}(document,window.wring||[]);wring.init('${this.projectDetail.apiKey}',{advanced_capture_all_elements: true, enable_recording_console_log: true, api_host:'${this.projectDetail.serverUrl}'}) <\/script> `;
          this.loadingAddProject = false;
        } else {
          this.loadingAddProject = false;
          this.addTestProject = false;
          this.editProject = false;
          this.$notify({
            type: "danger",
            duration: 5000,
            message: res.data.message,
          });
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          duration: 5000,
          message: "an error occurred while edit the project .",
        });
      }
    },
    async deleteProject(row) {
      const confirmation = await swal.fire({
        title: "Project '" + row.name + "' will be removed.",
        type: "question",
        buttonsStyling: true,
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, keep the project.",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });
      const id = row.projectId;
      const url = `${process.env.VUE_APP_API_URL_PREFIX}/pageviews/user-projects/${id}`;
      try {
        axios.defaults.validateStatus = (status) => {
          // return status >= 200 && status < 401; // to catch 400 error
          return status === 400 || (status >= 200 && status < 300);
        };
        if (confirmation.value === true) {
          const res = await axios.delete(url);
          if ((res.data && res.data.status == "success") || res.data.status == 200) {
            this.$notify({
              type: "success",
              message: res.data.message,
            });
          } else {
            this.$notify({
              type: "danger",
              duration: 5000,
              message: res.data.message,
            });
          }
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          duration: 5000,
          message: "an error occurred while edit the project .",
        });
      } finally {
        this.getProject();
      }
    },
    async detailProject(row) {
      this.$refs["data-rendered-detail"].innerHTML = "";
      this.projectDetail = Object.assign({}, this.newProjectDetail);
      this.showDetailProject = true;
      this.loadingDetailProject = true;
      const id = row;
      const url = `${process.env.VUE_APP_API_URL_PREFIX}/pageviews/user-projects/${id}`;
      try {
        axios.defaults.validateStatus = (status) => {
          // return status >= 200 && status < 401; // to catch 400 error
          return status === 400 || (status >= 200 && status < 500);
        };
        const res = await axios.get(url);
        if ((res.data && res.data.status == "success") || res.data.status == 200) {
          this.projectDetail = Object.assign({}, res.data.response);
          this.projectDetail.appUrls.map((item) => {
            const elt = document.createElement("span");
            elt.classList.add("ml-1");
            elt.classList.add("u--tag");
            elt.innerHTML = " " + item + " ";
            this.$refs["data-rendered-detail"].appendChild(elt);
          });
          this.projectDetail.jsSnppet = `<script> !function(t,e){var o,n,p,r;e.__SV||(window.wring=e,e._i=[],e.init=function(i,s,a){function g(t,e){var o=e.split(".");2==o.length&&(t=t[o[0]],e=o[1]),t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}}(p=t.createElement("script")).type="text/javascript",p.async=!0,p.src="https://wring.dev/assets/js/array.js",(r=t.getElementsByTagName("script")[0]).parentNode.insertBefore(p,r);var u=e;for(void 0!==a?u=e[a]=[]:a="wring",u.people=u.people||[],u.toString=function(t){var e="wring";return"wring"!==a&&(e+="."+a),t||(e+=" (stub)"),e},u.people.toString=function(){return u.toString(1)+".people (stub)"},o="capture identify alias people.set people.set_once set_config register register_once unregister opt_out_capturing has_opted_out_capturing opt_in_capturing reset isFeatureEnabled onFeatureFlags".split(" "),n=0;n<o.length;n++)g(u,o[n]);e._i.push([i,s,a])},e.__SV=1)}(document,window.wring||[]);wring.init('${this.projectDetail.apiKey}',{advanced_capture_all_elements: true, enable_recording_console_log: true, api_host:'${this.projectDetail.serverUrl}'}) <\/script> `;
          this.loadingDetailProject = false;
        } else {
          this.$notify({
            type: "danger",
            duration: 5000,
            message: res.data.message,
          });
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          duration: 5000,
          message: "an error occurred while edit the project .",
        });
      }
    },
    async addUrls(urls) {
      const url = `${process.env.VUE_APP_API_URL_PREFIX}/pageviews/user-projects/${this.projectId}`;
      let data = {
        appUrls: [
          {
            op: "add",
            url: urls,
          },
        ],
      };
      try {
        axios.defaults.validateStatus = (status) => {
          // return status >= 200 && status < 401; // to catch 400 error
          return status === 400 || (status >= 200 && status < 500);
        };
        const res = await axios.put(url, data);
        if ((res.data && res.data.status == "success") || res.data.status == 200) {
          return true;
        } else {
          this.$notify({
            type: "danger",
            duration: 5000,
            message: "the addition of the new url has failed.",
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async deleteUrls(urls) {
      const url = `${process.env.VUE_APP_API_URL_PREFIX}/pageviews/user-projects/${this.projectId}`;
      console.log(urls);
      let data = {
        appUrls: [
          {
            op: "remove",
            url: urls,
          },
        ],
      };
      try {
        axios.defaults.validateStatus = (status) => {
          // return status >= 200 && status < 401; // to catch 400 error
          return status === 400 || (status >= 200 && status < 500);
        };
        const res = await axios.put(url, data);
        if ((res.data && res.data.status == "success") || res.data.status == 200) {
          return true;
        } else {
          this.$notify({
            type: "danger",
            duration: 5000,
            message: "deleting url failed .",
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  computed: {
    filteredResultProjectSet() {
      const seq = this.resultProject;
      return seq && Array.isArray(seq)
        ? seq.filter((item) => {
            if (this.queryProject.length > 0) {
              if (item.name.toLowerCase().includes(this.queryProject.toLowerCase())) {
                return item.name.toLowerCase().includes(this.queryProject.toLowerCase());
              }
            } else {
              return item;
            }
          })
        : [];
    },
    filteredListSession() {
      return this.filteredResultsSession.slice(this.from, this.to);
    },
    canAddPrevious() {
      if (this.pagination.currentPage == 1) return true;
      return false;
    },
  },
  watch: {
    queryProject: {
      handler: "initializeProjectState",
      immediate: true,
    },
    paginationProject: {
      handler: "initializeProjectState",
      immediate: false,
      deep: true,
    },
  },
};
</script>

<style lang="scss">
@import "../../../assets/sass/custom/custom-variables.scss";

.fl--center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.add-test-project-modal {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  .modal-body {
    max-height: 60vh;
    overflow: auto;
  }
}

.run-name {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}

.model-label {
  display: block;
  font-size: 13px !important;
  font-weight: 600;
  margin-top: 0.5em;
}

.run-name input,
.run-name textarea,
.modal-checks-container textarea {
  width: 100%;
  border-radius: 5px;
  padding: 7px;
  outline: none;
  border: 1px solid #8a8d90;
  box-sizing: border-box;
  font-size: 13px;
}

.run-name input:focus,
.run-name textarea:focus,
.modal-checks-container textarea:focus {
  border-bottom: 1px solid rgb(68, 67, 67);
  -webkit-box-shadow: 0px -5px 2px -5px #222 inset;
  -moz-box-shadow: 0px -5px 2px -5px#222 inset;
  box-shadow: 0px -5px 2px -5px #222 inset;
}

.run-name input::-moz-placeholder,
.run-name textarea::-moz-placeholder {
  color: #8a8d90;
  font-size: 13px !important;
}

.run-name input::placeholder,
.run-name textarea::placeholder {
  color: #8a8d90;
  font-size: 13px !important;
}

.fbb {
  width: 100%;
  text-align: left;
  padding-bottom: 0.4em;
  border-bottom: 2px solid lightgray;
}

.three-input {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.tic {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.tic:nth-child(2) {
  padding: 0 5px;
}

.three-input input {
  width: 100%;
  border-radius: 5px;
  padding: 7px;
  outline: none;
  border: 1px solid #8a8d90;
  box-sizing: border-box;
  font-size: 13px;
}

.modal-checks-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}

.modal-checks {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: 13px;
  margin-top: 10px;
}

.modal-checks label {
  margin: 0 !important;
  padding-left: 7px;
  line-height: 1;
}

.modal-checks input {
  outline: none;
}

.mod-chk-last-input {
  width: 100px;
  margin: 0 10px;
  border-radius: 5px;
  outline: none;
  border: 1px solid #8a8d90;
  box-sizing: border-box;
  padding: 7px;
}

.mod-chk-last-input:focus {
  border-bottom: 1px solid rgb(68, 67, 67);
  -webkit-box-shadow: 0px -5px 2px -5px #222 inset;
  -moz-box-shadow: 0px -5px 2px -5px#222 inset;
  box-shadow: 0px -5px 2px -5px #222 inset;
}

[v-cloak] {
  display: none;
}

.upload-craglist label {
  width: 100%;
  text-align: center;
  font-size: 14px;
  color: #000000;
  font-weight: 600;
}

.craglist-files {
  width: 100%;
}

.craglist-files ul {
  border-top: 1px solid gray;
  padding: 0;
  margin: 0;
  width: 100%;
}

.craglist-files ul li {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid gray;
}

.rmv-btn {
  font-size: 20px;
  cursor: pointer;
}

.file-lists-container {
  width: 100%;
}

.file-head-clearall {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  color: #000000;
}

.file-head-clearall .clearall {
  cursor: pointer;
}

.upload-file-div-wrapper {
  height: 100px;
  width: 100%;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  background-color: #ddf;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.fileInput {
  cursor: pointer;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 99;
  font-size: 50px;
  opacity: 0;
  -moz-opacity: 0;
  filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
}

.el-table .el-table__header-wrapper thead tr th:nth-child(5) .cell {
  justify-content: center;
}

.down-btn-container a {
  color: #000000;
}

.down-btn-container a:hover {
  opacity: 0.6;
}

.down-btn-container a .fa-file-pdf,
.down-btn-container .fa-file-csv {
  font-weight: 600;
}

.progress-xpath-count {
  font-weight: bold;
  color: #4ac3db;
}

.onlyForRunningTestRow {
  background-color: #c4f1de !important;
}

.s-a-tabs {
  width: 100%;
  margin-top: 1em;
}

.test-run-name {
  //color: rgb(94, 114, 228);
  color: $color;
  font-weight: bold;
}

.test-run-name-for-suite {
  color: #2dce89;
  font-weight: bold;
}

.passed-status {
  font-weight: bold;
  font-size: 12px;
  color: #8a8d90;
  text-transform: capitalize;
}

.failed-status {
  background-color: #d93b3b;
  color: #fff;
  padding: 1px 10px;
  border-radius: 10px;
  font-weight: bold;
  font-size: 12px;
}

.run-tags-cont {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.run-tags {
  background-color: rgb(245, 245, 245);
  border: 1px solid rgb(222, 222, 222);
  border-radius: 3px;
  padding: 3px;
  font-size: 0.65rem;
  margin: 2.5px 2.5px 0 0;
  display: inline-block;
}

.test-time-cont {
  display: inline-block;
  max-width: 100% !important;
  font-size: 0.6rem;
  padding: 0.15rem 0.35rem;
  text-align: center;
  border: solid 0.1px rgba(#25a18e, 0.75);
  background: rgba(#25a18e, 0.15);
  margin-top: 5px;
  border-radius: 0.25rem;
  color: #25a18e;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.looping-rhombuses-spinner,
.looping-rhombuses-spinner * {
  box-sizing: border-box;
}

.looping-rhombuses-spinner {
  width: 50%;
  height: 15px;
  position: relative;
  margin-left: 10px !important;
}

.script {
  background: #223e33;
  border-radius: 0.25rem;
}

.pageview-modal .modal-content,
.pageview-modal .modal-dialog {
  width: auto;
  max-width: 80vw;
  max-height: 80vh;
}

.pageview-modal .modal-body {
  overflow: auto;
}

.add-test-project-modal .modal-dialog,
.re-run-modal-container .modal-dialog {
  width: auto;
  max-width: 900px;
  height: 10vh;
}

.show-test-project-modal .modal-dialog,
.re-run-modal-container .modal-dialog {
  width: auto;
  max-width: 900px;
  height: 10vh;
}

.looping-rhombuses-spinner .rhombus {
  height: 15px;
  width: 15px;
  background-color: #4ac3db;
  left: calc(15px * 1);
  position: absolute;
  margin: 0 auto;
  border-radius: 2px;
  transform: translateY(0) rotate(45deg) scale(0);
  animation: looping-rhombuses-spinner-animation 2500ms linear infinite;
}

.b {
  border: 1px solid red !important;
}

.for-row-selection .el-input {
  height: 100%;
  padding: 3px;
}

.test-descp {
  height: 10px;
  color: #9ea1a5;
  width: 50px;
}

.edit-test-select {
  width: 100%;
}

.edit-test-select .el-input {
  width: 100%;
}

.edit-test-modal-container .edit-test-select .el-select .el-input .el-input__inner {
  text-transform: capitalize !important;
}

.el-select-dropdown__item {
  text-transform: capitalize !important;
}

.add-test-project-modal button:disabled {
  cursor: not-allowed;
}

.for-pagination-circle-cursor .page-link {
  cursor: pointer;
}

.btn-gray {
  background-color: #172b4d !important;
}

.processing-screenshot-img {
  width: 150px;
  height: 100px;
  overflow: hidden;
}

.processing-screenshot-img img {
  /* border-radius: 10px; */
  /* border: 2px solid #fbb140; */
  width: 150px;
  height: 100px;
  object-fit: contain !important;
}

.add-test-footer-btn {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.file-hover {
  background-color: #adfad9 !important;
}

.pakhpalle {
  background-color: red !important;
}

.looping-rhombuses-spinner .rhombus:nth-child(1) {
  animation-delay: calc(2500ms * 1 / -1.5);
}

.looping-rhombuses-spinner .rhombus:nth-child(2) {
  animation-delay: calc(2500ms * 2 / -1.5);
}

.looping-rhombuses-spinner .rhombus:nth-child(3) {
  animation-delay: calc(2500ms * 3 / -1.5);
}

@keyframes looping-rhombuses-spinner-animation {
  0% {
    transform: translateX(0) rotate(45deg) scale(0);
  }

  50% {
    transform: translateX(233%) rotate(45deg) scale(1);
  }

  100% {
    transform: translateX(466%) rotate(45deg) scale(0);
  }
}

.narrow-select .el-input {
  height: 52px;
  width: 80px;
  padding: 3px;
}

.options-accordion .el-collapse-item__header {
  font-size: 16px;
}

.small--select {
  max-width: 80px !important;
}

.c--breadcrumb {
  display: flex;
  align-items: center;
  font-size: 0.85rem;
  font-weight: 300;
  color: rgba($pColor, 0.6);
  transform: translateY(-40px);
  width: calc(100% - 100px);

  .cb--divider {
    color: rgba($color, 0.6);
    font-size: 0.65rem;
  }

  &.to_move {
    transform: translate3d(30px, -36.5px, 0);
  }
}

.top--page,
.mid--page {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .tp--l,
  .mp--l,
  .mp--r {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .tp--title {
      font-size: 1.9rem;
      color: rgba($pColor, 0.85);
    }
  }
}

.mid--page {
  border-bottom: solid 0.1px rgba($pColor, 0.25);

  .el-input__inner {
    font-size: 0.75rem;
    padding: 0.35rem;
    background: #f7f9fc;

    &:focus {
      outline: none !important;
    }
  }
  .el-input__suffix {
    .el-input__suffix-inner {
      display: flex;
      justify-content: center;
      align-items: center;
      .el-input__icon {
        font-size: 0.8rem;
        transform: translateY(-3px);
      }
    }
  }
  .mp--l {
    span {
      color: rgba($pColor, 0.65);
      cursor: pointer;
      //font-size: 1.25rem;
      &.active {
        color: #25a18e;
      }
    }

    .small_btn {
      background: rgba($color, 0.1);
      border: solid 0.1px $color;

      i,
      span {
        font-size: 0.75rem !important;
        font-weight: 600;
        color: $color;
      }

      &:hover {
        background: rgba($color, 0.3);

        i,
        span {
          color: rgba($color, 0.75);
        }
      }

      &.active {
        i,
        span {
          color: #fff !important;
        }
      }
    }

    .dashed--btn {
      border-style: dashed;
    }
  }
  .mp--r {
    .pag--details {
      color: rgba($pColor, 0.65);
      font-size: 0.85rem;
      white-space: nowrap;
    }
  }
}

.bpsd--table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 15px;
  table-layout: fixed;

  .tbl--head {
    width: 100%;
    box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
    background: $color;
    color: #ffffff;
    overflow: hidden;

    th {
      padding: 0.8rem;
      font-size: 0.75rem;
      font-weight: 800;
      text-transform: uppercase;

      .th--sort {
        font-size: 0.6rem;
      }

      &:first-child {
        border-radius: 0.35rem 0 0 0.35rem;
      }

      &:last-child {
        border-radius: 0 0.35rem 0.35rem 0;
      }
    }
  }
}

.ctb--body {
  display: flex;
  flex-wrap: wrap;

  .ctbb--row {
    width: 31%;
    margin: 1%;
    cursor: pointer;
    overflow: hidden;
    background: #ffffff;
    box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
    border-radius: 0.25rem;
    position: relative;

    &.ctbb--processing {
      .ctbb--inner {
        filter: blur(2px);
        display: none;
      }
    }

    .ctbb--inner {
      height: 100%;
      display: flex;
      flex-direction: column;

      .elt--banner {
        position: relative;
        .elt--banner_img {
          display: inline-block;
          width: 100%;
        }

        .elt--banner_overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba($color, 0.3);
        }

        .elt--banner_overlay_status {
          position: absolute;
          top: 10px;
          right: 10px;
          max-width: 100px;
          text-align: center;
          &.no--close {
            padding: 0.25rem 0.35rem;
            .el-alert__closebtn {
              display: none;
            }
          }
        }

        .elt--banner_overlay_duration,
        .elt--banner_overlay_created {
          position: absolute;
          bottom: -10px;
          font-size: 0.7rem;
          font-weight: 700;
          color: #fff;
          background: rgba($color, 0.85);
          padding: 0.25rem;
          border-radius: 0.3rem;
        }

        .elt--banner_overlay_created {
          left: 10px;
        }
        .elt--banner_overlay_duration {
          right: 10px;
        }

        &.no--img {
          display: flex;
          align-items: center;
          height: 110%;
        }
      }

      .elt--content {
        padding: 0.6rem;
        margin-top: 10px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .test-desc {
          font-size: 0.8rem;
          color: rgba($pColor, 0.75);
          font-style: italic;
        }

        .elt--value {
          font-weight: 700;
          font-size: 0.85rem;
          margin-right: 12px;
        }

        .el-divider {
          margin: 8px 0;
        }

        .el--footer {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          border: solid red;
        }
      }
    }

    .ctbb--over {
      cursor: pointer;
      overflow: hidden;
      box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
      border-radius: 0.25rem;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      position: absolute;
      top: 0;
      left: 0;
      text-align: center;
      padding: 0.5rem;

      img {
        display: inline-block;
        width: 100%;
        box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
        border-radius: 0.25rem;
        margin: auto;
        border: solid 3px rgba(#108050, 0.7);
      }

      .s--icon {
        font-size: 3rem;
        font-weight: 700;
        color: #108050;

        i {
          font-weight: 600;
        }
      }

      .s--name {
        padding: 0.4rem 0.75rem;
        font-size: 0.85rem;
        font-weight: 600;
        color: #fff;
        background: rgba(#108050, 0.8);
        border: solid 0.1px #108050;
        border-radius: 0.2rem;
      }

      .s--txt {
        color: #108050;
        font-size: 0.8rem;
        font-weight: 800;
      }
    }
  }
}

.tbl--body {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .tblb--row {
    width: 100%;
    background: #ffffff;
    box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
    border-radius: 0.25rem;
    cursor: pointer;
    position: relative;
    overflow: hidden;

    &:hover {
      box-shadow: 0 0 30px -5px rgba(#111, 0.25);
      transition: all 0.25s ease-in;
    }

    &.tblb--processing {
      min-height: 350px;
      .tblb--inner {
        filter: blur(1px);
      }
    }

    .tblb--inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .elt-row {
        padding: 1.4rem 0.8rem;
        font-size: 0.81rem;
        display: inline-block;

        &.flex--elt {
          display: flex;
        }

        .el-alert {
          display: inline;
          text-align: center;
          &.no--close {
            padding: 0.25rem 0.35rem;
            .el-alert__closebtn {
              display: none;
            }
          }
        }

        .elt--value {
          font-weight: 700;
        }

        .elt--txt {
          font-size: 0.7rem;
        }

        .elt--banner_img {
          border-radius: 0.35rem;
          overflow: hidden;
          width: 100%;

          img {
            display: inline-block;
            width: 100%;
          }
        }
      }
    }

    .tblb--over {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #00ff6214;
      display: flex;
      justify-content: space-between;
      align-items: center;
      z-index: 999;

      .tblbo--img {
        height: 100%;
        width: 100%;
        border-radius: 0.25rem;
        overflow: hidden;
        img {
          height: 100%;
        }
      }
      .s--icon {
        font-size: 2.5rem;
        font-weight: 700;
        color: #108050;

        i {
          font-weight: 900;
        }
      }

      .s--name {
        padding: 0.4rem 0.75rem;
        font-size: 0.85rem;
        font-weight: 600;
        color: #fff;
        background: rgba(#108050, 0.8);
        border: solid 0.1px #108050;
        border-radius: 0.2rem;
      }

      .tblo--step {
        .s--txt {
          color: #108050;
          font-size: 0.8rem;
          font-weight: 800;
        }
      }
    }
  }
}
.modal-body {
  height: auto;
  padding-top: 40px !important;
}
/* width */
::-webkit-scrollbar {
  width: 5px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $color;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.mp--filter_container {
  animation: slideDown 0.3s;
  width: 100%;
  background: #ffffff;
  -webkit-box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
  border-radius: 0.25rem;
  position: relative;
  overflow: hidden;
}

@keyframes slideDown {
  from {
    height: 0;
    opacity: 0;
  }
  to {
    height: auto;
    opacity: 1;
  }
}
.sessionEvent-detail-modal {
  // width: 85vw !important;
  // margin: 1.75rem auto;
  @media (min-width: 576px) {
    .modal-dialog {
      max-width: 100%;
      margin: 1.75rem auto;
    }
  }
  .modal-dialog {
    width: 85% !important;
    height: 10vh;
  }
}
.showCoveringtests-modal {
  // width: 85vw !important;
  // margin: 1.75rem auto;
  .modal-dialog {
    width: 80vw !important;
    height: 10vh;
  }
}
.swal2-popup #swal2-title {
  display: block !important;
  font-size: 1.5em !important;
}

.inp--project {
  width: 300px;
  height: 20px !important;
}
.ultra--ipt_content {
  display: flex;
  align-items: center;
  white-space: nowrap;
  max-width: 70%;
  .u--tag {
    display: inline-block;
    font-size: 0.75rem;
    padding: 0.15rem 0.35rem;
    border: solid 0.1px rgba(#25a18e, 0.75);
    background: rgba(#25a18e, 0.15);
    border-radius: 0.25rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .u--tag--i {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.ultra--ipt_content,
.ultra--ipt_ipt {
  display: inline-block;
  height: 50px;
  outline: none;
  border: none;
  background: transparent;
}
.ultra--ipt_ipt {
  width: 100%;
}
.ultra--ipt {
  border: solid 0.1px #4b4b71;
  border-radius: 0.3rem;
  position: relative;
  height: 40px;
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 100%;
  .ultra--ipt_content,
  .ultra--ipt_ipt {
    display: inline-block;
    height: 50px;
    outline: none;
    border: none;
    background: transparent;
  }
  .ultra--ipt_content {
    display: flex;
    align-items: center;
    white-space: nowrap;
    max-width: 70%;
    .u--tag {
      display: inline-block;
      font-size: 0.75rem;
      padding: 0.15rem 0.35rem;
      border: solid 0.1px rgba(#25a18e, 0.75);
      background: rgba(#25a18e, 0.15);
      border-radius: 0.25rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .u--tag--i {
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .ultra--ipt_content--xphat {
    display: flex;
    align-items: center;
    white-space: nowrap;
    max-width: 85%;
    .u--tag {
      display: inline-block;
      font-size: 0.75rem;
      padding: 0.15rem 0.35rem;
      border: solid 0.1px rgba(#25a18e, 0.75);
      background: rgba(#25a18e, 0.15);
      border-radius: 0.25rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .u--tag--i {
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .ultra--ipt_ipt {
    width: 100%;
  }
  .content--ultra {
    max-width: 75%;
  }
  .btn--clean {
    max-width: 22%;
  }
}
.snippet--inp {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  width: 100%;
}

.list--downloadTestFile {
  border-bottom: solid 1px #f8f9fe;
  cursor: pointer;
}
.list--downloadTestFile:hover {
  color: #5f72e4;
  border-bottom: solid 1px #d5d5d5;
}
.ticks {
  z-index: 1;
  position: relative;
  width: 100%;
  bottom: 100%;
}
.ticks .tick-hover-box {
  cursor: pointer;
  position: absolute;
  height: 100%;
  top: -15px;
  width: 5px;
  // right: -3px;
}
.ticks .tick-hover-box .tick-info {
  border-radius: var(--radius);
  padding: 0.25rem 0.5rem;
  position: absolute;
  top: -36px;
  background-color: var(--bg-charcoal);
  display: none;
  color: var(--light);
  white-space: nowrap;
  left: 50%;
  transform: translate(-50%);
}
.ticks .tick-hover-box .tick-marker {
  position: absolute;
  width: 1px;
  height: 21px;
  background-color: #7e7e7e;
  right: 2px;
}
.ticks .tick-hover-box .tick-thumb {
  position: absolute;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  right: -2px;
  top: -4px;
  border: 0.5px solid #7e7e7e;
  background-color: #ffff;
  transform-origin: center;
  transition: transform 0.2s;
}
.container--video {
  display: flex;
  .video {
    width: 1080px;
  }
  .container--event--liste {
    margin: 0 0 0 30px;
    padding: 8px;
    width: 100%;
    border: solid 1px #c6c6c6;
    border-radius: 5px;
    max-height: 656px;
    overflow: hidden;
  }
  .event--liste {
    max-height: 580px;
    overflow-x: auto;
    overflow-y: none;
  }
  event--liste::-webkit-scrollbar {
    display: none;
  }
  .content {
    cursor: pointer;
    overflow: hidden;
    display: flex;
    align-items: center;
    margin-bottom: 3px;
    width: 100%;
    border-radius: 5px;
    padding: 10px;
    .time--tag {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      flex-wrap: nowrap;
      margin-right: 1rem !important;
      width: 96%;
    }
    .right--content {
      width: 80%;
      flex-direction: column;
    }
    .icon--event {
      width: 20%;
      display: flex;
      background-color: #e5e5e5;
      width: 40px;
      height: 40px;
      border-radius: 5px;
      text-align: center;
      align-items: center;
      justify-content: center;
    }
  }
  .content:hover {
    background-color: #e8eeff;
    .icon--event {
      background-color: $color;
      i {
        color: #ffff;
      }
    }
  }
  .active--event {
    background-color: #e8eeff;
    .icon--event {
      background-color: $color;
      i {
        color: #ffff;
      }
    }
  }
}
.small_btn {
  background: rgba($color, 0.1);
  border: solid 0.1px $color;

  i,
  span {
    font-size: 0.75rem !important;
    font-weight: 600;
    color: $color;
  }

  &:hover {
    background: rgba($color, 0.3);

    i,
    span {
      color: rgba($color, 0.75);
    }
  }
}

.dashed--btn {
  border-style: dashed;
}
</style>
