<template>
  <div class="container-fluid list-item-page mt--4">
    <div class="c--breadcrumb" :class="{ to_move: $sidebar.isMinimized }">
      <router-link to="/dashboard"><i class="el-icon-house"></i></router-link>

      <span><i class="el-icon-d-arrow-right mx-1 cb--divider"></i></span>
      <span> Live events</span>
    </div>
    <div class="mid--page pb-4 no-border">
      <div class="mp--l">
        <div class="btn-group align-items-center">
          <el-button
            @click="showComponent('event')"
            icon="el-icon-s-platform"
            class="tab--btn small_btn"
            :class="{ 'bg-primary active': showEvent }"
          >
            Pageviews event
          </el-button>
          <el-button
            @click="showComponent('coveringtests')"
            icon="fa fa-sitemap"
            class="tab--btn small_btn"
            :class="{ 'bg-primary active': showCoveringtests }"
          >
            Key flows
          </el-button>
        </div>
      </div>
      <div class="mp--r" >
        <div class="btn-group align-items-center mp--r">
          <span for="project_select" class="pag--details mr-3">Choose project</span>
          <el-select
            id="project_select"
            v-model="selectProjectId"
            @input="changeProject()"
            placeholder="Select you project"
          >
            <el-option
              v-for="item in resultProject"
              :key="item.projectId"
              :label="item.name"
              :value="item.projectId"
            >
              <span style="float: left">{{ item.name }} </span>
              <span style="float: right; color: #8492a6; font-size: 13px" class="pl-2"
                >id : {{ item.projectId }}</span
              >
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
    <template v-if="showEvent">
      <div class="mid--page_container">
        <div class="mid--page pb-3 my-3" style="border: none;">
          <div class="mp--l">
            <el-input :placeholder="$t('list_item_page.search')" v-model="querySession">
              <i slot="suffix" class="el-input__icon el-icon-search"></i>
            </el-input>
          </div>
          <div class="mp--r">
            <span class="pag--details mr-3"
              >{{ totalSession ? fromSession + 1 : 1 }} {{ $t("list_item_page.to") }}
              {{ toSesion > totalSession ? totalSession : toSesion }}
              {{ $t("list_item_page.of") }} {{ totalSession }}
              {{ $t("list_item_page.entries") }}
            </span>
            <el-select
              v-model="paginationSession.perPage"
              @change="savePaginationSession"
              placeholder="Per page"
              class="small--select"
            >
              <el-option
                v-for="(item, id) in paginationSession.perPageOptions"
                :key="id"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div class="eff--page">
        <data-list
          :tHeader="Header2"
          :loading="loadingSession"
          :items="toShowSession"
          :customSizes="true"
          ref="dataList"
        >
          <template #content>
            <div
              v-for="(elt, id) in toShowSession"
              :key="id"
              class="tblb--row mb-2"
              @click="processRun(elt)"
            >
              <div class="tblb--inner">
                <div class="elt-row" style="width: 6%">
                  <span class="test-run-name">
                    {{ forStepNumber(id) }}
                  </span>
                </div>
                <div class="elt-row" style="width: 23%">
                  <span>
                    {{
                      elt.person.properties.$geoip_continent_name
                        ? elt.person.properties.$geoip_continent_name
                        : "N/A"
                    }}
                    /{{
                      elt.person.properties.$geoip_country_name
                        ? elt.person.properties.$geoip_country_name
                        : "N/A"
                    }}
                  </span>
                </div>
                <div class="elt-row" style="width: 27%">
                  <el-tooltip
                    class="item"
                    effect="light"
                    :content="elt.person.properties.$initial_current_url"
                    placement="top"
                  >
                    <span
                      class="run-tags"
                      style="
                        max-width: 100%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                      "
                    >
                      {{ elt.person.properties.$initial_current_url }}
                    </span>
                  </el-tooltip>
                </div>
                <div class="elt-row" style="width: 12%">
                  <span class="elt--txt">
                    {{ elt.person.properties.$initial_os }} /
                    {{ elt.person.properties.$initial_browser }}
                  </span>
                </div>
                <div class="elt-row" style="width: 16%">
                  <span class="elt--txt">
                    {{ formatDate(elt.start_time) }}
                  </span>
                </div>
                <div class="elt-row" style="width: 12%">
                  <span class="test-time-cont">
                    <i class="far fa-clock mr-1"></i>
                    {{ secondsToRealTime(elt.recording_duration) }}
                  </span>
                </div>
              </div>
            </div>
          </template>
        </data-list>
        <el-button-group>
          <el-button
            type="primary"
            icon="el-icon-arrow-left"
            :disabled="canAddPreviousSession"
            @click="handleCurrentChangeSession('Previous')"
          >
            Previous
          </el-button>
          <el-button type="primary" @click="handleCurrentChangeSession('next')">
            Next
            <i class="el-icon-arrow-right el-icon-right"></i>
          </el-button>
        </el-button-group>
      </div>
    </template>
    <template v-if="showCoveringtests">
      <div class="mid--page_container">
        <div class="mid--page my-3 pb-3" style="border: none;">
          <div class="mp--l">
            <el-input :placeholder="$t('list_item_page.search')" v-model="queryCoveringtests">
              <i slot="suffix" class="el-input__icon el-icon-search"></i>
            </el-input>
            <el-button
              @click="toggleShowFilters = !toggleShowFilters"
              icon="el-icon-guide"
              class="ml-3 small_btn dashed--btn"
              plain
              size="small"
            >
              {{ $t("list_item_page.filter") }}
            </el-button>
          </div>
        </div>
      </div>
      <div class="eff--page">
        <div class="mod--content lite" v-if="toggleShowFilters">
          <br />
          <el-form class="small-spaces" @submit.native.prevent="getCoveringTests()">
            <el-row :gutter="24">
              <el-col :span="9">
                <el-form-item label="From">
                  <el-date-picker
                    style="width: 100%"
                    type="date"
                    placeholder="from"
                    v-model="filterDate[0]"
                    :picker-options="pickerBeginDateBefore"
                    :disabledDate="pickerBeginDateBefore"
                  ></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="9">
                <el-form-item label="to">
                  <el-date-picker
                    style="width: 100%"
                    type="date"
                    placeholder="to"
                    v-model="filterDate[1]"
                    :picker-options="pickerBeginDateBefore"
                    :disabledDate="pickerBeginDateBefore"
                  ></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="Select Cov %">
                  <el-select
                    v-model="SelectCov"
                    placeholder="Select Cov"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="item in SelectCovList"
                      :key="item"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <base-button type="primary" native-type="submit">
              Process filter
            </base-button>
            <base-button type="info" @click="toggleShowFilters = !toggleShowFilters">
              Close
            </base-button>
          </el-form>
        </div>
        <template>
          <data-list
            :tHeader="Header3"
            :loading="loadingShowCoveringtests"
            :items="toShowCoveringtests"
            :customSizes="true"
            ref="dataList"
          >
            <template #content>
              <div
                v-for="(elt, id) in toShowCoveringtests"
                :key="id"
                class="tblb--row mb-2"
                @click="processShowVideo(elt)"
              >
                <div class="tblb--inner">
                  <div class="elt-row" style="width: 30%">
                    <el-tooltip
                      class="item"
                      effect="light"
                      :content="elt.url"
                      placement="top"
                    >
                      <span
                        class="run-tags"
                        style="
                          max-width: 100%;
                          overflow: hidden;
                          text-overflow: ellipsis;
                          white-space: nowrap;
                        "
                      >
                        {{ elt.url }}
                      </span>
                    </el-tooltip>
                  </div>
                  <div class="elt-row" style="width: 15%">
                    {{ elt.tests[0].statistics["relative covering percent"] }}
                  </div>
                  <div class="elt-row" style="width: 15%">
                    {{ elt.tests[0].statistics["covered elements"] }}
                  </div>
                  <div class="elt-row" style="width: 15%">
                    <el-tooltip
                      class="item"
                      effect="light"
                      content="Run test"
                      placement="top"
                    >
                      <el-button
                        type="primary"
                        icon="el-icon-refresh"
                        @click.stop="actionCoveringtests('runTest', elt)"
                        circle
                        plain
                        size="small"
                        style="margin-left: 10px"
                      >
                      </el-button>
                    </el-tooltip>

                    <el-tooltip
                      class="item"
                      effect="light"
                      content="Download test file"
                      placement="top"
                    >
                      <el-button
                        type="success"
                        icon="el-icon-download"
                        @click.stop="actionCoveringtests('downloadTestFile', elt)"
                        circle
                        plain
                        size="small"
                      >
                      </el-button>
                    </el-tooltip>
                  </div>
                </div>
              </div>
            </template>
          </data-list>
        </template>
      </div>
    </template>


    <!-- Modal for video session start-->
    <div class="pageview-modal">
      <modal :show.sync="showVideo">
        <template slot="header">
          <h5 class="modal-title" id="sessionEventRunModal">
            {{ showCoveringtests === false ? "User Session" : "video" }}
          </h5>
        </template>
        <div class="">
          <div class="mid--page pb-4 no-border">
            <div></div>
            <div class="mp--r" v-if="showCoveringtests === false">
              <button @click="addTestModal = true" class="small_btn rounded mr-2">
                <el-tooltip content="run a test for this session" placement="top">
                  <i class="fa fa-cogs" aria-hidden="true"></i>
                </el-tooltip>
              </button>
              <button
                @click="downloadTestFile(null)"
                class="small_btn rounded mr-2"
                v-if="fileBackup.length < 2"
              >
                <el-tooltip content="download the test execution file" placement="top">
                  <i class="fa fa-download" aria-hidden="true"></i>
                </el-tooltip>
              </button>
              <el-dropdown v-else>
                <button class="small_btn rounded mr-2">
                  <el-tooltip
                    content="select the file you want to download"
                    placement="top"
                  >
                    <i class="fa fa-download" aria-hidden="true"></i>
                  </el-tooltip>
                </button>
                <el-dropdown-menu
                  slot="dropdown"
                  style="max-height: 50vh; overflow-x: auto"
                >
                  <div
                    v-for="(ls, id) in fileBackup"
                    :key="id"
                    class="px-3 py-1 list--downloadTestFile"
                    @click="downloadTestFile(id)"
                  >
                    {{ ls.name }}
                  </div>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
          <div class="tblb--row mb-2" v-if="loadingVideo">
            <div class="tblb--inner">
              <img
                src="/img/loading.gif"
                alt="loading..."
                style="height: 100px; width: 100px"
              />
            </div>
          </div>
          <template v-else-if="showVideo">
            <div class="d-flex pt-4">
              <div class="mr-4">
                <RrWebPlayer :events="events" :skipInactive="true" :autoplay="autoplay" />
              </div>
              <div class="" style="min-width: 200px">
                <div class="mid--page_container">
                  <div class="mid--page pb-4 mb-6ty3">
                    <el-input
                      :placeholder="$t('list_item_page.search')"
                      v-model="querySessionEvent"
                    >
                      <i slot="suffix" class="el-input__icon el-icon-search"></i>
                    </el-input>
                  </div>
                </div>
                <div class="event--liste">
                  <div
                    v-for="(ls, id) in tagsEvent"
                    :key="id"
                    class="content"
                    @click="startEvent(id)"
                  >
                    <div class="icon--event">
                      <i :class="getIcon(ls.event)"></i>
                    </div>
                    <div class="right--content">
                      <div class="time--tag ml-3 mr-3">
                        <h4
                          style="
                            max-width: 60;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                          "
                        >
                          {{ ls.event.replace("$", " ") }}
                        </h4>
                        <span
                          style="
                            max-width: 40;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                          "
                        >
                          {{ secondsToRealTime(ls.start_time) }}
                        </span>
                      </div>
                      <div
                        style="
                          max-width: 60;
                          overflow: hidden;
                          text-overflow: ellipsis;
                          white-space: nowrap;
                          font-size: 14px;
                        "
                        class="ml-3 action"
                      >
                        {{ ls.properties.$pathname }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
        <template slot="footer">
          <div class="add-test-footer-btn">
            <base-button type="secondary" @click="showVideo = false">
              {{ $t("list_item_page.close") }}
            </base-button>
          </div>
        </template>
      </modal>
    </div>
    <!-- Modal for video session end-->

    <!-- Modal add test start-->
    <div class="add-test-project-modal">
      <modal :show.sync="addTestModal">
        <template slot="header">
          <h5 class="modal-title" id="addTestModal">
            {{ $t("list_item_page.generate_test") }}
          </h5>
        </template>
        <div class="tblb--row mb-2" v-if="loadingModalAddRun">
          <div class="tblb--inner">
            <img
              src="/img/loading.gif"
              alt="loading..."
              style="height: 100px; width: 100px"
            />
          </div>
        </div>
        <div class="body--modale" v-else>
          <div class="modal-add-containt">
            <div class="add-test-project-modal">
              <div class="run-name">
                <label class="model-label" for="run_name">
                  {{ $t("list_item_page.run_name") }}
                </label>
                <input
                  type="text"
                  id="run_name"
                  name="run_name"
                  :disabled="showCoveringtests != true"
                  :placeholder="$t('list_item_page.placeholder_run_name')"
                  v-model="run_name"
                />
                <label class="model-label" for="run_description">{{
                  $t("list_item_page.description")
                }}</label>
                <textarea
                  id="run_description"
                  name="run_description"
                  rows="2"
                  :placeholder="$t('list_item_page.placeholder_run_description')"
                  v-model="run_description"
                ></textarea>
                <label class="model-label" for="run_name">
                  {{ $t("list_item_page.run_tags") }}
                </label>
                <input
                  type="text"
                  id="run_tags"
                  name="run_tags"
                  :placeholder="$t('list_item_page.placeholder_run_tags')"
                  v-model="run_tags"
                />
                <label class="model-label" for="Test_Script_Files">
                  Test Script Files
                </label>
                <el-select
                  id="Test_Script_Files"
                  v-model="filesListName"
                  laceholder="Select execution file"
                >
                  <el-option
                    v-for="item in fileBackup"
                    :key="item.name"
                    :label="item.name"
                    :value="item.name"
                  >
                    <span
                      style="float: left; width: 100%"
                      @click="changeFile((filesList = item))"
                      >{{ item.name }}</span
                    >
                  </el-option>
                </el-select>
              </div>

              <div class="s-a-tabs">
                <ul class="nav nav-tabs nav-justified">
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      @click.prevent="setActive('settings')"
                      :class="{ active: isActive('settings') }"
                      href="#home"
                      >{{ $t("list_item_page.settings") }}</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      @click.prevent="setActive('advanced')"
                      :class="{ active: isActive('advanced') }"
                      href="#profile"
                      >{{ $t("list_item_page.advanced") }}</a
                    >
                  </li>
                </ul>
                <div class="tab-content py-3" id="myTabContent">
                  <div
                    class="tab-pane fade"
                    :class="{ 'active show': isActive('settings') }"
                    id="settings"
                  >
                    <div class="mt-2">
                      <h4 class="fbb">{{ $t("list_item_page.test_script") }}</h4>
                      <div class="three-input">
                        <div class="tic">
                          <label class="model-label" for="browser_width">{{
                            $t("list_item_page.browser_width")
                          }}</label>
                          <input
                            type="text"
                            id="browser_width"
                            name="browser_width"
                            placeholder="1920"
                            v-model="browser_width"
                          />
                        </div>
                        <div class="tic">
                          <label class="model-label" for="browser_height">{{
                            $t("list_item_page.browser_height")
                          }}</label>
                          <input
                            type="text"
                            id="fname"
                            name="browser_height"
                            placeholder="1980"
                            v-model="browser_height"
                          />
                        </div>

                        <div class="tic">
                          <label class="model-label" for="max_wait_time">{{
                            $t("list_item_page.max_wait")
                          }}</label>
                          <input
                            type="text"
                            id="max_wait_time"
                            name="max_wait_time"
                            placeholder="5.0"
                            v-model="max_wait_time"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="tab-pane fade"
                    :class="{ 'active show': isActive('advanced') }"
                    id="advanced"
                  >
                    <div class="modal-checks-container">
                      <div class="modal-checks">
                        <input
                          type="checkbox"
                          id="ignore_disabled_elements"
                          name="ignore_disabled_elements"
                          v-model="ignore_disabled_elements"
                        />
                        <label for="ignore_disabled_elements">{{
                          $t("list_item_page.ignore_disabled")
                        }}</label>
                      </div>
                      <div class="modal-checks">
                        <input
                          type="checkbox"
                          id="ignore_invisible_elements"
                          name="ignore_invisible_elements"
                          v-model="ignore_invisible_elements"
                        />
                        <label for="ignore_invisible_elements">{{
                          $t("list_item_page.ignore_invisible")
                        }}</label>
                      </div>
                      <div class="modal-checks">
                        <input
                          type="checkbox"
                          id="collect_page_interaction_data"
                          name="collect_page_interaction_data"
                          v-model="collect_page_interaction_data"
                        />
                        <label for="collect_page_interaction_data">{{
                          $t("list_item_page.collect_interaction")
                        }}</label>
                      </div>
                      <div class="modal-checks">
                        <input
                          type="checkbox"
                          id="extra_element_data"
                          name="extra_element_data"
                          v-model="extra_element_data"
                        />
                        <label for="extra_element_data">{{
                          $t("list_item_page.collect_extra_element")
                        }}</label>
                      </div>
                      <div class="modal-checks">
                        <input
                          type="checkbox"
                          id="all_suggest"
                          name="all_suggest"
                          v-model="all_suggest"
                        />
                        <label for="all_suggest">{{
                          $t("list_item_page.generate_selectors")
                        }}</label>
                      </div>

                      <div class="modal-checks">
                        <input
                          type="checkbox"
                          id="downweight_strings"
                          name="downweight_strings"
                          v-model="downweight_strings"
                        />
                        <label for="downweight_strings">{{
                          $t("list_item_page.try_to_avoid")
                        }}</label>
                      </div>
                      <div class="modal-checks">
                        <input
                          type="checkbox"
                          id="verify_suggested_selectors"
                          name="verify_suggested_selectors"
                          v-model="verify_suggested_selectors"
                        />
                        <label for="verify_suggested_selectors">{{
                          $t("list_item_page.verify_suggested")
                        }}</label>
                      </div>
                      <div class="modal-checks">
                        <input
                          type="checkbox"
                          id="enable_integrations"
                          name="enable_integrations"
                          v-model="enable_integrations"
                        />
                        <label for="enable_integrations">{{
                          $t("list_item_page.trigger_integrations")
                        }}</label>
                      </div>
                      <br />

                      <label class="model-label" for="bad_attributes_list">{{
                        $t("list_item_page.avoid_attributes")
                      }}</label>
                      <textarea
                        id="bad_attributes_list"
                        name="bad_attributes_list"
                        rows="2"
                        :placeholder="$t('list_item_page.placeholder_avoid_attributes')"
                        v-model="bad_attributes_list"
                      ></textarea>

                      <label class="model-label mt-3" for="good_attributes_list">{{
                        $t("list_item_page.use_attributes")
                      }}</label>
                      <textarea
                        id="good_attributes_list"
                        name="good_attributes_list"
                        rows="2"
                        :placeholder="$t('list_item_page.placeholder_use_attributes')"
                        v-model="good_attributes_list"
                      ></textarea>
                      <div class="modal-checks">
                        <input
                          type="checkbox"
                          id="continue_test_suite_on_fail"
                          name="continue_test_suite_on_fail"
                          v-model="continue_test_suite_on_fail"
                        />
                        <label for="continue_test_suite_on_fail">{{
                          $t("list_item_page.continue_test_suite")
                        }}</label>
                      </div>

                      <div class="modal-checks">
                        <input
                          type="checkbox"
                          id="timeout_cancel_run"
                          name="timeout_cancel_run"
                          v-model="timeout_cancel_run"
                        />
                        <label for="timeout_cancel_run">{{
                          $t("list_item_page.timeout_and_cancel")
                        }}</label>
                        <input
                          class="mod-chk-last-input"
                          type="text"
                          id="timeout_cancel_run_value"
                          name="timeout_cancel_run_value"
                          v-model="timeout_cancel_run_value"
                          :placeholder="
                            $t('list_item_page.placeholder_timeout_and_cancel')
                          "
                        />
                        {{ $t("list_item_page.seconds") }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <template slot="footer">
          <div class="add-test-footer-btn">
            <base-button
              type="secondary"
              @click="
                addTestModal = false;
                cleanUpState();
              "
              >{{ $t("list_item_page.close") }}</base-button
            >

            <el-tooltip
              :disabled="!(filesList.length === 0 || run_name === '')"
              :content="`${run_name === '' ? 'Run name cannot be empty' : ''} ${
                events.length === 0 ? 'Please upload at least one file' : ''
              }`"
              placement="top"
            >
              <span>
                <base-button
                  :disabled="filesList.length === 0 || run_name === ''"
                  type="primary"
                  @click="addTest"
                  >{{ $t("list_item_page.start_run") }}</base-button
                >
              </span>
            </el-tooltip>
          </div>
        </template>
      </modal>
    </div>
    <!-- Modal add test end-->

    <!-- Modal add project start-->
    <div class="add-test-project-modal">
      <modal :show.sync="addTestProject">
        <template slot="header">
          <h5 class="modal-title">
            {{
              editProject == true
                ? " edit project " + projectName
                : " Add new pageview project"
            }}
          </h5>
        </template>
        <div class="body--modale" v-loading="loadingAddProject">
          <div class="modal-add-containt">
            <div class="add-test-project-modal">
              <div class="run-name">
                <label class="model-label" for="project_name"> PROJECT NAME </label>
                <input
                  type="text"
                  id="project_name"
                  name="project_name"
                  placeholder="Project name"
                  v-model="projectName"
                />
                <label class="model-label" for="app_urls"> APP URLs </label>
                <div class="ultra--ipt">
                  <div class="ultra--ipt_content pl-2" ref="data-rendered"></div>
                  <input
                    type="text"
                    class="ultra--ipt_ipt px-2"
                    @input="handleUltraIpt"
                    @change="handleUltraIpt"
                    @keyup.backspace="handleDeleteLastDataValue"
                    v-model="ultraIpt"
                  />
                </div>
                <label v-if="editProject == true" class="model-label" for="api_Key">
                  API KEY
                </label>
                <input
                  type="text"
                  id="api_Key"
                  disabled="true"
                  name="api_Key"
                  placeholder="api Key"
                  v-if="editProject == true"
                  v-model="projectDetail.apiKey"
                />
                <label v-if="editProject == true" class="model-label" for="server_Url">
                  SERVER URL
                </label>
                <input
                  type="text"
                  id="server_Url"
                  disabled="true"
                  name="server_Url"
                  placeholder="server Url"
                  v-if="editProject == true"
                  v-model="projectDetail.serverUrl"
                />
                <div v-if="editProject == true" class="snippet--inp">
                  <label class="model-label" for="js_Snppet"> JS SNIPPET </label>
                  <i
                    class="ni ni-single-copy-04"
                    @click="copy(projectDetail.jsSnppet, 'js snippet')"
                  ></i>
                </div>
                <div v-if="editProject == true" class="script p-4">
                  <template>
                    <p
                      class="text-white text-xs"
                      style="overflow: hidden; text-overflow: ellipsis"
                    >
                      {{ projectDetail.jsSnppet }}
                    </p>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
        <template slot="footer">
          <div class="add-test-footer-btn">
            <base-button
              type="secondary"
              @click="
                addTestProject = false;
                cleanUpProject();
              "
              >{{ $t("list_item_page.close") }}</base-button
            >
            <div v-if="editProject">
              <el-tooltip
                :disabled="!(projectName.length === 0)"
                :content="'project name cannot be empty'"
                placement="top"
              >
                <span>
                  <base-button
                    :disabled="projectName.length === 0 || loadingAddProject"
                    type="primary"
                    @click="addProject('edit')"
                  >
                    Edit
                  </base-button>
                </span>
              </el-tooltip>
            </div>
            <div v-else>
              <el-tooltip
                :disabled="!(projectName.length === 0)"
                :content="`${
                  projectName.length === 0 ? 'project name cannot be empty' : ''
                } `"
                placement="top"
              >
                <span>
                  <base-button
                    :disabled="projectName.length === 0 || loadingAddProject"
                    type="primary"
                    @click="addProject('add')"
                  >
                    add new project
                  </base-button>
                </span>
              </el-tooltip>
            </div>
          </div>
        </template>
      </modal>
    </div>
    <!-- Modal add project end-->

    <!-- Modal detail project start-->
    <div class="show-test-project-modal">
      <modal :show.sync="showDetailProject">
        <template slot="header">
          <h5 class="modal-title" id="showprojectDetailModal">
            {{ projectDetail.name }} project details
          </h5>
        </template>
        <div class="body--modale" v-loading="loadingDetailProject">
          <div class="modal-add-containt">
            <div class="add-test-project-modal">
              <div class="run-name">
                <label class="model-label" for="project_name"> Project name </label>
                <input
                  type="text"
                  id="project_name"
                  disabled="true"
                  name="project_name"
                  placeholder="Project name"
                  v-model="projectDetail.name"
                />
                <label class="model-label" for="app_urls"> App URLs </label>
                <div class="ultra--ipt">
                  <div class="ultra--ipt_content pl-2" ref="data-rendered-detail"></div>
                  <input
                    type="text"
                    disabled="true"
                    class="ultra--ipt_ipt px-2"
                    @input="handleUltraIpt"
                    @change="handleUltraIpt"
                    @keyup.backspace="handleDeleteLastDataValue"
                    v-model="ultraIpt"
                  />
                </div>
                <label class="model-label" for="api_Key"> API KEY </label>
                <input
                  type="text"
                  id="api_Key"
                  disabled="true"
                  name="api_Key"
                  placeholder="api Key"
                  v-model="projectDetail.apiKey"
                />
                <label class="model-label" for="server_Url"> SERVER URL </label>
                <input
                  type="text"
                  id="server_Url"
                  disabled="true"
                  name="server_Url"
                  placeholder="server Url"
                  v-model="projectDetail.serverUrl"
                />
                <div class="snippet--inp">
                  <label class="model-label" for="js_Snppet"> JS Snippet </label>
                  <i
                    class="ni ni-single-copy-04"
                    @click="copy(projectDetail.jsSnppet, 'js snippet')"
                  ></i>
                </div>
                <div class="script p-4">
                  <template>
                    <p
                      class="text-white text-xs"
                      style="overflow: hidden; text-overflow: ellipsis"
                    >
                      {{ projectDetail.jsSnppet }}
                    </p>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
        <template slot="footer">
          <div class="add-test-footer-btn">
            <base-button type="secondary" @click="showDetailProject = false">{{
              $t("list_item_page.close")
            }}</base-button>
          </div>
        </template>
      </modal>
    </div>
    <!-- Modal detail project end-->
  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
  Collapse,
  CollapseItem,
} from "element-ui";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import moment from "moment";
import "moment-timezone";
import DataList from "@/components/Custom/DataList.vue";
import axios from "axios";
import RrWebPlayer from "@preflight-hq/rrweb-player-vue";

export default {
  layout: "DashboardLayout",
  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    DataList,
    RrWebPlayer,
  },

  data() {
    return {
      pickerBeginDateBefore: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      Header2: [
        {
          label: "",
          key: "_id",
          width: "6%",
          sortable: true,
          sortDetails: { asc: true, desc: false, type: "string" },
        },
        {
          label: "Continent/Country".toUpperCase(),
          key: "",
          width: "23%",
          sortable: false,
        },
        {
          label: "url".toUpperCase(),
          key: "",
          width: "27%",
          sortable: false,
        },
        { label: "browser".toUpperCase(), key: "", width: "12%", sortable: false },
        { label: "start time".toUpperCase(), key: "", width: "16%", sortable: false },
        { label: "duration".toUpperCase(), key: "", width: "12%", sortable: false },
      ],
      Header3: [
        {
          label: "Url".toUpperCase(),
          key: "",
          width: "30%",
          sortable: false,
        },
        {
          label: "Freq %".toUpperCase(),
          key: "",
          width: "20%",
          sortable: false,
        },
        { label: "Elements".toUpperCase(), key: "", width: "20%", sortable: false },
        { label: "action".toUpperCase(), key: "", width: "15%", sortable: false },
      ],
      Header: [
        {
          label: "Project name".toUpperCase(),
          key: "name",
          width: "60%",
        },
        {
          label: "Api key".toUpperCase(),
          key: "",
          width: "30%",
          sortable: false,
        },
        { label: "".toUpperCase(), key: "", width: "15%", sortable: false },
      ],
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
      },
      paginationSession: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
      },
      paginationProject: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
      },
      paginationSessionProject: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
      },
      result: [],
      filteredResults: [],
      filteredResultsSession: [],
      to: 0,
      toSession: 0,
      from: 0,
      fromSession: 0,
      total: 0,
      totalSession: 0,
      loading: true,
      loadingSession: false,
      toShow: [],
      toShowSession: [],
      resultProject: [],
      filteredResultsProject: [],
      toProject: 0,
      fromProject: 0,
      totalProject: 0,
      loadingProject: true,
      toShowProject: [],
      query: "",
      querySession: "",
      querySessionEvent: "",
      queryCoveringtests: "",
      querySessionId: "",
      resultSession: {},
      showEvents: false,
      showSessions: true,
      events: [],
      tagsEvent: {},
      resultEvent: {},
      showVideo: false,
      id: "",
      loadingVideo: false,
      addTestModal: false,
      orEditTestRowReqId: "",
      forReRunTest: "",
      toggleEditTestModal: false,
      toggleReRunModal: false,
      activeItem: "settings",
      run_name: "",
      run_description: "",
      run_tags: "",
      browser_width: "1920",
      browser_height: "1080",
      max_wait_time: "5.0",
      ignore_disabled_elements: "1",
      ignore_invisible_elements: "1",
      collect_page_interaction_data: "1",
      extra_element_data: 0,
      downweight_strings: 0,
      all_suggest: 0,
      try_to_avoid: false,
      bad_attributes_list: "",
      good_attributes_list: "",
      continue_test_suite_on_fail: "true",
      timeout_cancel_run: "",
      timeout_cancel_run_value: null,
      handle_failure: "",
      verify_suggested_selectors: false,
      enable_integrations: true,
      filesList: [],
      filesListName: "",
      loadingModalAddRun: false,
      lastUrlTest: "",
      showProject: false,
      showEvent: true,
      addTestProject: false,
      projectName: "",
      appUrls: "",
      appUrlsLs: [],
      loadingAddProject: false,
      editProject: false,
      projectId: null,
      showDetailProject: false,
      projectDetail: {
        name: "",
        appUrls: "",
        apiKey: "",
        serverUrl: "",
        jsSnppet: "",
      },
      newProjectDetail: {
        name: "",
        appUrls: "",
        apiKey: "",
        serverUrl: "",
        jsSnppet: "",
      },
      loadingDetailProject: true,
      selectProjectId: null,
      ultraIpt: "",
      showCoveringtests: false,
      toShowCoveringtests: [],
      date_to: undefined,
      date_from: undefined,
      limitCoveringtests: 10,
      percent: 20,
      loadingShowCoveringtests: false,
      fileBackup: [],
      toggleShowFilters: false,
      limitCoveringtestsList: [5, 10, 15, 20, 30, 40, 50],
      filterDate: [new Date(new Date().getTime() - 60 * 60 * 24 * 14 * 1000), new Date()],
      activeSessionDuration: 0,
      autoplay: false,
      mommentVideo: "",
      SelectCovList: [
        { label: "20%", value: 20 },
        { label: "50%", value: 50 },
        { label: "70%", value: 70 },
        { label: "90%", value: 90 },
        { label: "100%", value: 100 },
      ],
      SelectCov: 20,
      resultCoveringtests: [],
    };
  },
  async created() {
    sessionStorage.getItem("testEventPageNbr")
      ? (this.pagination.perPage = parseInt(sessionStorage.getItem("testEventPageNbr")))
      : (this.pagination.perPage = 10);
    sessionStorage.getItem("sesssionPageNbr")
      ? (this.paginationSession.perPage = parseInt(
          sessionStorage.getItem("sessionPageNbr")
        ))
      : (this.paginationSession.perPage = 10);
    sessionStorage.getItem("projectPageNbr")
      ? (this.paginationProject.perPage = parseInt(
          sessionStorage.getItem("projectPageNbr")
        ))
      : (this.paginationProject.perPage = 10);
    this.total = this.result.length;
    this.totalProject = this.resultProject.length;
    await this.getSessions();
    await this.getProject();
    await this.getCoveringTests();
  },
  methods: {
    changeProject(){
      if(this.selectProjectId){
        this.$router.push({
          name: "List Sessions",
          params: {
            id: this.selectProjectId,
          },
        });
        location.reload();
      }
    },
    remove(i) {
      this.filesList.splice(i, 1);
    },
    getIcon(action) {
      if (action == "$pageview") {
        return "el-icon-view";
      } else if (action == "$autocapture") {
        return "el-icon-position";
      } else if (action == "$pageleave") {
        return "el-icon-news";
      } else {
        return "el-icon-help";
      }
    },
    startEvent(id) {
      const mommentVideo = document.getElementById(`tick_thumb_${id}`);
      mommentVideo.click();
    },
    restoreAllFiles() {
      this.filesList = this.fileBackup;
    },
    copy(text, type) {
      if (text.length > 0) {
        navigator.clipboard.writeText(text).then(() => {
          this.$notify({
            type: "success",
            message: type + " Copied Successfully.",
          });
        });
      }
    },
    changeFile(file) {
      this.filesList = [file];
    },
    handleUltraIpt() {
      if (this.ultraIpt.length > 0 && this.ultraIpt[0] === " ") {
        this.ultraIpt = "";
      } else if (this.ultraIpt.slice(-1) === " ") {
        const elt = document.createElement("span");
        elt.classList.add("ml-1");
        elt.classList.add("u--tag");
        elt.innerHTML = " " + this.ultraIpt.trim() + " ";
        if (this.editProject === true) {
          this.addUrls(this.ultraIpt);
        }
        this.ultraIpt = "";
        if (this.$refs["data-rendered"].innerHTML.length > 1) {
          const space = document.createElement("span");
          space.classList.add("ml-1");
          space.innerHTML = " ";
          this.$refs["data-rendered"].appendChild(space);
          this.appUrls = space;
        }
        this.$refs["data-rendered"].appendChild(elt);
        this.appUrls = elt;
      } else if (this.ultraIpt.length > 0 && this.ultraIpt[0] != " ") {
        this.appUrls = this.ultraIpt;
      } else if (this.ultraIpt.length === 0) {
        this.appUrls = "";
      }
    },
    handleDeleteLastDataValue() {
      let getStr = this.$refs["data-rendered"].innerHTML
        .replace(/<span class="ml-1 u--tag">/g, "")
        .replace(/<span class="ml-1">/g, "")
        .replace(/<\/span>/g, ",")
        .split(",")
        .filter((s) => s.trim().length > 0)
        .map((s) => s.trim());
      if (this.ultraIpt.length <= 0) {
        if (this.editProject === true) {
          this.deleteUrls(getStr.pop());
        } else {
          let _rem = getStr.splice(-1);
        }
        this.$refs["data-rendered"].innerHTML = "";
        getStr.map((item) => {
          const elt = document.createElement("span");
          elt.classList.add("ml-1");
          elt.classList.add("u--tag");
          elt.innerHTML = " " + item + " ";
          this.$refs["data-rendered"].appendChild(elt);
          this.appUrlsLs.push(elt);
        });
      } else if (this.ultraIpt.length === 0) {
        this.appUrls = "";
      }
    },
    async showComponent(val) {
      this.selectProjectId = this.$route.params.id;
      this.showEvent = false;
      this.showCoveringtests = false;
      if (val === "coveringtests") {
        this.showCoveringtests = true;
      }
      if (val === "event") {
        this.showEvent = true;
      }
    },
    cleanUpProject() {
      this.projectName = "";
      this.appUrls = "";
    },
    addTestModalProject() {
      this.addTestProject = true;
      this.appUrls = "";
      this.projectName = "";
      this.projectId = null;
      this.editProject = false;
      this.$refs["data-rendered"].innerHTML = "";
    },
    showResult(val) {
      this.showEvents = false;
      this.showSessions = false;
      if (val == "event") {
        this.showEvents = true;
      }
      if (val == "session") {
        this.showSessions = true;
      }
    },
    pad(num) {
      if (num) return ("" + num).slice(-2);
      return "";
    },
    hhmmss(secs) {
      let minutes = Math.floor(secs / 60);
      let dis_sec = Math.ceil(secs % 60);
      let hours = Math.floor(minutes / 60);
      minutes = minutes % 60;
      let result = "";
      if (hours > 0) {
        result = result + `${this.pad(hours)}h `;
      }
      if (minutes > 0) {
        result = result + `${this.pad(minutes)}m `;
      }
      if (dis_sec > 0) {
        result = result + `${this.pad(dis_sec)}s `;
      }
      return result;
    },
    secondsToRealTime(b) {
      return this.hhmmss(b) == "" ? "0s" : this.hhmmss(b);
    },
    secondsToRealTimeEvent(a, b) {
      let start = Date.parse(a);
      let end = Date.parse(b);
      let sec = end - start;
      return sec / 1000;
    },

    formatDate(a) {
      return moment(a).format("MMM DD YYYY, h:mm:ss a");
    },
    formatDateEvent(a) {
      return moment(a).format("YYYY-MM-DDTHH:mm:ss.sssZ");
    },
    async getSessions() {
      let projectId = this.$route.params.id;
      const params = new URLSearchParams();
      params.append("project_id", projectId);
      params.append("limit", this.paginationSession.perPage);
      params.append("page", this.paginationSession.currentPage - 1);
      axios.defaults.validateStatus = (status) => {
        // return status >= 200 && status < 401; // to catch 400 error
        return status === 400 || (status >= 200 && status < 500);
      };
      this.loadingSession = true;
      await axios
        .get(process.env.VUE_APP_API_URL_PREFIX + "/pageviews/sessions?" + params)
        .then((response) => {
          if (response) {
            if (response.message === "Request failed with status code 500") {
              this.$notify({
                type: "danger",
                message: "an error has occurred",
              });
              this.resultSession = [];
              this.totalSession = 0;
            } else if (response.data.status == "success") {
              this.resultSession = response.data.response;
              this.totalSession = response.data.response.length;
            } else {
              this.resultSession = [];
              this.totalSession = 0;
            }
          }
        })
        .catch((error) => {
          console.log(error);
          // this.$notify({
          //   type: "danger",
          //   message: "an error occurred while retrieving data",
          // });
        });
      this.filteredResultsSession = this.filteredResultSessionSet;
      this.initializeSessionState();
      this.loadingSession = false;
    },
    async getProject() {
      this.loadingProject = true;
      const url = `${process.env.VUE_APP_API_URL_PREFIX}/pageviews/user-projects`;
      try {
        axios.defaults.validateStatus = (status) => {
          // return status >= 200 && status < 401; // to catch 400 error
          return status === 400 || (status >= 200 && status < 500);
        };
        const res = await axios.get(url);
        if ((res.data && res.data.status == "success") || res.data.status == 200) {
          this.resultProject = res.data.response;
          this.totalProject = res.data.response.length;
        } else {
          this.resultProject = [];
          this.totalProject = 0;
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          duration: 5000,
          message: "no project found ",
        });
      } finally {
        this.loadingProject = false;
      }
    },
    async processRun(val) {
      let url = val.person.properties.$initial_current_url.replace(/https:\/\//g, "");
      this.run_name =
        url +
        " " +
        val.person.properties.$initial_browser +
        " " +
        val.person.properties.$geoip_country_name;
      // console.log("get Details session")
      await this.detailsSession(val);
      // console.log("getFileTest called")

      await this.getFiletest(val.id);
    },
    async processShowVideo(val) {
      console.log("In processShowVideo method");
      console.log("Val from video is: ", val);
      val.id = val.tests[0].session_id;
      console.log("Video id:", val.id);
      await this.detailsSession(val);
    },
    async addTest() {
      const ifMultipleFiles = this.filesList.length > 1;
      const files = this.filesList;
      let scriptType = "JSON";
      var single = false;
      var double = false;
      let x_neighbor_maxdist = "";
      let x_interceptor_failure = "";
      if (this.extra_element_data === 0) {
        x_neighbor_maxdist = 100;
        x_interceptor_failure = "exception";
      } else {
        x_neighbor_maxdist = 900;
        x_interceptor_failure = "suggest-xpaths";
      }

      let parsedRunTags = this.parseRunTags(this.run_tags);
      if (this.enable_integrations) {
        parsedRunTags.push("integration enabled");
      }

      const runOptions = {
        filterDisplayed: this.ignore_disabled_elements,
        filterEnabled: this.ignore_invisible_elements,
        allSuggestEnabled: this.all_suggest,
        useTgAnalyze: this.collect_page_interaction_data,
        suiteFailType: this.continue_test_suite_on_fail ? "continue" : "fail-on-test",
        runnerResolution: `${this.browser_width}x${this.browser_height}`,
        runnerStepWait: this.max_wait_time,
        runnerUserTimeout: this.timeout_cancel_run_value,
        handleFailure: x_interceptor_failure,
        neighborMaxDist: x_neighbor_maxdist,
        goodAttributeList: this.good_attributes_list,
        badAttributeList: this.bad_attributes_list,
        xpathDownweightStrings: this.downweight_strings,
        runTags: parsedRunTags.join(","),
      };
      const formData = new FormData();
      let reqHeaders = {};
      if (ifMultipleFiles) {
        double = true;
        reqHeaders = {
          "Content-Type": "multipart/form-data;",
          "x-test-script-type": scriptType,
          "x-Rp-launch": this.run_name,
          "x-rp-description": this.run_description,
          "x-filter-displayed": this.ignore_disabled_elements,
          "x-filter-enabled": this.ignore_invisible_elements,
          "x-runner-resolution": `${this.browser_width}x${this.browser_height}`,
          "x-runner-step-wait": this.max_wait_time,
          "x-interceptor-failure": x_interceptor_failure,
          "x-neighbor-maxdist": x_neighbor_maxdist,
          "x-all-suggest-enabled": this.all_suggest,
          "x-use-tg-analyze": this.collect_page_interaction_data,
          "X-Good-Attributes": this.good_attributes_list,
          "X-Bad-Attributes": this.bad_attributes_list,
          "X-Downweight-Strings": this.downweight_strings ? "1" : "0",
          "X-Run-Tags": runOptions.runTags,
          "X-Runner-Verify-Selectors": this.verify_suggested_selectors ? "1" : "0",
        };

        let runnerTimeoutSec = null;
        runnerTimeoutSec = parseFloat(this.timeout_cancel_run_value);
        if (
          !Number.isNaN(runnerTimeoutSec) &&
          runnerTimeoutSec > 0 &&
          runnerTimeoutSec < 3600
        ) {
          reqHeaders["X-Runner-Timeout"] = `${runnerTimeoutSec}`;
        }
      } else {
        single = true;
        reqHeaders = {
          "Content-Type": "multipart/form-data;",
          "x-test-script-type": scriptType,
          "x-Rp-launch":
            this.run_name +
            " file_" +
            files[0].name.replace("generated-test-run", "").replace(".side", ""),
          "x-rp-description": this.run_description,
          "x-filter-displayed": this.ignore_disabled_elements,
          "x-filter-enabled": this.ignore_invisible_elements,
          "x-runner-resolution": `${this.browser_width}x${this.browser_height}`,
          "x-runner-step-wait": this.max_wait_time,
          "x-interceptor-failure": x_interceptor_failure,
          "x-neighbor-maxdist": x_neighbor_maxdist,
          "x-all-suggest-enabled": this.all_suggest,
          "x-use-tg-analyze": this.collect_page_interaction_data,
          "X-Good-Attributes": this.good_attributes_list,
          "X-Bad-Attributes": this.bad_attributes_list,
          "X-Downweight-Strings": this.downweight_strings ? "1" : "0",
          "X-Run-Tags": runOptions.runTags,
          "X-Runner-Verify-Selectors": this.verify_suggested_selectors ? "1" : "0",
        };

        let runnerTimeoutSec = null;
        runnerTimeoutSec = parseFloat(this.timeout_cancel_run_value);
        if (
          !Number.isNaN(runnerTimeoutSec) &&
          runnerTimeoutSec > 0 &&
          runnerTimeoutSec < 3600
        ) {
          reqHeaders["X-Runner-Timeout"] = `${runnerTimeoutSec}`;
        }
        formData.append("attachedFile", files[0], files[0].name);
      }
      if (single) {
        try {
          this.loadingModalAddRun = true;
          const res = await axios.post(
            process.env.VUE_APP_API_URL_PREFIX + "/testscript/v1",
            formData,
            { headers: reqHeaders }
          );
          if ((res.data.status = "queued")) {
            this.run_description = "";
            this.addTestModal = false;
            this.pollStatus = true;
            this.singleTestId = true;
            this.loadingModalAddRun = false;
            this.lastUrlTest = "/details/test-explorer/list-tests";
            // swal.fire(`<div style="width: 100%;">Test started at : <a href="${this.lastUrlTest}"> ${this.lastUrlTest}</a></div>`);
            this.$notify({
              type: "success",
              message: `Test started at : <a href="${this.lastUrlTest}"> ${this.lastUrlTest}</a>`,
            });
          } else {
            this.$notify({
              type: "danger",
              message: "execution failed",
            });
          }
        } catch (error) {
          console.log(error);
          this.$notify({
            type: "danger",
            message: "execution failed",
          });
        }
        this.loadingModalAddRun = false;
      }
      if (double) {
        this.loadingModalAddRun = true;
        let status = "";
        // for (let index = 0; index < files.length; index++) {
        //   formData.append("attachedFile", files[index], files[index].name);
        //   reqHeaders["x-Rp-launch"] = this.run_name + index
        //   const res = await axios.post(process.env.VUE_APP_API_URL_PREFIX + "/testscript/v1", formData, {headers: reqHeaders,})
        //   if (!res.data || res.data.status != "queued") {
        //     this.$notify({
        //         type: "danger",
        //         message: "execution failed",
        //     });
        //     status = "failed"
        //   }else if(res.data.status == "queued"){
        //     status = "success"
        //   }
        // }
        formData.append(
          "attachedFile",
          files[files.length - 1],
          files[files.length - 1].name
        );
        reqHeaders["x-Rp-launch"] = this.run_name;
        const res = await axios.post(
          process.env.VUE_APP_API_URL_PREFIX + "/testscript/v1",
          formData,
          { headers: reqHeaders }
        );
        if (!res.data || res.data.status != "queued") {
          this.$notify({
            type: "danger",
            message: "execution failed",
          });
          status = "failed";
        } else if (res.data.status == "queued") {
          status = "success";
        }
        if (status === "success") {
          this.run_description = "";
          this.addTestModal = false;
          this.pollStatus = true;
          this.singleTestId = true;
          this.loadingModalAddRun = false;
          this.lastUrlTest = "/details/test-explorer/list-tests";
          // swal.fire(`<div style="width: 100%;">Test started at : <a href="${this.lastUrlTest}"> ${this.lastUrlTest}</a></div>`);
          this.$notify({
            type: "success",
            message: `Test started at : <a href="${this.lastUrlTest}"> ${this.lastUrlTest}</a>`,
          });
          this.loadingModalAddRun = false;
        }
      }
    },
    downloadTestFile(index) {
      // console.log("Before downloading file: ", files);
      if (index) {
        const files = this.fileBackup;
        let fileName = files[index].name;
        var fileURL = window.URL.createObjectURL(new Blob([files[index]]));
        var fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", fileName);
        document.body.appendChild(fileLink);
        fileLink.click();
      } else {
        const files = this.filesList;
        let fileName = files[0].name;
        var fileURL = window.URL.createObjectURL(new Blob([files[0]]));
        var fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", fileName);
        document.body.appendChild(fileLink);
        fileLink.click();
      }
    },
    async actionCoveringtests(action, elt) {
      console.log("actionCoveringTests");
      if (action === "downloadTestFile") {
        await this.builFile(elt);
        this.downloadTestFile();
      }
      if (action === "runTest") {
        await this.builFile(elt);
        this.addTestModal = true;
      }
    },
    savePagination() {
      sessionStorage.setItem("testEventPageNbr", this.pagination.perPage);
      this.getEvents();
    },
    savePaginationSession() {
      sessionStorage.setItem("sessionPageNbr", this.paginationSession.perPage);
      this.getSessions();
    },
    savePaginationProject() {
      sessionStorage.setItem("projectPageNbr", this.paginationProject.perPage);
      this.getProject();
    },
    handleSizeChangeSession(val) {
      this.fromSession = (val - 1) * this.paginationSession.perPage;
      this.toSesion =
        parseInt(this.fromSession) + parseInt(this.paginationSession.perPage);
      const sortByMapped = (map, compareFn) => (a, b) => compareFn(map(a), map(b));
      const byValue = (a, b) => a - b;
      const toPrice = (e) => e.price;
      const byPrice = sortByMapped(toPrice, byValue);
      this.filteredResultsSession = [...this.filteredResultsSession].sort(byPrice);
      this.toShowSession = this.filteredResultsSession;
    },
    handleSizeCoveringTests() {
      const sortByMapped = (map, compareFn) => (a, b) => compareFn(map(a), map(b));
      const byValue = (a, b) => a - b;
      const toPrice = (e) => e.price;
      const byPrice = sortByMapped(toPrice, byValue);
      this.filteredResultsProject = [...this.filteredResultsProject].sort(byPrice);
      this.toShowProject = this.filteredResultsProject;
    },
    handleCurrentChangeSession(val) {
      val == "next"
        ? (this.paginationSession.currentPage = this.paginationSession.currentPage + 1)
        : (this.paginationSession.currentPage = this.paginationSession.currentPage - 1);
      this.getSessions();
    },
    initializeSessionState() {
      this.filteredResultsSession = this.filteredResultSessionSet;
      this.handleSizeChangeSession(this.paginationSession.currentPage);
    },
    initializeSessionEvent() {
      this.tagsEvent = this.filteredResultSessionEventSet;
    },
    initializeCoveringtestsState() {
      this.toShowCoveringtests = this.filteredResultCoveringtestsSet;
      // this.handleSizeCoveringTests();
    },
    forStepNumber(row) {
      return row + this.fromSession + 1;
    },
    removedtag(row) {
      return row.replace("$", "");
    },
    async detailsSession(elt) {
      await this.getEventSession(elt);
      let projectId = this.$route.params.id;
      this.loadingVideo = true;
      this.showVideo = true;
      this.id = elt.id;
      const params = new URLSearchParams();
      params.append("project_id", projectId);
      params.append("session_id", this.id);
      axios.defaults.validateStatus = (status) => {
        return status === 400 || (status >= 200 && status < 300);
      };
      await axios
        .get(process.env.VUE_APP_API_URL_PREFIX + "/pageviews/snapshots?" + params)
        .then((response) => {
          if (response) {
            if (response.message === "Request failed with status code 500") {
              this.$notify({
                type: "danger",
                message: "the recovery of the video failed ",
              });
              this.events = [];
            } else if (response.data.status === "success") {
              this.events = [];
              // let index =  Object.entries(response.data.response.result.snapshot_data_by_window_id)[0]
              // this.events = response.data.response.result.snapshot_data_by_window_id[index[0]]
              let allVideo = [];
              response.data.response.snapshots.map((item) => {
                return Object.entries(item.result.snapshot_data_by_window_id).map(
                  (key) => {
                    this.events = this.events.concat(key[1]);
                  }
                );
              });
              this.loadingVideo = false;
            } else if (response.data.status === "failure") {
              this.$notify({
                type: "danger",
                message: "the recovery of the video failed ",
              });
              this.events = [];
            }
          }
        })
        .catch((error) => {
          console.log(error);
          this.$notify({
            type: "danger",
            message: "an error occurred while retrieving data ",
          });
        });
      this.loadingVideo = false;
      this.addPik();
    },
    async getEventSession(elt) {
      this.autoplay = false;
      let projectId = this.$route.params.id;
      this.loadingVideo = true;
      this.showVideo = true;
      this.id = elt.id;
      const params = new URLSearchParams();
      params.append("project_id", projectId);
      params.append("session_id", this.id);
      axios.defaults.validateStatus = (status) => {
        return status === 400 || (status >= 200 && status < 300);
      };
      await axios
        .get(process.env.VUE_APP_API_URL_PREFIX + "/pageviews/events?" + params)
        .then((response) => {
          if (response) {
            if (response.message === "Request failed with status code 500") {
              this.$notify({
                type: "danger",
                message: "the recovery of the video failed ",
              });
              this.tagsEvent = {};
            } else if (response.data.status === "success") {
              this.tagsEvent = {};
              this.tagsEvent = response.data.response;
              this.tagsEvent.map((item) => {
                item["start_time"] = this.secondsToRealTimeEvent(
                  elt.start_time,
                  item.timestamp
                );
                return item;
              });
              this.resultEvent = this.tagsEvent;
              this.activeSessionDuration = elt.recording_duration;
            } else if (response.data.status === "failure") {
              this.$notify({
                type: "danger",
                message: "the recovery of the video failed ",
              });
              this.tagsEvent = {};
            }
          }
        })
        .catch((error) => {
          console.log(error);
          this.$notify({
            type: "danger",
            message: "an error occurred while retrieving data ",
          });
        });
    },
    async addPik() {
      await this.$nextTick();
      const lectBar = document.getElementsByClassName("rr-progress")[0];
      const timeLine = document.getElementsByClassName("rr-timeline")[0];
      const progress__handler = document.getElementsByClassName("rr-progress__handler")[0];
      const progress__step = document.getElementsByClassName("rr-progress__step")[0];
      const ticks = document.createElement("div");
      ticks.classList.add("ticks");

      for (let index = 0; index < this.tagsEvent.length; index++) {
        const ticks_box = document.createElement("div");
        ticks_box.classList.add("tick-hover-box");
        let left =
          (this.tagsEvent[index].start_time * 100) / this.activeSessionDuration - 0.5;
        if (left > 100) left = 100 - 0.5;
        if (left < 0) left = 0;
        ticks_box.style.left = left + "%";

        const tick_info = document.createElement("div");
        tick_info.classList.add("tick-info");
        tick_info.innerHTML = this.tagsEvent[index].event;

        const tick_marker = document.createElement("div");
        tick_marker.classList.add("tick-marker");

        const tick_thumb = document.createElement("div");
        tick_thumb.classList.add("tick-thumb");
        ticks_box.setAttribute("id", "tick_thumb_" + index);

        ticks_box.appendChild(tick_info);
        ticks_box.appendChild(tick_marker);
        ticks_box.appendChild(tick_thumb);
        ticks.appendChild(ticks_box);
      }
      console.log("hello")

      timeLine && (timeLine.style.width = "100%");
      progress__step.style.zIndex = "2";
      progress__handler.style.zIndex = "2";
      lectBar.appendChild(ticks);
      this.autoplay = true;
    },
    isActive(menuItem) {
      return this.activeItem === menuItem;
    },
    setActive(menuItem) {
      this.activeItem = menuItem;
    },
    cleanUpState() {
      this.run_name = "";
      this.run_description = "";
      this.run_tags = "";
      this.browser_width = "1920";
      this.browser_height = "1080";
      this.max_wait_time = "5.0";
      this.ignore_disabled_elements = "1";
      this.ignore_invisible_elements = "1";
      this.collect_page_interaction_data = "1";
      this.extra_element_data = 0;
      this.downweight_strings = 0;
      this.all_suggest = 0;
      this.try_to_avoid = false;
      this.bad_attributes_list = "";
      this.good_attributes_list = "";
      this.continue_test_suite_on_fail = "true";
      this.timeout_cancel_run = "";
      this.timeout_cancel_run_value = null;
      this.handle_failure = "";
      this.verify_suggested_selectors = false;
      this.enable_integrations = true;
      this.collapseActive = null;
    },
    parseRunTags(runTags) {
      if (runTags.length === 0) {
        return [];
      }

      const splitTags = runTags.split(",");
      return splitTags.map((item) => {
        return item.trim().toLowerCase();
      });
    },
    async getFiletest(id) {
      let projectId = this.$route.params.id;
      const params = new URLSearchParams();
      params.append("project_id", projectId);
      params.append("session_id", this.id);
      this.loadingModalAddRun = true;
      axios.defaults.validateStatus = (status) => {
        return status === 400 || (status >= 200 && status < 500);
      };
      await axios
        .get(process.env.VUE_APP_API_URL_PREFIX + "/pageviews/testgen?" + params)
        .then((response) => {
          if (response) {
            if (response.message === "Request failed with status code 500") {
              this.$notify({
                type: "danger",
                message: "an error has occurred",
              });
              this.addTestModal = false;
            } else if (response.status === 200) {
              // console.log("response.data fro testgen: ", response)
              this.builFile(response.data);
            } else {
              this.$notify({
                type: "danger",
                message: "recovery of the execution file failed ",
              });
              this.addTestModal = false;
            }
          }
        })
        .catch((error) => {
          console.log(error);
          this.$notify({
            type: "danger",
            message: "recovery of the execution file failed ",
          });
          this.addTestModal = false;
        });
    },
    builFile(file) {
      this.loadingModalAddRun = true;
      this.filesList = [];
      this.fileBackup = [];
      if (file.length) {
        // Just get the last test
        // file = file[file.length - 1];
        file.map((item, index) => {
          let jsonStringified = JSON.stringify(item);
          let jsonBlob = new Blob([jsonStringified], { type: "application/json" });
          let testFile = new File([jsonBlob], `generated-test-run${index}.side`);
          this.fileBackup.push(testFile);
        });
        this.filesList = [this.fileBackup[this.fileBackup.length - 1]];
        this.filesListName = this.filesList[0].name;
        this.loadingModalAddRun = false;
        // file = file[0];
      } else {
        let jsonStringified = JSON.stringify(file);
        let jsonBlob = new Blob([jsonStringified], { type: "application/json" });
        let testFile = new File([jsonBlob], "generated-test-run.side");
        this.filesList = [testFile];
        this.filesListName = this.filesList.name;
        this.fileBackup = [testFile];
        this.loadingModalAddRun = false;
      }
    },
    async addProject(val) {
      this.loadingAddProject = true;
      if (val === "add") {
        let appUrls = this.$refs["data-rendered"].innerHTML
          .replace(/<span class="ml-1 u--tag">/g, "")
          .replace(/<span class="ml-1">/g, "")
          .replace(/<\/span>/g, ",")
          .split(",")
          .filter((s) => s.trim().length > 0)
          .map((s) => s.trim());
        if (this.ultraIpt) appUrls.push(this.ultraIpt);
        this.ultraIpt = "";
        let data = {
          // appUrls: appUrls,
          name: this.projectName,
        };
        if (appUrls) {
          data["appUrls"] = appUrls;
        }
        const url = `${process.env.VUE_APP_API_URL_PREFIX}/pageviews/user-projects`;
        try {
          axios.defaults.validateStatus = (status) => {
            // return status >= 200 && status < 401; // to catch 400 error
            return status === 400 || (status >= 200 && status < 500);
          };
          const res = await axios.post(url, data);
          if ((res.data && res.data.status == "success") || res.data.status == 200) {
            this.detailProject(res.data.response.projectId);
            this.$notify({
              type: "success",
              message: res.data.message,
            });
            this.addTestProject = false;
            this.cleanUpProject();
          } else {
            this.$notify({
              type: "danger",
              duration: 5000,
              message: res.data.message,
            });
          }
        } catch (error) {
          this.$notify({
            type: "danger",
            duration: 5000,
            message: "an error occurred while add the project.",
          });
        } finally {
          this.loadingAddProject = false;
          this.getProject();
        }
      }
      if (val === "edit") {
        const url = `${process.env.VUE_APP_API_URL_PREFIX}/pageviews/user-projects/${this.projectId}`;
        let data = {
          name: this.projectName,
        };
        let appUrls = "";
        if (this.ultraIpt.length > 1) appUrls = this.ultraIpt;
        if (appUrls.length > 1) {
          this.addUrls(appUrls);
        }
        try {
          axios.defaults.validateStatus = (status) => {
            // return status >= 200 && status < 401; // to catch 400 error
            return status === 400 || (status >= 200 && status < 500);
          };
          const res = await axios.put(url, data);
          if ((res.data && res.data.status == "success") || res.data.status == 200) {
            this.$notify({
              type: "success",
              message: res.data.message,
            });
          } else {
            this.$notify({
              type: "danger",
              duration: 5000,
              message: res.data.message,
            });
          }
        } catch (error) {
          this.$notify({
            type: "danger",
            duration: 5000,
            message: "an error occurred while edit the project .",
          });
        } finally {
          this.projectDetail = Object.assign({}, this.newProjectDetail);
          this.loadingAddProject = false;
          this.addTestProject = false;
          this.cleanUpProject();
          this.getProject();
          this.ultraIpt = "";
        }
      }
    },
    async editProjectModal(row) {
      this.ultraIpt = "";
      this.loadingAddProject = true;
      this.addTestProject = true;
      this.projectName = row.name;
      this.editProject = true;
      this.projectId = row.projectId;

      this.$refs["data-rendered"].innerHTML = "";
      const id = row.projectId;
      const url = `${process.env.VUE_APP_API_URL_PREFIX}/pageviews/user-projects/${id}`;
      try {
        axios.defaults.validateStatus = (status) => {
          // return status >= 200 && status < 401; // to catch 400 error
          return status === 400 || (status >= 200 && status < 500);
        };
        const res = await axios.get(url);
        if ((res.data && res.data.status === "success") || res.data.status === 200) {
          res.data.response.appUrls.map((item) => {
            const elt = document.createElement("span");
            elt.classList.add("ml-1");
            elt.classList.add("u--tag");
            elt.innerHTML = " " + item + " ";
            this.$refs["data-rendered"].appendChild(elt);
          });
          this.projectDetail = Object.assign({}, res.data.response);
          this.projectDetail.jsSnppet = `<script> !function(t,e){var o,n,p,r;e.__SV||(window.wring=e,e._i=[],e.init=function(i,s,a){function g(t,e){var o=e.split(".");2==o.length&&(t=t[o[0]],e=o[1]),t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}}(p=t.createElement("script")).type="text/javascript",p.async=!0,p.src="https://wring.dev/assets/js/array.js",(r=t.getElementsByTagName("script")[0]).parentNode.insertBefore(p,r);var u=e;for(void 0!==a?u=e[a]=[]:a="wring",u.people=u.people||[],u.toString=function(t){var e="wring";return"wring"!==a&&(e+="."+a),t||(e+=" (stub)"),e},u.people.toString=function(){return u.toString(1)+".people (stub)"},o="capture identify alias people.set people.set_once set_config register register_once unregister opt_out_capturing has_opted_out_capturing opt_in_capturing reset isFeatureEnabled onFeatureFlags".split(" "),n=0;n<o.length;n++)g(u,o[n]);e._i.push([i,s,a])},e.__SV=1)}(document,window.wring||[]);wring.init('${this.projectDetail.apiKey}',{advanced_capture_all_elements: true, enable_recording_console_log: true, api_host:'${this.projectDetail.serverUrl}'}) <\/script> `;
          this.loadingAddProject = false;
        } else {
          this.loadingAddProject = false;
          this.addTestProject = false;
          this.editProject = false;
          this.$notify({
            type: "danger",
            duration: 5000,
            message: res.data.message,
          });
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          duration: 5000,
          message: "an error occurred while edit the project .",
        });
      }
    },
    async deleteProject(row) {
      const confirmation = await swal.fire({
        title: "Project '" + row.name + "' will be removed.",
        type: "question",
        buttonsStyling: true,
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, keep the project.",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });
      const id = row.projectId;
      const url = `${process.env.VUE_APP_API_URL_PREFIX}/pageviews/user-projects/${id}`;
      try {
        axios.defaults.validateStatus = (status) => {
          // return status >= 200 && status < 401; // to catch 400 error
          return status === 400 || (status >= 200 && status < 300);
        };
        if (confirmation.value === true) {
          const res = await axios.delete(url);
          if ((res.data && res.data.status == "success") || res.data.status == 200) {
            this.$notify({
              type: "success",
              message: res.data.message,
            });
          } else {
            this.$notify({
              type: "danger",
              duration: 5000,
              message: res.data.message,
            });
          }
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          duration: 5000,
          message: "an error occurred while edit the project .",
        });
      } finally {
        this.getProject();
      }
    },
    async detailProject(row) {
      this.$refs["data-rendered-detail"].innerHTML = "";
      this.projectDetail = Object.assign({}, this.newProjectDetail);
      this.showDetailProject = true;
      this.loadingDetailProject = true;
      const id = row;
      const url = `${process.env.VUE_APP_API_URL_PREFIX}/pageviews/user-projects/${id}`;
      try {
        axios.defaults.validateStatus = (status) => {
          // return status >= 200 && status < 401; // to catch 400 error
          return status === 400 || (status >= 200 && status < 500);
        };
        const res = await axios.get(url);
        if ((res.data && res.data.status == "success") || res.data.status == 200) {
          this.projectDetail = Object.assign({}, res.data.response);
          this.projectDetail.appUrls.map((item) => {
            const elt = document.createElement("span");
            elt.classList.add("ml-1");
            elt.classList.add("u--tag");
            elt.innerHTML = " " + item + " ";
            this.$refs["data-rendered-detail"].appendChild(elt);
          });
          this.projectDetail.jsSnppet = `<script> !function(t,e){var o,n,p,r;e.__SV||(window.wring=e,e._i=[],e.init=function(i,s,a){function g(t,e){var o=e.split(".");2==o.length&&(t=t[o[0]],e=o[1]),t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}}(p=t.createElement("script")).type="text/javascript",p.async=!0,p.src="https://wring.dev/assets/js/array.js",(r=t.getElementsByTagName("script")[0]).parentNode.insertBefore(p,r);var u=e;for(void 0!==a?u=e[a]=[]:a="wring",u.people=u.people||[],u.toString=function(t){var e="wring";return"wring"!==a&&(e+="."+a),t||(e+=" (stub)"),e},u.people.toString=function(){return u.toString(1)+".people (stub)"},o="capture identify alias people.set people.set_once set_config register register_once unregister opt_out_capturing has_opted_out_capturing opt_in_capturing reset isFeatureEnabled onFeatureFlags".split(" "),n=0;n<o.length;n++)g(u,o[n]);e._i.push([i,s,a])},e.__SV=1)}(document,window.wring||[]);wring.init('${this.projectDetail.apiKey}',{advanced_capture_all_elements: true, enable_recording_console_log: true, api_host:'${this.projectDetail.serverUrl}'}) <\/script> `;
          this.loadingDetailProject = false;
        } else {
          this.$notify({
            type: "danger",
            duration: 5000,
            message: res.data.message,
          });
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          duration: 5000,
          message: "an error occurred while edit the project .",
        });
      }
    },
    async addUrls(urls) {
      const url = `${process.env.VUE_APP_API_URL_PREFIX}/pageviews/user-projects/${this.projectId}`;
      let data = {
        appUrls: [
          {
            op: "add",
            url: urls,
          },
        ],
      };
      try {
        axios.defaults.validateStatus = (status) => {
          // return status >= 200 && status < 401; // to catch 400 error
          return status === 400 || (status >= 200 && status < 500);
        };
        const res = await axios.put(url, data);
        if ((res.data && res.data.status == "success") || res.data.status == 200) {
          return true;
        } else {
          this.$notify({
            type: "danger",
            duration: 5000,
            message: "the addition of the new url has failed.",
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async deleteUrls(urls) {
      const url = `${process.env.VUE_APP_API_URL_PREFIX}/pageviews/user-projects/${this.projectId}`;
      console.log(urls);
      let data = {
        appUrls: [
          {
            op: "remove",
            url: urls,
          },
        ],
      };
      try {
        axios.defaults.validateStatus = (status) => {
          // return status >= 200 && status < 401; // to catch 400 error
          return status === 400 || (status >= 200 && status < 500);
        };
        const res = await axios.put(url, data);
        if ((res.data && res.data.status == "success") || res.data.status == 200) {
          return true;
        } else {
          this.$notify({
            type: "danger",
            duration: 5000,
            message: "deleting url failed .",
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getCoveringTests() {
      this.loadingShowCoveringtests = true;
      let projectId = this.$route.params.id;
      const params = new URLSearchParams();
      this.date_from = moment(this.filterDate[0]).format("YYYY-MM-DD");
      this.date_to = moment(this.filterDate[1]).format("YYYY-MM-DD");
      params.append("date_to", this.date_to);
      params.append("date_from", this.date_from);
      if (this.date_to == "Invalid date" || this.date_from == "Invalid date") {
        this.$notify({
          type: "danger",
          duration: 5000,
          message: "Invalid date.",
        });
        this.loadingShowCoveringtests = false;
        return;
      }
      // params.append('limit', this.limitCoveringtests);
      console.log(this.date_from, this.date_to);
      params.append("project_id", projectId);
      // params.append('percent', this.percent);
      params.append("percent", this.SelectCov);
      const url = `${process.env.VUE_APP_API_URL_PREFIX}/pageviews/coveringtests?${params}`;
      try {
        axios.defaults.validateStatus = (status) => {
          return status === 400 || (status >= 200 && status < 300) || status === 500;
        };
        const res = await axios.get(url, params);
        if (res.data && res.status === 200) {
          this.resultCoveringtests = res.data.testcases.reverse();
        } else {
          this.toShowCoveringtests = [];
          console.log(res.data.message);
        }
      } catch (error) {
        console.log("an error occurred while covering the project.");
      } finally {
        this.loadingShowCoveringtests = false;
        this.initializeCoveringtestsState()
      }
    },
  },
  computed: {
    filteredResultSet() {
      const seq = this.result;
      return seq && Array.isArray(seq)
        ? seq.filter((item) => {
            if (this.query) {
              return item.person.properties.$geoip_country_name
                .toLowerCase()
                .includes(this.query.toLowerCase());
            } else {
              return item;
            }
          })
        : [];
    },
    filteredResultSessionSet() {
      const seq = this.resultSession;
      return seq && Array.isArray(seq)
        ? seq.filter((item) => {
            if (this.querySession) {
              if (
                item.person.properties.$geoip_country_name
                  .toLowerCase()
                  .includes(this.querySession.toLowerCase())
              ) {
                return item.person.properties.$geoip_country_name
                  .toLowerCase()
                  .includes(this.querySession.toLowerCase());
              } else if (
                item.person.properties.$geoip_continent_name
                  .toLowerCase()
                  .includes(this.querySession.toLowerCase())
              ) {
                return item.person.properties.$geoip_continent_name
                  .toLowerCase()
                  .includes(this.querySession.toLowerCase());
              } else if (
                item.person.properties.$initial_browser
                  .toLowerCase()
                  .includes(this.querySession.toLowerCase())
              ) {
                return item.person.properties.$initial_browser
                  .toLowerCase()
                  .includes(this.querySession.toLowerCase());
              } else if (
                item.person.properties.$initial_os
                  .toLowerCase()
                  .includes(this.querySession.toLowerCase())
              ) {
                return item.person.properties.$initial_os
                  .toLowerCase()
                  .includes(this.querySession.toLowerCase());
              }
            } else {
              return item;
            }
          })
        : [];
    },
    filteredResultSessionEventSet() {
      const seq = this.resultEvent;
      return seq && Array.isArray(seq)
        ? seq.filter((item) => {
            if (this.querySessionEvent) {
              if (
                item.event.toLowerCase().includes(this.querySessionEvent.toLowerCase())
              ) {
                return item.event
                  .toLowerCase()
                  .includes(this.querySessionEvent.toLowerCase());
              } else if (
                item.properties.$pathname
                  .toLowerCase()
                  .includes(this.querySessionEvent.toLowerCase())
              ) {
                return item.properties.$pathname
                  .toLowerCase()
                  .includes(this.querySessionEvent.toLowerCase());
              }
            } else {
              return item;
            }
          })
        : [];
    },
    filteredResultCoveringtestsSet() {
      const seq = this.resultCoveringtests;
      return seq && Array.isArray(seq)
        ? seq.filter((item) => {
            if (this.queryCoveringtests.length > 0) {
              if (item.name.toLowerCase().includes(this.queryCoveringtests.toLowerCase())) {
                return item.name.toLowerCase().includes(this.queryCoveringtests.toLowerCase());
              }
            } else {
              console.log(1)
              return item;
            }
          })
        : [];
    },
    filteredList() {
      return this.filteredResults.slice(this.from, this.to);
    },
    filteredListSession() {
      return this.filteredResultsSession.slice(this.from, this.to);
    },
    pagesSession() {
      return Math.ceil(
        this.filteredResultsSession.length / this.paginationSession.perPage
      );
    },
    pages() {
      return Math.ceil(this.filteredResults.length / this.pagination.perPage);
    },
    // eslint-disable-next-line vue/return-in-computed-property
    canAddPrevious() {
      if (this.pagination.currentPage == 1) return true;
      return false;
    },
    canAddPreviousSession() {
      if (this.paginationSession.currentPage == 1) return true;
      return false;
    },
  },
  watch: {
    querySession: {
      handler: "initializeSessionState",
      immediate: true,
    },
    querySessionEvent: {
      handler: "initializeSessionEvent",
      immediate: true,
    },
    paginationSession: {
      handler: "initializeSessionState",
      immediate: false,
      deep: true,
    },
    queryCoveringtests: {
      handler: "initializeCoveringtestsState",
      immediate: true,
    },
    // paginationProject: {
    //   handler: "initializeCoveringtestsState",
    //   immediate: false,
    //   deep: true,
    // },
  },
};
</script>

<style lang="scss">
@import "../../../assets/sass/custom/custom-variables.scss";

.fl--center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.add-test-project-modal {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  .modal-body {
    max-height: 60vh;
    overflow: auto;
  }
}

.run-name {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}

.model-label {
  display: block;
  font-size: 13px !important;
  font-weight: 600;
  margin-top: 0.5em;
}

.run-name input,
.run-name textarea,
.modal-checks-container textarea {
  width: 100%;
  border-radius: 5px;
  padding: 7px;
  outline: none;
  border: 1px solid #8a8d90;
  box-sizing: border-box;
  font-size: 13px;
}

.run-name input:focus,
.run-name textarea:focus,
.modal-checks-container textarea:focus {
  border-bottom: 1px solid rgb(68, 67, 67);
  -webkit-box-shadow: 0px -5px 2px -5px #222 inset;
  -moz-box-shadow: 0px -5px 2px -5px#222 inset;
  box-shadow: 0px -5px 2px -5px #222 inset;
}

.run-name input::-moz-placeholder,
.run-name textarea::-moz-placeholder {
  color: #8a8d90;
  font-size: 13px !important;
}

.run-name input::placeholder,
.run-name textarea::placeholder {
  color: #8a8d90;
  font-size: 13px !important;
}

.fbb {
  width: 100%;
  text-align: left;
  padding-bottom: 0.4em;
  border-bottom: 2px solid lightgray;
}

.three-input {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.tic {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.tic:nth-child(2) {
  padding: 0 5px;
}

.three-input input {
  width: 100%;
  border-radius: 5px;
  padding: 7px;
  outline: none;
  border: 1px solid #8a8d90;
  box-sizing: border-box;
  font-size: 13px;
}

.modal-checks-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}

.modal-checks {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: 13px;
  margin-top: 10px;
}

.modal-checks label {
  margin: 0 !important;
  padding-left: 7px;
  line-height: 1;
}

.modal-checks input {
  outline: none;
}

.mod-chk-last-input {
  width: 100px;
  margin: 0 10px;
  border-radius: 5px;
  outline: none;
  border: 1px solid #8a8d90;
  box-sizing: border-box;
  padding: 7px;
}

.mod-chk-last-input:focus {
  border-bottom: 1px solid rgb(68, 67, 67);
  -webkit-box-shadow: 0px -5px 2px -5px #222 inset;
  -moz-box-shadow: 0px -5px 2px -5px#222 inset;
  box-shadow: 0px -5px 2px -5px #222 inset;
}

[v-cloak] {
  display: none;
}

.upload-craglist label {
  width: 100%;
  text-align: center;
  font-size: 14px;
  color: #000000;
  font-weight: 600;
}

.craglist-files {
  width: 100%;
}

.craglist-files ul {
  border-top: 1px solid gray;
  padding: 0;
  margin: 0;
  width: 100%;
}

.craglist-files ul li {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid gray;
}

.rmv-btn {
  font-size: 20px;
  cursor: pointer;
}

.file-lists-container {
  width: 100%;
}

.file-head-clearall {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  color: #000000;
}

.file-head-clearall .clearall {
  cursor: pointer;
}

.upload-file-div-wrapper {
  height: 100px;
  width: 100%;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  background-color: #ddf;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.fileInput {
  cursor: pointer;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 99;
  font-size: 50px;
  opacity: 0;
  -moz-opacity: 0;
  filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
}

.el-table .el-table__header-wrapper thead tr th:nth-child(5) .cell {
  justify-content: center;
}

.down-btn-container a {
  color: #000000;
}

.down-btn-container a:hover {
  opacity: 0.6;
}

.down-btn-container a .fa-file-pdf,
.down-btn-container .fa-file-csv {
  font-weight: 600;
}

.progress-xpath-count {
  font-weight: bold;
  color: #4ac3db;
}

.onlyForRunningTestRow {
  background-color: #c4f1de !important;
}

.s-a-tabs {
  width: 100%;
  margin-top: 1em;
}

.test-run-name {
  //color: rgb(94, 114, 228);
  color: $color;
  font-weight: bold;
}

.test-run-name-for-suite {
  color: #2dce89;
  font-weight: bold;
}

.passed-status {
  font-weight: bold;
  font-size: 12px;
  color: #8a8d90;
  text-transform: capitalize;
}

.failed-status {
  background-color: #d93b3b;
  color: #fff;
  padding: 1px 10px;
  border-radius: 10px;
  font-weight: bold;
  font-size: 12px;
}

.run-tags-cont {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.run-tags {
  background-color: rgb(245, 245, 245);
  border: 1px solid rgb(222, 222, 222);
  border-radius: 3px;
  padding: 3px;
  font-size: 0.65rem;
  margin: 2.5px 2.5px 0 0;
  display: inline-block;
}

.test-time-cont {
  display: inline-block;
  max-width: 100% !important;
  font-size: 0.6rem;
  padding: 0.15rem 0.35rem;
  text-align: center;
  border: solid 0.1px rgba(#25a18e, 0.75);
  background: rgba(#25a18e, 0.15);
  margin-top: 5px;
  border-radius: 0.25rem;
  color: #25a18e;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.looping-rhombuses-spinner,
.looping-rhombuses-spinner * {
  box-sizing: border-box;
}

.looping-rhombuses-spinner {
  width: 50%;
  height: 15px;
  position: relative;
  margin-left: 10px !important;
}

.script {
  background: #223e33;
  border-radius: 0.25rem;
}

.pageview-modal .modal-content,
.pageview-modal .modal-dialog {
  width: auto;
  max-width: 80vw;
  max-height: 80vh;
  min-width: 50%;
}

.pageview-modal .modal-body {
  overflow: auto;
}

.add-test-project-modal .modal-dialog,
.re-run-modal-container .modal-dialog {
  width: auto;
  max-width: 900px;
  height: 10vh;
}

.show-test-project-modal .modal-dialog,
.re-run-modal-container .modal-dialog {
  width: auto;
  max-width: 900px;
  height: 10vh;
}

.looping-rhombuses-spinner .rhombus {
  height: 15px;
  width: 15px;
  background-color: #4ac3db;
  left: calc(15px * 1);
  position: absolute;
  margin: 0 auto;
  border-radius: 2px;
  transform: translateY(0) rotate(45deg) scale(0);
  animation: looping-rhombuses-spinner-animation 2500ms linear infinite;
}

.b {
  border: 1px solid red !important;
}

.for-row-selection .el-input {
  height: 100%;
  padding: 3px;
}

.test-descp {
  height: 10px;
  color: #9ea1a5;
  width: 50px;
}

.edit-test-select {
  width: 100%;
}

.edit-test-select .el-input {
  width: 100%;
}

.edit-test-modal-container .edit-test-select .el-select .el-input .el-input__inner {
  text-transform: capitalize !important;
}

.el-select-dropdown__item {
  text-transform: capitalize !important;
}

.add-test-project-modal button:disabled {
  cursor: not-allowed;
}

.for-pagination-circle-cursor .page-link {
  cursor: pointer;
}

.btn-gray {
  background-color: #172b4d !important;
}

.processing-screenshot-img {
  width: 150px;
  height: 100px;
  overflow: hidden;
}

.processing-screenshot-img img {
  /* border-radius: 10px; */
  /* border: 2px solid #fbb140; */
  width: 150px;
  height: 100px;
  object-fit: contain !important;
}

.add-test-footer-btn {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.file-hover {
  background-color: #adfad9 !important;
}

.pakhpalle {
  background-color: red !important;
}

.looping-rhombuses-spinner .rhombus:nth-child(1) {
  animation-delay: calc(2500ms * 1 / -1.5);
}

.looping-rhombuses-spinner .rhombus:nth-child(2) {
  animation-delay: calc(2500ms * 2 / -1.5);
}

.looping-rhombuses-spinner .rhombus:nth-child(3) {
  animation-delay: calc(2500ms * 3 / -1.5);
}

@keyframes looping-rhombuses-spinner-animation {
  0% {
    transform: translateX(0) rotate(45deg) scale(0);
  }

  50% {
    transform: translateX(233%) rotate(45deg) scale(1);
  }

  100% {
    transform: translateX(466%) rotate(45deg) scale(0);
  }
}

.narrow-select .el-input {
  height: 52px;
  width: 80px;
  padding: 3px;
}

.options-accordion .el-collapse-item__header {
  font-size: 16px;
}

.small--select {
  max-width: 80px !important;
}

.c--breadcrumb {
  display: flex;
  align-items: center;
  font-size: 0.85rem;
  font-weight: 300;
  color: rgba($pColor, 0.6);
  transform: translateY(-40px);
  width: calc(100% - 100px);

  .cb--divider {
    color: rgba($color, 0.6);
    font-size: 0.65rem;
  }

  &.to_move {
    transform: translate3d(30px, -36.5px, 0);
  }
}

.top--page,
.mid--page {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .tp--l,
  .mp--l,
  .mp--r {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .tp--title {
      font-size: 1.9rem;
      color: rgba($pColor, 0.85);
    }
  }
}

.mid--page {
  border-bottom: solid 0.1px rgba($pColor, 0.25);

  .el-input__inner {
    font-size: 0.75rem;
    padding: 0.35rem;
    background: #f7f9fc;

    &:focus {
      outline: none !important;
    }
  }
  .el-input__suffix {
    .el-input__suffix-inner {
      display: flex;
      justify-content: center;
      align-items: center;
      .el-input__icon {
        font-size: 0.8rem;
        transform: translateY(-3px);
      }
    }
  }
  .mp--l {
    span {
      color: rgba($pColor, 0.65);
      cursor: pointer;
      //font-size: 1.25rem;
      &.active {
        color: #25a18e;
      }
    }

    .small_btn {
      background: rgba($color, 0.1);
      border: solid 0.1px $color;

      i,
      span {
        font-size: 0.75rem !important;
        font-weight: 600;
        color: $color;
      }

      &:hover {
        background: rgba($color, 0.3);

        i,
        span {
          color: rgba($color, 0.75);
        }
      }

      &.active {
        i,
        span {
          color: #fff !important;
        }
      }
    }

    .dashed--btn {
      border-style: dashed;
    }
  }
  .mp--r {
    .pag--details {
      color: rgba($pColor, 0.65);
      font-size: 0.85rem;
      white-space: nowrap;
    }
  }
}

.bpsd--table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 15px;
  table-layout: fixed;

  .tbl--head {
    width: 100%;
    box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
    background: $color;
    color: #ffffff;
    overflow: hidden;

    th {
      padding: 0.8rem;
      font-size: 0.75rem;
      font-weight: 800;
      text-transform: uppercase;

      .th--sort {
        font-size: 0.6rem;
      }

      &:first-child {
        border-radius: 0.35rem 0 0 0.35rem;
      }

      &:last-child {
        border-radius: 0 0.35rem 0.35rem 0;
      }
    }
  }
}

.ctb--body {
  display: flex;
  flex-wrap: wrap;

  .ctbb--row {
    width: 31%;
    margin: 1%;
    cursor: pointer;
    overflow: hidden;
    background: #ffffff;
    box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
    border-radius: 0.25rem;
    position: relative;

    &.ctbb--processing {
      .ctbb--inner {
        filter: blur(2px);
        display: none;
      }
    }

    .ctbb--inner {
      height: 100%;
      display: flex;
      flex-direction: column;

      .elt--banner {
        position: relative;
        .elt--banner_img {
          display: inline-block;
          width: 100%;
        }

        .elt--banner_overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba($color, 0.3);
        }

        .elt--banner_overlay_status {
          position: absolute;
          top: 10px;
          right: 10px;
          max-width: 100px;
          text-align: center;
          &.no--close {
            padding: 0.25rem 0.35rem;
            .el-alert__closebtn {
              display: none;
            }
          }
        }

        .elt--banner_overlay_duration,
        .elt--banner_overlay_created {
          position: absolute;
          bottom: -10px;
          font-size: 0.7rem;
          font-weight: 700;
          color: #fff;
          background: rgba($color, 0.85);
          padding: 0.25rem;
          border-radius: 0.3rem;
        }

        .elt--banner_overlay_created {
          left: 10px;
        }
        .elt--banner_overlay_duration {
          right: 10px;
        }

        &.no--img {
          display: flex;
          align-items: center;
          height: 110%;
        }
      }

      .elt--content {
        padding: 0.6rem;
        margin-top: 10px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .test-desc {
          font-size: 0.8rem;
          color: rgba($pColor, 0.75);
          font-style: italic;
        }

        .elt--value {
          font-weight: 700;
          font-size: 0.85rem;
          margin-right: 12px;
        }

        .el-divider {
          margin: 8px 0;
        }

        .el--footer {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          border: solid red;
        }
      }
    }

    .ctbb--over {
      cursor: pointer;
      overflow: hidden;
      box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
      border-radius: 0.25rem;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      position: absolute;
      top: 0;
      left: 0;
      text-align: center;
      padding: 0.5rem;

      img {
        display: inline-block;
        width: 100%;
        box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
        border-radius: 0.25rem;
        margin: auto;
        border: solid 3px rgba(#108050, 0.7);
      }

      .s--icon {
        font-size: 3rem;
        font-weight: 700;
        color: #108050;

        i {
          font-weight: 600;
        }
      }

      .s--name {
        padding: 0.4rem 0.75rem;
        font-size: 0.85rem;
        font-weight: 600;
        color: #fff;
        background: rgba(#108050, 0.8);
        border: solid 0.1px #108050;
        border-radius: 0.2rem;
      }

      .s--txt {
        color: #108050;
        font-size: 0.8rem;
        font-weight: 800;
      }
    }
  }
}

.tbl--body {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .tblb--row {
    width: 100%;
    background: #ffffff;
    box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
    border-radius: 0.25rem;
    cursor: pointer;
    position: relative;
    overflow: hidden;

    &:hover {
      box-shadow: 0 0 30px -5px rgba(#111, 0.25);
      transition: all 0.25s ease-in;
    }

    &.tblb--processing {
      min-height: 350px;
      .tblb--inner {
        filter: blur(1px);
      }
    }

    .tblb--inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .elt-row {
        padding: 1.4rem 0.8rem;
        font-size: 0.81rem;
        display: inline-block;

        &.flex--elt {
          display: flex;
        }

        .el-alert {
          display: inline;
          text-align: center;
          &.no--close {
            padding: 0.25rem 0.35rem;
            .el-alert__closebtn {
              display: none;
            }
          }
        }

        .elt--value {
          font-weight: 700;
        }

        .elt--txt {
          font-size: 0.7rem;
        }

        .elt--banner_img {
          border-radius: 0.35rem;
          overflow: hidden;
          width: 100%;

          img {
            display: inline-block;
            width: 100%;
          }
        }
      }
    }

    .tblb--over {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #00ff6214;
      display: flex;
      justify-content: space-between;
      align-items: center;
      z-index: 999;

      .tblbo--img {
        height: 100%;
        width: 100%;
        border-radius: 0.25rem;
        overflow: hidden;
        img {
          height: 100%;
        }
      }
      .s--icon {
        font-size: 2.5rem;
        font-weight: 700;
        color: #108050;

        i {
          font-weight: 900;
        }
      }

      .s--name {
        padding: 0.4rem 0.75rem;
        font-size: 0.85rem;
        font-weight: 600;
        color: #fff;
        background: rgba(#108050, 0.8);
        border: solid 0.1px #108050;
        border-radius: 0.2rem;
      }

      .tblo--step {
        .s--txt {
          color: #108050;
          font-size: 0.8rem;
          font-weight: 800;
        }
      }
    }
  }
}
.modal-body {
  height: auto;
  padding-top: 40px !important;
}
/* width */
::-webkit-scrollbar {
  width: 5px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $color;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.mp--filter_container {
  animation: slideDown 0.3s;
  width: 100%;
  background: #ffffff;
  -webkit-box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
  border-radius: 0.25rem;
  position: relative;
  overflow: hidden;
}

@keyframes slideDown {
  from {
    height: 0;
    opacity: 0;
  }
  to {
    height: auto;
    opacity: 1;
  }
}
.sessionEvent-detail-modal {
  // width: 85vw !important;
  // margin: 1.75rem auto;
  @media (min-width: 576px) {
    .modal-dialog {
      max-width: 100%;
      margin: 1.75rem auto;
    }
  }
  .modal-dialog {
    width: 85% !important;
    height: 10vh;
  }
}
.showCoveringtests-modal {
  // width: 85vw !important;
  // margin: 1.75rem auto;
  .modal-dialog {
    width: 80vw !important;
    height: 10vh;
  }
}
.swal2-popup #swal2-title {
  display: block !important;
  font-size: 1.5em !important;
}

.inp--project {
  width: 300px;
  height: 20px !important;
}
.ultra--ipt_content {
  display: flex;
  align-items: center;
  white-space: nowrap;
  max-width: 70%;
  .u--tag {
    display: inline-block;
    font-size: 0.75rem;
    padding: 0.15rem 0.35rem;
    border: solid 0.1px rgba(#25a18e, 0.75);
    background: rgba(#25a18e, 0.15);
    border-radius: 0.25rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .u--tag--i {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.ultra--ipt_content,
.ultra--ipt_ipt {
  display: inline-block;
  height: 50px;
  outline: none;
  border: none;
  background: transparent;
}
.ultra--ipt_ipt {
  width: 100%;
}
.ultra--ipt {
  border: solid 0.1px #4b4b71;
  border-radius: 0.3rem;
  position: relative;
  height: 40px;
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 100%;
  .ultra--ipt_content,
  .ultra--ipt_ipt {
    display: inline-block;
    height: 50px;
    outline: none;
    border: none;
    background: transparent;
  }
  .ultra--ipt_content {
    display: flex;
    align-items: center;
    white-space: nowrap;
    max-width: 70%;
    .u--tag {
      display: inline-block;
      font-size: 0.75rem;
      padding: 0.15rem 0.35rem;
      border: solid 0.1px rgba(#25a18e, 0.75);
      background: rgba(#25a18e, 0.15);
      border-radius: 0.25rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .u--tag--i {
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .ultra--ipt_content--xphat {
    display: flex;
    align-items: center;
    white-space: nowrap;
    max-width: 85%;
    .u--tag {
      display: inline-block;
      font-size: 0.75rem;
      padding: 0.15rem 0.35rem;
      border: solid 0.1px rgba(#25a18e, 0.75);
      background: rgba(#25a18e, 0.15);
      border-radius: 0.25rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .u--tag--i {
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .ultra--ipt_ipt {
    width: 100%;
  }
  .content--ultra {
    max-width: 75%;
  }
  .btn--clean {
    max-width: 22%;
  }
}
.snippet--inp {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  width: 100%;
}

.list--downloadTestFile {
  border-bottom: solid 1px #f8f9fe;
  cursor: pointer;
}
.list--downloadTestFile:hover {
  color: #5f72e4;
  border-bottom: solid 1px #d5d5d5;
}
.ticks {
  z-index: 1;
  position: relative;
  width: 100%;
  bottom: 100%;
}
.ticks .tick-hover-box {
  cursor: pointer;
  position: absolute;
  height: 100%;
  top: -15px;
  width: 5px;
  // right: -3px;
}
.ticks .tick-hover-box .tick-info {
  border-radius: var(--radius);
  padding: 0.25rem 0.5rem;
  position: absolute;
  top: -36px;
  background-color: var(--bg-charcoal);
  display: none;
  color: var(--light);
  white-space: nowrap;
  left: 50%;
  transform: translate(-50%);
}
.ticks .tick-hover-box .tick-marker {
  position: absolute;
  width: 1px;
  height: 21px;
  background-color: #7e7e7e;
  right: 2px;
}
.ticks .tick-hover-box .tick-thumb {
  position: absolute;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  right: -2px;
  top: -4px;
  border: 0.5px solid #7e7e7e;
  background-color: #ffff;
  transform-origin: center;
  transition: transform 0.2s;
}
  .container--event--liste {
    margin: 0 0 0 30px;
    padding: 8px;
    width: 100%;
    border: solid 1px #c6c6c6;
    border-radius: 5px;
    max-height: 656px;
    overflow: hidden;
  }
  .event--liste {
    max-height: 580px;
    overflow-x: auto;
    overflow-y: none;
  }
  event--liste::-webkit-scrollbar {
    display: none;
  }
  .content {
    cursor: pointer;
    overflow: hidden;
    display: flex;
    align-items: center;
    margin-bottom: 3px;
    width: 100%;
    border-radius: 5px;
    padding: 10px;
    .time--tag {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      flex-wrap: nowrap;
      margin-right: 1rem !important;
      width: 96%;
    }
    .right--content {
      width: 80%;
      flex-direction: column;
    }
    .icon--event {
      width: 20%;
      display: flex;
      background-color: #e5e5e5;
      width: 40px;
      height: 40px;
      border-radius: 5px;
      text-align: center;
      align-items: center;
      justify-content: center;
    }
  }
  .content:hover {
    background-color: #e8eeff;
    .icon--event {
      background-color: $color;
      i {
        color: #ffff;
      }
    }
  }
  .active--event {
    background-color: #e8eeff;
    .icon--event {
      background-color: $color;
      i {
        color: #ffff;
      }
    }
  }
.small_btn {
  background: rgba($color, 0.1);
  border: solid 0.1px $color;

  i,
  span {
    font-size: 0.75rem !important;
    font-weight: 600;
    color: $color;
  }

  &:hover {
    background: rgba($color, 0.3);

    i,
    span {
      color: rgba($color, 0.75);
    }
  }
}

.dashed--btn {
  border-style: dashed;
}
</style>
