<template>
    <div class="card" v-loading="loading">
      <div class="card-header">
        <h1>{{ $t('user_edit_card.edit_password') }}</h1>
      </div>
      <div class="card-body">
        <form ref="profile_form" @submit.prevent="handlePasswordUpdate">
          <base-input
          v-if="user.user_origin && user.user_origin != 'OAuth2 signup' "
            :label="$t('user_edit_card.old_password')"
            prepend-icon="fa fa-unlock-alt"
            placeholder="password"
            v-model="old_password"
            type="password"
          />
          <validation-error :errors="apiValidationErrors.old_password" />
          <base-input
            :label="$t('user_edit_card.new_password')"
            prepend-icon="fa fa-unlock-alt"
            placeholder="New password"
            v-model="new_password"
            type="password"
          />
          <validation-error :errors="apiValidationErrors.new_password" />
          <el-tooltip
          :disabled="!(new_password.length === 0 || old_password.length === 0)"
          :content="'new and existing password are required'"
          placement="top"
          >
            <div class="my-4">
                <base-button
                type="button"
                class="btn btn-sm btn-primary"
                native-type="submit"
                :disabled="!canChangePassword"
                >
                {{$t('user_edit_card.submit')}}
                </base-button>
            </div>
          </el-tooltip>
        </form>
      </div>
    </div>
  </template>
  <script>
  import BaseInput from "@/components/Inputs/BaseInput.vue";
  import BaseButton from "@/components/BaseButton.vue";
  import formMixin from "@/mixins/form-mixin";
  import ValidationError from "@/components/ValidationError.vue";
  import axios from "axios";
  
  export default {
    name: "UserEditCard",
  
    components: {
      BaseInput,
      BaseButton,
      ValidationError,
    },
  
    mixins: [formMixin],
  
    props: {
      user: Object,
      loading: {
        type: Boolean,
        default: false,
      },
    },
  
    data() {
      return {
        old_password :"",
        new_password: "",
      };
    },
    methods: {
      async handlePasswordUpdate() {
        if (this.$isDemo == 1 && ["1", "2", "3"].includes(this.user.id)) {
          this.$notify({
            type: "danger",
            message: "You are not allowed not change data of default users.",
          });
          return;
        } else {
          const url = `${process.env.VUE_APP_API_URL_PREFIX}/auth/v1/users/password/change`;
          const data =
            this.user.user_origin == "OAuth2 signup'"
              ? {
                    new_password: this.new_password
                }
              : {
                    old_password: this.old_password,
                    new_password: this.new_password
                };



          try {

            axios.defaults.validateStatus = (status) => {
              // return status >= 200 && status < 401; // to catch 400 error
              return (status === 400) || (status >= 200 && status < 300);
            }

            const res = await axios.post(url, data)
            console.log("Result:", res)
            if(res && res.data && res.data.status === "success"){
              this.$notify({
                type: "success",
                message: "Changed password successfully",
              });
              await this.$store.getters["profile/me"];
            }else{
              this.$notify({
                type: "danger",
                duration: 10000,
                message: "We need a minimum of 8 characters total along with a number and a symbol. Details: " + res.data.message,
              });
            }
          } catch (error) {
            this.$notify({
              type: "danger",
              message: "Old password should be valid and we need a minimum of 8 characters total along with a number and a symbol. We also dont allow dictionary words or simple/compromised passwords.",
            });
            console.log("Caught: ", error);
            // this.setApiValidation(error.response.data.errors);
          } finally {
            this.loading = false;
            axios.defaults.validateStatus = (status) => {
              return status >= 200 && status < 300; // default
            }

          }
        }
      },
    },
    computed: {
      canChangePassword() {
        return this.new_password.length > 0 
      },
    },
  };
  </script>
  