var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper"},[_c('notifications'),_c('side-bar',[_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('nav.liveEvents'),
            icon: 'ni ni-air-baloon text-primary',
            path: '/details/project-explorer/list-projects',
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Funnels',
            icon: 'fas fa-baby text-primary',
            path: '/details/project-explorer/funnels',
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Flows',
            icon: 'fas fa-users text-primary',
            path: '/details/project-explorer/flows',
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Snapshots',
            icon: 'fas fa-camera text-primary',
            path: '/details/project-explorer/snapshots',
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('nav.profile'),
            icon: 'ni ni-single-02 text-primary',
            path: '/details/profile',
          }}}),_c('div',{staticClass:"sidebar--botom"},[_c('hr',{staticClass:"my-3",staticStyle:{"border-color":"rgba(0, 0, 0, 0.1) currentcolor currentcolor","border-style":"solid none none","border-width":"1px 0px 0px","border-image":"none 100% / 1 / 0 stretch","min-width":"80%","overflow":"visible","box-sizing":"content-box","height":"0px"}})])],1)],2),_c('div',{staticClass:"main-content"},[_c('dashboard-navbar',{attrs:{"type":_vm.$route.meta.navbarType}}),_c('div',{on:{"click":function($event){return _vm.$sidebar.displaySidebar(false)}}},[_c('fade-transition',{attrs:{"duration":200,"origin":"center top","mode":"out-in"}},[_c('router-view')],1)],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }