import auth from "@/middleware/auth";
import guest from "@/middleware/guest";
import accessLiveEvent from "@/middleware/accessLiveEvent";

import DashboardLayout from "@/views/Layout/DashboardLayout.vue";
import AuthLayout from "@/views/Layout/AuthLayout.vue";
import NotFound from "@/views/Error/NotFoundPage.vue";
import listProject from "@/views/Pages/Events/listProject.vue";
import dashboard from "@/views/Pages/Events/dashboard.vue";
import funnels from "@/views/Pages/Events/Funnels.vue";
import flows from "@/views/Pages/Events/Flows.vue";
import snapshots from "@/views/Pages/Events/Snapshots.vue";
import sessionListEvents from "@/views/Pages/Events/sessionListEvents.vue";
import ProfilePage from "@/views/ProfilePage/ProfilePage.vue";

// Pages
const Pricing = () => import("@/views/Pages/Pricing.vue");
const Login = () => import("@/views/Pages/Login.vue");
const EmailConfirmation = () => import("@/views/Pages/EmailConfirmation.vue");
const TnS = () => import("@/views/Pages/Tns.vue");
const Register = () => import("@/views/Pages/Register.vue");
const Lock = () => import("@/views/Pages/Lock.vue");
const PasswordReset = () => import("@/views/Password/Reset.vue");
const PasswordEmail = () => import("@/views/Password/Email.vue");

const pagesRoute = {
  path: "/details",
  component: DashboardLayout,
  name: "Details",
  children: [
    {
      path: "project-explorer/list-projects",
      name: "List Project",
      components: { default: listProject },
      meta: { middleware: auth, middleware: accessLiveEvent },
    },
    {
      path: "project-explorer/funnels",
      name: "Funnels",
      components: { default: funnels },
      meta: { middleware: auth, middleware: accessLiveEvent },
    },
    {
      path: "project-explorer/flows",
      name: "Flows",
      components: { default: flows },
      meta: { middleware: auth, middleware: accessLiveEvent },
    },
    {
      path: "project-explorer/snapshots",
      name: "Snapshots",
      components: { default: snapshots },
      meta: { middleware: auth, middleware: accessLiveEvent },
    },
    {
      path: "project-explorer/view-project/:id",
      name: "List Sessions",
      components: { default: sessionListEvents },
      meta: { middleware: auth, middleware: accessLiveEvent },
    },
    {
      path: "profile",
      name: "User Profile",
      components: { default: ProfilePage },
      meta: { middleware: auth },
    },
  ],
};

const notFoundPages = {
  path: "/*",
  component: NotFound,
  name: NotFound,
};

const authPages = {
  path: "/",
  component: AuthLayout,
  name: "Authentication",
  children: [
    {
      path: "/login",
      name: "Login",
      component: Login,
      meta: { middleware: guest },
    },
    {
      path: "/confirmation",
      name: "Email-Confirmation",
      component: EmailConfirmation,
      meta: { middleware: guest },
    },
    {
      path: "/service-agreement",
      name: "Service-Agreement",
      component: TnS,
      meta: { middleware: guest },
    },
    {
      path: "/register",
      name: "Register",
      component: Register,
      meta: { middleware: guest },
    },
    {
      path: "/password/reset",
      name: "PasswordReset",
      component: PasswordReset,
      meta: { middleware: guest },
    },
    {
      path: "/password-reset",
      name: "PasswordEmail",
      component: PasswordEmail,
      meta: { middleware: guest },
    },
    {
      path: "/pricing",
      name: "Pricing",
      component: Pricing,
    },
    {
      path: "/lock",
      name: "Lock",
      component: Lock,
    },
  ],
};

const routes = [
  {
    path: "/",
    redirect: "/details/project-explorer/list-projects",
  },
  {
    path: "/verify",
    redirect: (to) => {
      return { path: "/details/project-explorer/list-projects", query: null };
    },
  },
  pagesRoute,
  authPages,
  notFoundPages,
];

export default routes;
