<template>
  <div class="bpsd_dtb">
    <header v-if="customSizes" class="tbl--header mb-3">
      <div
        class="elt--header"
        v-for="(elt, id) in tHeader"
        :key="elt.id"
        :style="'width: ' + elt.width"
      >
        <template v-if="elt.sortable">
          <span
            class="tblh--filterable"
            @click="handleFilter(elt.key, elt.sortDetails, id)"
          >
            <span class="th--label">{{ elt.label }}</span>
            <span v-if="elt.sortDetails.asc" class="th--sort ml-2"
              ><i class="el-icon-caret-top"></i
            ></span>
            <span v-else class="th--sort ml-2"><i class="el-icon-caret-bottom"></i></span>
          </span>
        </template>
        <span class="th--label" v-else>{{ elt.label }}</span>
      </div>
    </header>
    <table class="bpsd--table" v-else>
      <thead class="tbl--head mb-3">
        <tr>
          <th v-for="(elt, id) in tHeader" :key="elt.id">
            <template v-if="elt.sortable">
              <span
                class="tblh--filterable"
                @click="handleFilter(elt.key, elt.sortDetails, id)"
              >
                <span class="th--label">{{ elt.label }}</span>
                <span v-if="elt.sortDetails.asc" class="th--sort ml-2"
                  ><i class="el-icon-caret-top"></i
                ></span>
                <span v-else class="th--sort ml-2"
                  ><i class="el-icon-caret-bottom"></i
                ></span>
              </span>
            </template>
            <span class="th--label" v-else>{{ elt.label }}</span>
          </th>
        </tr>
      </thead>
    </table>
    <div class="tbl--body mb-3" v-if="loading">
      <div class="tblb--row mb-2">
        <img
          src="/img/loading.gif"
          alt="loading..."
          style="height: 100px; width: 100px"
        />
      </div>
    </div>
    <div class="tbl--body mb-3" v-else>
      <template v-if="items.length > 0">
        <slot name="content" />
      </template>
      <template v-else>
        <div class="tblb--row px-3 py-4">
          {{ emptyText }}
        </div>
      </template>
    </div>
  </div>
</template>
<script>
export default {
  name: "data-list",
  props: {
    items: {
      type: Array,
      default: () => [],
      description: "List of items to show",
    },
    tHeader: {
      type: Array,
      default: undefined,
      description: "Header of data list",
    },
    loading: {
      type: Boolean,
      default: true,
      description: "Loading state",
    },
    customSizes: {
      type: Boolean,
      default: false,
      description: "Loading state",
    },
    emptyText: {
      type: String,
      default: "No items found",
      description: "Text to show where data are empty",
    },
  },

  methods: {
    filterString(key, a, b, asc) {
      return asc
        ? a[key].toString().toLowerCase() > b[key].toString().toLowerCase()
          ? 1
          : b[key].toString().toLowerCase() > a[key].toString().toLowerCase()
          ? -1
          : 0
        : b[key].toString().toLowerCase() > a[key].toString().toLowerCase()
        ? 1
        : a[key].toString().toLowerCase() > b[key].toString().toLowerCase()
        ? -1
        : 0;
    },

    filterDate(key, a, b, asc) {
      return asc
        ? new Date(a[key]).getTime() > new Date(b[key]).getTime()
          ? 1
          : new Date(b[key]).getTime() > new Date(a[key]).getTime()
          ? -1
          : 0
        : new Date(b[key]).getTime() > new Date(a[key]).getTime()
        ? 1
        : new Date(a[key]).getTime() > new Date(b[key]).getTime()
        ? -1
        : 0;
    },

    handleFilter(key, sortDetails, id) {
      //!TODO Optimize this function
      if (key !== "parentCall") {
        if (key === "_id") {
          this.$parent.filteredResults.reverse();
        } else {
          if (sortDetails.type === "date") {
            this.$parent.filteredResults.sort((a, b) =>
              this.filterDate(key, a, b, sortDetails.asc)
            );
          } else {
            this.$parent.filteredResults.sort((a, b) =>
              this.filterString(key, a, b, sortDetails.asc)
            );
          }

          this.tHeader[id].sortDetails = {
            asc: !sortDetails.asc,
            desc: !sortDetails.desc,
            type: sortDetails.type,
          };
        }
      }
      this.$parent.handleCurrentChange(this.$parent.pagination.currentPage);
    },
  },
};
</script>
<style lang="scss">
$color: #25a18e;
.bpsd--table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 15px;
  table-layout: fixed;

  .tbl--head {
    width: 100%;
    box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
    background: $color;
    color: #ffffff;
    overflow: hidden;

    th {
      padding: 0.8rem;
      font-size: 0.75rem;
      font-weight: 800;
      text-transform: uppercase;

      .th--sort {
        font-size: 0.6rem;
      }

      .tblh--filterable {
        cursor: pointer;
      }

      &:first-child {
        border-radius: 0.35rem 0 0 0.35rem;
      }

      &:last-child {
        border-radius: 0 0.35rem 0.35rem 0;
      }
    }
  }
}

.tbl--header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.45);
  background: $color;
  color: #ffffff;
  overflow: hidden;
  border-radius: 0.45rem;

  .elt--header {
    padding: 1.4rem 0.8rem;
    font-size: 0.81rem;
    display: inline-block;
    font-size: 0.75rem;
    font-weight: 800;

    .tblh--filterable {
      cursor: pointer;
    }
  }
}

.tbl--body {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .tblb--row {
    width: 100%;
    background: #ffffff;
    box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
    border-radius: 0.25rem;
    cursor: pointer;
    position: relative;
    overflow: hidden;

    &:hover {
      box-shadow: 0 0 30px -5px rgba(#111, 0.25);
      transition: all 0.25s ease-in;
    }

    &.tblb--processing {
      .tblb--inner {
        filter: blur(2px);
      }
    }

    .tblb--inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .elt-row {
        padding: 1.4rem 0.8rem;
        font-size: 0.81rem;
        display: inline-block;
        overflow: hidden;

        &.flex--elt {
          display: flex;
        }

        .el-alert {
          display: inline;
          text-align: center;
          &.no--close {
            padding: 0.25rem 0.35rem;
            .el-alert__closebtn {
              display: none;
            }
          }
        }

        .elt--value {
          font-weight: 700;
        }

        .elt--txt {
          font-size: 0.7rem;
        }

        .elt--banner_img {
          border-radius: 0.35rem;
          overflow: hidden;
          width: 100%;

          img {
            display: inline-block;
            width: 100%;
          }
        }
      }
    }

    .tblb--over {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      // background-color: red;
      display: flex;
      justify-content: space-between;
      align-items: center;
      z-index: 999;

      .tblbo--img {
        height: 100%;
        width: 100%;
        border-radius: 0.25rem;
        overflow: hidden;
        img {
          height: 100%;
        }
      }
      .s--icon {
        font-size: 2.5rem;
        font-weight: 700;
        color: #108050;

        i {
          font-weight: 900;
        }
      }

      .tblo--step {
        .s--txt {
          color: #108050;
          font-size: 0.8rem;
          font-weight: 800;
        }
      }
    }
  }
}
</style>
