<template>
<div>
  Snapshots
</div>
</template>

<script>
export default {
name: "Snapshots"
}
</script>

<style scoped>

</style>